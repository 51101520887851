import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './FooterTable.module.scss';

interface IFooterTable {
  total: number | string;
}

const cx = classNames.bind(styles);

const FooterTable = ({ total }: IFooterTable) => {
  const { t } = useTranslation();

  return (
    <div className={cx('footerTable')}>
      <h2>{t('orders_orderDetail_cart_table_total')}</h2>
      <h3 className={cx('footerTotalPrice')}>{total}</h3>
    </div>
  );
};

FooterTable.propTypes = {};

export default FooterTable;
