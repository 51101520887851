import { createAsyncThunk } from '@reduxjs/toolkit';
import { agentApi } from '~/apis/agent';
import { IParamAccountAgent, IParamDetailAgent, IPayloadDeleteAgent, IPayloadUpdateAgent } from '~/data/account';
import { IAddAgent } from '~/pages/agents/addAgent/addAgent.interface';
import { CREATE_AGENT, DELETE_AGENT, GET_DETAIL_AGENT, GET_LIST_AGENT, SEARCH_LIST_AGENT, UPDATE_AGENT } from '~/utils/actionType';

export const createAgent = createAsyncThunk(CREATE_AGENT, async (payload: IAddAgent, { rejectWithValue }) => {
  try {
    const res = await agentApi.createAgent(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListAgent = createAsyncThunk(GET_LIST_AGENT, async (params: IParamAccountAgent, { rejectWithValue }) => {
  try {
    const res = await agentApi.getListAgent(params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAgent = createAsyncThunk(DELETE_AGENT, async (payload: IPayloadDeleteAgent, { rejectWithValue }) => {
  try {
    const res = await agentApi.deleteAccountAgent(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailAgent = createAsyncThunk(GET_DETAIL_AGENT, async (param: IParamDetailAgent, { rejectWithValue }) => {
  try {
    const res = await agentApi.getDetailAgent(param);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAgent = createAsyncThunk(UPDATE_AGENT, async (payload: IPayloadUpdateAgent, { rejectWithValue }) => {
  try {
    const res = await agentApi.updateAccountAgent(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchAgents = createAsyncThunk(SEARCH_LIST_AGENT, async (params: IParamAccountAgent, { rejectWithValue }) => {
  try {
    const res = await agentApi.getListAgent(params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});