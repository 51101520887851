// Libs
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
// Ant design
import { Button, Col, Flex, Form, Input, Row, Select, Tooltip, Typography, message } from 'antd';
// i18next
import { useTranslation } from 'react-i18next';
// Hooks
import useBreakpoint from '~/hooks/useBreakPoint';
// Styles
import styles from './NewOrder.module.scss';
// Constants
import { MAX_LENGTH_ORDER_NOTE, ROUTER_PATHS, TITLE_LEVEL } from '~/utils/constants';
import { RolesEnum, StoragesEnum } from '~/utils/enum';
// Interface
import { IPayment, NewOrderProps } from './NewOrder.interface';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectClientDetail } from '~/thunks/clientDetail/clientDetailSlice';
import { selectPayments } from '~/thunks/payments/paymentsSlice';
import { getPayments } from '~/thunks/payments/paymentsThunk';
import { selectDetailCreateOrder } from '~/thunks/order/createOrderSlice';
import { addClientDetailAdminNote } from '~/thunks/clientDetail/clientDetailThunk';

const cx = classNames.bind(styles);
const { TextArea } = Input;
const { Title } = Typography;

const NewOrder: React.FC<NewOrderProps> = ({ orderCompleted = false, addProducts = false, clientId, ...props }) => {
  const { t } = useTranslation();
  const { sm, lg } = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = localStorage.getItem('role');

  const clientDetail = useAppSelector(selectClientDetail);
  const order = useAppSelector(selectDetailCreateOrder);

  const [addressSelectedId, setAddressSelectedId] = useState<string>(clientDetail?.destinationId);
  const [displayedAddress, setDisplayedAddress] = useState({
    address: clientDetail?.address,
    zipCode: clientDetail?.zipCode,
    city: clientDetail?.city,
    province: clientDetail?.province,
    clientName: clientDetail?.clientName,
  });
  const [orderNote, setOrderNote] = useState<string>('');
  const [adminNote, setAdminNote] = useState<string>('');

  useEffect(() => {
    if (!orderCompleted) {
      if (clientDetail?.destinationId) {
        setAddressSelectedId(clientDetail?.destinationId);
      }
      setOrderNote(clientDetail?.orderNote);
      setDisplayedAddress({
        address: clientDetail?.address,
        zipCode: clientDetail?.zipCode,
        city: clientDetail?.city,
        province: clientDetail?.province,
        clientName: clientDetail?.clientName,
      });

      const storedDataString = sessionStorage.getItem(StoragesEnum.DATA_SAVE);

      if (storedDataString) {
        const storedDataObject = JSON.parse(storedDataString);
        if (storedDataObject.clientId === clientId) {
          setOrderNote(storedDataObject.orderNote);
          setDisplayedAddress({ ...displayedAddress, address: storedDataObject.addressName });
          setAddressSelectedId(storedDataObject.destinationId);
        }
      }
      setAdminNote(clientDetail.adminNote);
    }

    if (orderCompleted) {
      setOrderNote(order?.order?.note);
    }
  }, [clientDetail]);

  useEffect(() => {
    if (clientDetail.destinations && addressSelectedId) {
      const address = clientDetail.destinations.find((dest: any) => dest.id === addressSelectedId);
      if (address) {
        setDisplayedAddress(address);
      }
    }
  }, [addressSelectedId, clientDetail.destinations]);
  const handleAddProducts = () => {
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientDetail?.id}${ROUTER_PATHS.CATEGORIES}`);
    const storedDataString = sessionStorage.getItem(StoragesEnum.DATA_SAVE);
    if (storedDataString) {
      const dataToSave = { ...JSON.parse(storedDataString), addressName: displayedAddress.address, destinationId: addressSelectedId, clientId: clientId };
      const dataToSaveString = JSON.stringify(dataToSave);
      sessionStorage.setItem(StoragesEnum.DATA_SAVE, dataToSaveString);
    }
  };

  const handleAddressSelected = (value: string) => {
    setAddressSelectedId(value);
  };

  const handleCitySelected = (value: string) => {
    setAddressSelectedId(value);
  };

  const handleChangeOrderNote = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOrderNote(e.target.value);
  };

  const handleChangeNoteAdmin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAdminNote(e.target.value);
  };

  const handleAddNoteAdmin = () => {
    const noteAdminPayload = {
      id: clientId!,
      note: adminNote,
    };
    dispatch(addClientDetailAdminNote(noteAdminPayload))
      .unwrap()
      .then((resp) => {
        message.success(t(`clients_clientDetails_admin_note_success`));
      });
  };

  return (
    <div id='newOrder' className={cx('clientDetailsInformation')}>
      <Flex align='center'>
        <Title level={TITLE_LEVEL}>{t('clients_clientDetails_newOrder_title')}</Title>
      </Flex>

      <Form layout='vertical' size='large'>
        <Row gutter={{ sm: 8, md: 12, lg: 16 }} style={{ marginBottom: 16 }}>
          <Col span={lg ? 12 : 24}>
            {!sm && (
              <Form.Item className={cx('clientDetailsFormItemContainer')}>
                <Form.Item label={t('clients_clientDetails_newOrder_clientCode')} className={cx('clientDetailsFormItemHalfFirst')}>
                  <Tooltip title={clientDetail?.code}>
                    <Input value={clientDetail?.code} readOnly />
                  </Tooltip>
                </Form.Item>
                <Form.Item label={t('clients_clientDetails_newOrder_listN')} className={cx('clientDetailsFormItemHalfLast')}>
                  <Tooltip title={clientDetail?.listingCode}>
                    <Input value={clientDetail?.listingCode} readOnly />
                  </Tooltip>
                </Form.Item>
              </Form.Item>
            )}

            {sm && (
              <Form.Item label={t('clients_clientDetails_newOrder_clientCode')} className={cx('clientDetailsFormItem')}>
                <Tooltip title={clientDetail?.code}>
                  <Input value={clientDetail?.code} readOnly />
                </Tooltip>
              </Form.Item>
            )}

            {sm && (
              <Form.Item label={t('clients_clientDetails_newOrder_listN')} className={cx('clientDetailsFormItem')}>
                <Tooltip title={clientDetail?.listingCode}>
                  <Input value={clientDetail?.listingCode} readOnly />
                </Tooltip>
              </Form.Item>
            )}
            {orderCompleted ? (
              <Form.Item label={t('clients_clientDetails_newOrder_clientName')}>
                <Tooltip title={displayedAddress?.clientName}>
                  <Input value={order?.client?.name} readOnly />
                </Tooltip>
              </Form.Item>
            ) : (
              <Form.Item label={t('clients_clientDetails_newOrder_clientName')}>
                <Tooltip title={displayedAddress?.clientName}>
                  <Input value={displayedAddress?.clientName} readOnly />
                </Tooltip>
              </Form.Item>
            )}
            <Form.Item label={t('clients_clientDetails_newOrder_email')}>
              <Tooltip title={clientDetail?.email}>
                <Input value={clientDetail?.email} readOnly />
              </Tooltip>
            </Form.Item>

            {!lg && (
              <Form.Item label={t('clients_clientDetails_newOrder_clientAddress')}>
                <Select
                  value={displayedAddress?.address}
                  placeholder={t('clients_clientDetails_newOrder_paymentType_placeholder')}
                  onChange={handleAddressSelected}
                  options={(clientDetail?.destinations || []).map((d: any) => ({
                    value: d.id,
                    label: d.address,
                  }))}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  notFoundContent={false}
                  allowClear={false}
                />
              </Form.Item>
            )}

            {!lg && (
              <Form.Item className={cx('clientDetailsFormItemContainer')}>
                <Row gutter={{ sm: 8 }}>
                  <Col xs={24} sm={24} md={6} lg={8} xl={8} xxl={8}>
                    <Form.Item label={t('clients_clientDetails_newOrder_zipCode')} className={cx('clientDetailsFormItem')}>
                      <Tooltip title={displayedAddress?.zipCode}>
                        <Input value={displayedAddress?.zipCode} readOnly />
                      </Tooltip>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                    <Form.Item label={t('clients_clientDetails_newOrder_city')} className={cx('clientDetailsFormItem')}>
                      <Select
                        value={displayedAddress?.city}
                        placeholder={t('clients_clientDetails_newOrder_paymentType_placeholder')}
                        onChange={handleCitySelected}
                        options={(clientDetail?.destinations || []).map((d: any) => ({
                          value: d.id,
                          label: d.city,
                        }))}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        notFoundContent={false}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} xl={8} xxl={8}>
                    <Form.Item label={t('clients_clientDetails_newOrder_province')} className={cx('clientDetailsFormItem')}>
                      <Tooltip title={displayedAddress?.province}>
                        <Input value={displayedAddress?.province} readOnly />
                      </Tooltip>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            )}

            <Form.Item label={t('clients_clientDetails_newOrder_phone')}>
              <Tooltip title={clientDetail?.phone}>
                <Input value={clientDetail?.phone} readOnly />
              </Tooltip>
            </Form.Item>

            {!lg && (
              <Form.Item label={t('clients_clientDetails_newOrder_codiceFiscale')}>
                <Tooltip title={clientDetail?.codiceFiscale}>
                  <Input value={clientDetail?.codiceFiscale} readOnly />
                </Tooltip>
              </Form.Item>
            )}

            <Form.Item label={t('Clients_ClientDetails_newOrder_note')}>
              <TextArea
                onChange={handleChangeNoteAdmin}
                value={adminNote}
                readOnly={role == RolesEnum.ADMIN ? false : true}
                style={{ resize: 'none', backgroundColor: '#99fc96', minHeight: '150px' }}
                maxLength={MAX_LENGTH_ORDER_NOTE}
              />
              {role === RolesEnum.ADMIN ? (
                <Button className={cx('btnAdminNote')} size='large' type='primary' onClick={handleAddNoteAdmin}>
                  {t('clients_clientDetails_newOrder_save')}
                </Button>
              ) : null}
            </Form.Item>

            {orderCompleted && (
              <Form.Item label={t('clients_clientDetails_newOrder_paymentType')}>
                <Tooltip title={order?.client?.paymentName}>
                  <Input value={order?.client?.paymentName} readOnly />
                </Tooltip>
              </Form.Item>
            )}

            {!lg && orderCompleted && (
              <Form.Item label={t('clients_clientDetails_newOrder_orderNote')}>
                <TextArea
                  className={cx('clientDetailsNewOrderNoteMob')}
                  readOnly={orderCompleted}
                  style={{ resize: 'none' }}
                  showCount={!orderCompleted}
                  maxLength={200}
                  allowClear={!orderCompleted}
                  onChange={handleChangeOrderNote}
                  value={orderNote}
                />
              </Form.Item>
            )}
          </Col>

          {lg && (
            <Col span={12}>
              {orderCompleted ? (
                <Form.Item label={t('clients_clientDetails_newOrder_clientAddress')}>
                  <Tooltip title={order?.client?.address}>
                    <Input value={order?.client?.address} />
                  </Tooltip>
                </Form.Item>
              ) : (
                <Form.Item label={t('clients_clientDetails_newOrder_clientAddress')}>
                  <Select
                    value={displayedAddress?.address}
                    placeholder={t('clients_clientDetails_newOrder_paymentType_placeholder')}
                    onChange={handleAddressSelected}
                    options={(clientDetail?.destinations || []).map((d: any) => ({
                      value: d.id,
                      label: d.address,
                    }))}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={false}
                    allowClear={false}
                  />
                </Form.Item>
              )}

              {orderCompleted ? (
                <Form.Item className={cx('clientDetailsFormItemContainer')}>
                  <Row gutter={{ sm: 8 }}>
                    <Col span={6}>
                      <Form.Item label={t('clients_clientDetails_newOrder_zipCode')} className={cx('clientDetailsFormItem')}>
                        <Tooltip title={order?.client?.zipCode}>
                          <Input value={order?.client?.zipCode} readOnly />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t('clients_clientDetails_newOrder_city')} className={cx('clientDetailsFormItem')}>
                        <Tooltip title={order?.client?.city}>
                          <Input value={order?.client?.city} readOnly />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t('clients_clientDetails_newOrder_province')} className={cx('clientDetailsFormItem')}>
                        <Tooltip title={order?.client?.province}>
                          <Input value={order?.client?.province} readOnly />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
                <Form.Item className={cx('clientDetailsFormItemContainer')}>
                  <Row gutter={{ sm: 8 }}>
                    <Col span={6}>
                      <Form.Item label={t('clients_clientDetails_newOrder_zipCode')} className={cx('clientDetailsFormItem')}>
                        <Tooltip title={displayedAddress?.zipCode}>
                          <Input value={displayedAddress?.zipCode} readOnly />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t('clients_clientDetails_newOrder_city')} className={cx('clientDetailsFormItem')}>
                        <Select
                          value={displayedAddress?.city}
                          placeholder={t('clients_clientDetails_newOrder_paymentType_placeholder')}
                          onChange={handleCitySelected}
                          options={(clientDetail?.destinations || []).map((d: any) => ({
                            value: d.id,
                            label: d.city,
                          }))}
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          notFoundContent={false}
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t('clients_clientDetails_newOrder_province')} className={cx('clientDetailsFormItem')}>
                        <Tooltip title={displayedAddress?.province}>
                          <Input value={displayedAddress?.province} readOnly />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              )}

              <Form.Item label={t('clients_clientDetails_newOrder_codiceFiscale')}>
                <Tooltip title={clientDetail?.codiceFiscale}>
                  <Input value={clientDetail?.codiceFiscale} readOnly />
                </Tooltip>
              </Form.Item>

              <Form.Item label={t('clients_clientDetails_newOrder_vatNumber')}>
                <Tooltip title={clientDetail?.vatId}>
                  <Input value={clientDetail?.vatId} readOnly />
                </Tooltip>
              </Form.Item>

              {orderCompleted && (
                <Form.Item label={t('clients_clientDetails_newOrder_orderNote')}>
                  <TextArea
                    className={cx('clientDetailsNewOrderNote')}
                    readOnly={orderCompleted}
                    style={{ resize: 'none' }}
                    showCount={!orderCompleted}
                    maxLength={MAX_LENGTH_ORDER_NOTE}
                    allowClear={!orderCompleted}
                    onChange={handleChangeOrderNote}
                    value={orderNote}
                  />
                </Form.Item>
              )}
            </Col>
          )}
        </Row>
      </Form>

      {addProducts && (
        <Flex align='center' justify='center'>
          <Button size='large' type='primary' onClick={handleAddProducts}>
            {t('clients_clientDetails_newOrder_addProducts')}
          </Button>
        </Flex>
      )}
    </div>
  );
};

export default NewOrder;
