import { createSlice } from '@reduxjs/toolkit';
import { getPayments } from './paymentsThunk';

interface IPaymentsState {
  loading: boolean;
  payments: [];
}

const initialState: IPaymentsState = {
  loading: false,
  payments: [],
};

const paymentsSlice = createSlice({
  name: `payment/get`,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPayments.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload;
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const paymentsActions = paymentsSlice.actions;

export const selectPayments = (state: any) => state.payments.payments?.data?.responses;

const paymentsReducer = paymentsSlice.reducer;
export default paymentsReducer;
