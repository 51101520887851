import { createAsyncThunk } from '@reduxjs/toolkit';
import { clientsApi } from '~/apis/clients';
import { IParamsPayments } from '~/pages/clients/Clients.interface';
import { GET_PAYMENTS } from '~/utils/actionType';

export const getPayments = createAsyncThunk(GET_PAYMENTS, async (params: IParamsPayments, { rejectWithValue }) => {
  try {
    const res = await clientsApi.getPayments(params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
