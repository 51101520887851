import { createAsyncThunk } from '@reduxjs/toolkit';
import { clientsApi } from '~/apis/clients';
import { IClientsParams } from '~/pages/clients/Clients.interface';
import { GET_CLIENTS, SEARCH_CLIENTS } from '~/utils/actionType';

export const getClients = createAsyncThunk(GET_CLIENTS, async (params: IClientsParams, { rejectWithValue }) => {
  try {
    const res = await clientsApi.getClients(params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchClients = createAsyncThunk(SEARCH_CLIENTS, async (params: IClientsParams, { rejectWithValue }) => {
  try {
    const res = await clientsApi.getClients(params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
