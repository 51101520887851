import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateDatabaseApi } from '~/apis/updateDatabase';
import { UPDATE_DATABASE } from '~/utils/actionType';

export const updateDatabase = createAsyncThunk(UPDATE_DATABASE, async (payload: FormData, { rejectWithValue }) => {
  try {
    const res = await updateDatabaseApi.uploadDatabase(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
