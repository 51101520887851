// Libs
import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useParams, useSearchParams } from 'react-router-dom';
// Styles
import styles from './ClientDetails.module.scss';
// Interface
import { IPayloadClientDetailId } from '../../Clients.interface';
// Thunk
import { getClientDetail } from '~/thunks/clientDetail/clientDetailThunk';
import { selectClientDetail } from '~/thunks/clientDetail/clientDetailSlice';
// Hooks
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Components
import CartTable from '~/components/specific/cartTable/CartTable';
import NewOrder from '~/components/specific/newOrder/NewOrder';
import RevenueTable from '~/components/specific/revenueTable/RevenueTable';
import { LoadingContext } from '~/contexts/LoadingContext';
// Utils
import { StoragesEnum } from '~/utils/enum';

const cx = classNames.bind(styles);

const ClientDetails: React.FC = ({ ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const { clientId } = useParams();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();

  const clientDetail = useAppSelector(selectClientDetail);

  useEffect(() => {
    handleGetDetailClient({ id: clientId! });
  }, []);

  const handleGetDetailClient = (payload: IPayloadClientDetailId) => {
    loadingContext?.show();

    dispatch(getClientDetail(payload))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        localStorage.setItem(StoragesEnum.PAYMENT_ID, res?.data?.paymentId);
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  return (
    <div id='clientDetails' className={cx('clientDetailsContainer')}>
      <NewOrder addProducts clientId={clientId} />
      <RevenueTable />

      <CartTable actions={false} clearCart={false} />
    </div>
  );
};

export default ClientDetails;
