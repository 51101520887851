import { createSlice } from '@reduxjs/toolkit';
import { createAgent } from './agentThunk';

interface IAgentState {
  loading: boolean;
}

const initialState: IAgentState = {
  loading: false,
};

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createAgent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createAgent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createAgent.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const agentActions = agentSlice.actions;

const agentReducer = agentSlice.reducer;
export default agentReducer;
