import { createSlice } from '@reduxjs/toolkit';
import { getClients } from './clientsThunk';

export interface ClientsState {
  clients: [];
  loading: boolean;
}

const initialState: ClientsState = {
  clients: [],
  loading: false,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClients.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.loading = false;
        state.clients = action.payload;
      })
      .addCase(getClients.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const clientsActions = clientsSlice.actions;

export const selectClients = (state: any) => state.clients.clients?.data?.responses;
export const selectPagination = (state: any) => state.clients.clients?.data?.pagination;
export const selectLoading = (state: any) => state.clients.loading;

const clientsReducer = clientsSlice.reducer;
export default clientsReducer;
