// Libs
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
// Ant design
import { Space, Input, Typography, Row, Col, Button, message, Tooltip, Modal, AutoComplete, Flex } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
// i18next
import { useTranslation } from 'react-i18next';
// Interface
import { ICategory, IParamsCategories } from '../../Clients.interface';
// Styles
import styles from './ClientDetailsCategories.module.scss';
// Hooks
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Components
import CartTable from '~/components/specific/cartTable/CartTable';
import { LoadingContext } from '~/contexts/LoadingContext';
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';
// Thunk
import { getCategories, getCategoriesSearchResult } from '~/thunks/categories/categoriesThunk';
import { selectLoadingCategories } from '~/thunks/categories/categoriesSlice';
import { searchProductsByName } from '~/thunks/product/productThunk';
// Utils
import { PAGE_DEFAULT, ROUTER_PATHS, SEARCH_RESULT_TYPE, TITLE_LEVEL, TYPE_SEARCH_CATEGORIES, TYPE_SEARCH_PRODUCT } from '~/utils/constants';
import { getErrorMessageByCode } from '~/utils/helper';

const cx = classNames.bind(styles);

const { Search } = Input;
const { Title, Text } = Typography;

interface OptionType {
  id: string;
  type: string;
  productId: string;
}

const ClientDetailsCategories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const [categoriesData, setCategoriesData] = useState<ICategory[]>([]);
  const [pageSelected, setPageSelected] = useState<number>(Number(params?.page) || PAGE_DEFAULT);
  const [searchKey, setSearchKey] = useState<string>(params?.searchKey || '');
  const [searchResult, setSearchResult] = useState<ICategory[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const { clientId } = useParams();

  const loadingCategory = useAppSelector(selectLoadingCategories);

  const goBack = () => {
    navigate(-1);
  };

  const handleSearchCategory = (value: string) => {
    setSearchKey(value);

    if (value === '') {
      setSearchResult([]);
      return;
    }

    let payload = {
      clientId: clientId!,
      searchKey: value,
      type: SEARCH_RESULT_TYPE.CATEGORY,
    };

    dispatch(getCategoriesSearchResult(payload))
      .unwrap()
      .then((res) => {
        const convertSearchResult = res?.data?.map((category: ICategory, index: number) => {
          return {
            ...category,
            value: index,
            label: `${(category?.type === SEARCH_RESULT_TYPE.PRODUCT && category?.code) || ''} ${category?.name}`,
            type: category?.type || TYPE_SEARCH_CATEGORIES,
          };
        });
        setSearchResult(convertSearchResult);
      })
      .catch((error) => {});
  };

  const debounceSearchCategories = debounce(handleSearchCategory, 400);

  const handleSelectedSearchResult = (_: string, option: OptionType) => {
    const { id, productId, type } = option;

    let queryString = '';
    if (type === SEARCH_RESULT_TYPE.PRODUCT && productId) {
      queryString = `?productId=${productId}`;
    }

    switch (type) {
      case SEARCH_RESULT_TYPE.CATEGORY:
        navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.SUBCATEGORIES}/${id}`);
        break;
      case SEARCH_RESULT_TYPE.PRODUCT:
        navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.PRODUCTS}/${id}${queryString}`);
        break;
      default:
        navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.SUBCATEGORIES}/${id}`);
        break;
    }
  };

  const handleClickCategory = (id: string) => {
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.SUBCATEGORIES}/${id}`);
  };

  const handleLoadMoreCategories = () => {
    setPageSelected(pageSelected + 1);
  };

  useEffect(() => {
    loadingContext?.show();
    const payload: IParamsCategories = {
      clientId: clientId!,
      page: pageSelected,
    };

    dispatch(getCategories(payload))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        const newCategories = res?.data?.responses;
        setCategoriesData([...categoriesData, ...newCategories]);
        setTotalPage(res?.data?.pagination?.totalPages);
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  }, [pageSelected]);

  return (
    <>
      <div className={cx('clientDetailsCategories')}>
        <Row align={'middle'} justify={'space-between'} gutter={{ xs: 8, sm: 16, md: 24 }} className={cx('clientDetailsCategoriesLayout')}>
          <Col className={cx('clientsTableHeaderTitle')}>
            <Title level={TITLE_LEVEL}>{t('clients_clientDetails_categories_title')}</Title>
          </Col>
          <Col>
            <AutoComplete
              className={cx('autoCompleteClientDetailsCategoriesSearch')}
              onSearch={debounceSearchCategories}
              onSelect={handleSelectedSearchResult}
              options={searchResult}
            >
              <Search size='middle' className={cx('clientDetailsCategoriesSearch')} enterButton placeholder={t('clients_clientDetails_categories_search_placeholder')} allowClear />
            </AutoComplete>
          </Col>
        </Row>

        {!loadingCategory && (
          <>
            {categoriesData?.length > 0 ? (
              <Flex gap={16} vertical>
                <Row gutter={[8, 8]}>
                  {categoriesData.map((item: ICategory) => (
                    <Col xs={24} sm={8} md={6} lg={4} xxl={3} key={item.code}>
                      <Tooltip overlay={item.name}>
                        <Flex className={cx('clientDetailsCategory')} onClick={() => handleClickCategory(item.id)}>
                          <Flex gap={8} align='center' justify='center'>
                            <TagsOutlined className={cx('clientDetailsCategoryItemIcon')} />
                            <Text className={cx('clientDetailsCategoryItemLabel')}>{item.code + ' ' + item.name}</Text>
                          </Flex>
                        </Flex>
                      </Tooltip>
                    </Col>
                  ))}
                </Row>

                {pageSelected < totalPage && (
                  <Button onClick={handleLoadMoreCategories} size='large' type='primary'>
                    {t('common_button_loadingMore')}
                  </Button>
                )}
              </Flex>
            ) : (
              <EmptyTable />
            )}
          </>
        )}
      </div>

      <CartTable handleGoBack={goBack} />
    </>
  );
};

export default ClientDetailsCategories;
