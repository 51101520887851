// Libs
import { useEffect, useState } from 'react';
import { Button, Form, Input, Layout } from 'antd';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Thunks
import { authLoading } from '~/thunks/auth/authSlice';
// Shares
import styles from './ForgotPassword.module.scss';
import { IForgotPassword } from './ForgotPassword.interface';
import { imgBannerLogin } from '~/utils/image.constants';
import { ROUTER_PATHS } from '~/utils/constants';

const cx = classNames.bind(styles);

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useSelector(authLoading);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  const handleForgotPasswordForm = (values: IForgotPassword) => {
    navigate(ROUTER_PATHS.RESET_PASSWORD);
  };

  return (
    <Layout id='forgotPassword' className={cx('mainLayout')}>
      <div className={cx('layoutForgotTop')}>
        <img src={imgBannerLogin} className={cx('imgBanner')} alt='Login Banner' />
      </div>
      <div className={cx('forgotForm')}>
        <Form name='basic' form={form} onFinish={handleForgotPasswordForm} layout='vertical' size='large' autoComplete='off'>
          <Form.Item<IForgotPassword>
            name='email'
            label={t('forgot_password_label_email')}
            rules={[
              { required: true, message: t('forgot_password_require_message') },
              {
                type: 'email',
                message: t('forgot_password_invalid_message'),
              },
            ]}
          >
            <Input placeholder={t('forgot_password_placeholder')} />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type='primary' htmlType='submit' className={cx('btnForgot')}>
              {t('forgot_password_send')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
