// Libs
import axiosClient from './axiosClient';
// Utils
import { URL_API_CART } from '~/utils/constants';
// Interface
import { IPayloadCreateAndUpdateCart, IPayloadDeleteProductInCart, IPayloadEmptyCart, IPayloadGetProductsInCart, IPayloadUpdateProductInCart } from '~/data/cart';

export const cartApi = {
  createAndUpdateCart: (payload: IPayloadCreateAndUpdateCart) => {
    const url = URL_API_CART.ADD_PRODUCT_TO_CART;
    return axiosClient.post(url, payload);
  },
  updateProductInCart: (payload: IPayloadUpdateProductInCart) => {
    let url = `${URL_API_CART.UPDATE_PRODUCT_IN_CART}`;

    if (payload.cartProductId) {
      url += `/${payload.cartProductId}`;
    }

    return axiosClient.put(url, { quantity: payload.quantity, discount: payload.discount });
  },
  getProductsInCart: (payload: IPayloadGetProductsInCart) => {
    let url = `${URL_API_CART.GET_PRODUCTS_IN_CART}`;

    if (payload.clientId) {
      url += `/${payload.clientId}`;
    }

    return axiosClient.get(url);
  },
  deleteProductInCart: (payload: IPayloadDeleteProductInCart) => {
    let url = `${URL_API_CART.DELETE_PRODUCT_IN_CART}`;

    if (payload.productId) {
      url += `/${payload.productId}`;
    }

    return axiosClient.delete(url);
  },
  emptyCart: (payload: IPayloadEmptyCart) => {
    let url = `${URL_API_CART.EMPTY_CART}`;

    if (payload.clientId) {
      url += `/${payload.clientId}`;
    }

    return axiosClient.delete(url);
  },
};
