// Libs
import axiosClient from './axiosClient';
// Interface
import { IPayloadClientDetailId, IClientsParams, IParamsPayments, IPayloadClientDetailAdminNote } from '~/pages/clients/Clients.interface';
// Utils
import { URL_API_CLIENTS } from '~/utils/constants';
import { removeLastCharacter } from '~/utils/helper';

export const clientsApi = {
  getClients: (params: IClientsParams) => {
    let url = `${URL_API_CLIENTS.GET_CLIENTS}?`;

    if (params?.page) {
      url += `page=${params.page}&`;
    }

    if (params?.limit) {
      url += `limit=${params.limit}&`;
    }

    if (params?.searchKey) {
      url += `searchKey=${params.searchKey}&`;
    }

    if (params?.sort) {
      url += `sort=${params.sort}&`;
    }

    if (params?.order) {
      url += `order=${params.order}&`;
    }

    return axiosClient.get(url);
  },

  getPayments: (params: IParamsPayments) => {
    let url = `${URL_API_CLIENTS.GET_PAYMENTS}?`;

    if (params?.searchKey) {
      url += `searchKey=${params.searchKey}&`;
    }

    return axiosClient.get(removeLastCharacter(url));
  },
};

export const clientDetailApi = {
  getClientDetail: (payload: IPayloadClientDetailId) => {
    const url = `${URL_API_CLIENTS.GET_CLIENT_DETAIL}/${payload?.id}`;
    return axiosClient.get(url);
  },
  addClientDetailAdminNote: (payload: IPayloadClientDetailAdminNote) => {
    const url = `${URL_API_CLIENTS.GET_CLIENT_DETAIL}/${payload?.id}/note`;
    return axiosClient.put(url, { note: payload.note });
  },
};
