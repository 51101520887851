export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const URL_API_AUTH = {
  LOGIN: `/auth/login`,
  REFRESH_TOKEN: `/auth/refresh-token`,
};

export const URL_API_PROFILE = {
  GET_PROFILE: `/accounts/profile`,
  CHANGE_PASSWORD: `/accounts/change-password-current`,
  CHANGE_EMAIL: `/accounts/change-email`,
};

export const URL_API_AGENT = {
  CREATE_AGENT: `/accounts`,
  GET_LIST_AGENT: `/accounts/list-agent`,
  DELETE_AGENT: `/accounts`,
  GET_DETAIL_AGENT: `/accounts/detail-agent`,
  UPDATE_AGENT: `/accounts`,
};

export const URL_API_CATEGORIES = {
  GET_CATEGORIES: `/categories`,
  SEARCH_CATEGORY: '/search/results',
};

export const URL_API_SUB_CATEGORIES = {
  GET_SUB_CATEGORIES_BY_CATEGORY: `/sub-categories`,
  SEARCH_SUB_CATEGORY: '/search/results',
};

export const URL_API_CLIENTS = {
  GET_CLIENTS: `/clients/list-client`,
  GET_CLIENT_DETAIL: `/clients/detail-client`,
  GET_PAYMENTS: `/payments`,
};

export const URL_API_UPDATE_DATABASE = {
  UPDATE_DATABASE: `/migration/migration-data`,
};

export const URL_API_PRODUCTS = {
  GET_PRODUCTS_BY_SUB_CATEGORY: `/products/sub-category`,
  SEARCH_PRODUCT: '/search/results',
  GET_CLIENT: `/client`,
};

export const URL_API_CART = {
  ADD_PRODUCT_TO_CART: `/carts/add-products`,
  UPDATE_PRODUCT_IN_CART: `/carts/cart-product`,
  GET_PRODUCTS_IN_CART: `/carts/detail-cart`,
  DELETE_PRODUCT_IN_CART: `/carts/cart-product`,
  EMPTY_CART: `/carts/empty-cart`,
};

export const DEFAULT_LANGUAGE = 'English';

export const URL_API_ORDER = {
  GET_ORDERS: `/orders/list-order`,
  DELETE_ORDER: `/orders`,
  CREATE_ORDERS: `/orders`,
  GET_ORDER: `/orders`,
  SEND_PDF_EMAIL: `/orders/send-email`,
};

export const ROLE_ADMIN_MENU = 'Admin';

export const LANGUAGE_OPTION = [
  {
    KEY: 'it',
    VALUE: 'it',
    LABEL: 'Italiano',
  },
  {
    KEY: 'en',
    VALUE: 'en',
    LABEL: 'English',
  },
];

export const CLIENTS_SORT_OPTION = [
  {
    KEY: 'code',
    CHILDREN: 'client_code',
  },
  {
    KEY: 'name',
    CHILDREN: 'client_name',
  },
  {
    KEY: 'city',
    CHILDREN: 'client_city',
  },
];

export const CHARACTER_MIN_PASSWORD = 6;

export const SCROLL_X_TABLE = 576;

export const MAX_COUNT_FILE = 1;

// Router && Path
export const ROUTER_PATHS = {
  MAIN: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgotPassword',
  RESET_PASSWORD: '/resetPassword',
  RESET_PASSWORD_SUCCESS: '/resetPasswordSuccess',

  CLIENT: '/client',
  CLIENT_DETAILS: '/clients/clientDetails',
  CATEGORIES: '/categories',
  SUBCATEGORIES: '/categories/subCategories',
  PRODUCTS: '/products/subCategory',
  ORDER_COMPLETED: '/clients/clientDetails/orderCompleted',

  AGENTS: '/agents',
  AGENTS_ADD: '/agents/addAgent',
  AGENTS_EDIT: '/agents/editAgent',

  ORDER: '/orders',
  ORDER_DETAIL: '/orders/orderDetail',
};

export const EXACT_PATH = {
  MAIN: '/',
  AUTH: {
    LOGIN: `/${ROUTER_PATHS.LOGIN}`,
  },
};

export const ADMIN_PROTECTED_PATH = {
  CLIENTS: { path: 'clients', key: 'clients', title: 'clients', selectedKey: 'clients' },
  CLIENT_DETAILS: {
    path: 'clientDetails',
    key: 'clientDetails',
    title: 'Client Details',
    selectedKey: 'clientDetails',
  },
  CLIENT_DETAILS_CATEGORIES: {
    path: 'categories',
    key: 'categories',
    title: 'Client Details Categories',
    selectedKey: 'categories',
  },
  CLIENT_DETAILS_SUB_CATEGORIES: {
    path: 'subCategories',
    key: 'subCategories',
    title: 'Client Details Sub Categories',
    selectedKey: 'subCategories',
  },
  CLIENT_DETAILS_PRODUCTS: {
    path: 'products',
    key: 'products',
    title: 'Client Details Products',
    selectedKey: 'products',
  },
  CLIENT_DETAILS_ORDER_COMPLETED: {
    path: 'orderCompleted',
    key: 'orderCompleted',
    title: 'Client Details Order Completed',
    selectedKey: 'orderCompleted',
  },
  ORDERS: { path: 'orders', key: 'orders', title: 'orders', selectedKey: 'orders' },
  UPDATE_DATABASE: {
    path: 'updateDatabase',
    key: 'updateDatabase',
    title: 'update_database',
    selectedKey: 'updateDatabase',
  },
  PROFILE: { path: 'profile', key: 'profile', title: 'profile', selectedKey: 'profile' },
  AGENT: { path: 'agents', key: 'agents', title: 'agents', selectedKey: 'agents' },
};

export const MENU_KEY = {
  LOGOUT: 'logout',
  PROFILE: 'profile',
};

export const DEFAULT_MIN_VALUE_QUANTITY = 1;

export const DEFAULT_MIN_VALUE_DISCOUNT = 0;

export const DEFAULT_MAX_VALUE_DISCOUNT = 100;

export const MIN_X_SCROLL = 576;

export const PLUS_ICON_COLOR = '#1677ff';

export const MAX_LENGTH_ORDER_NOTE = 200;

export const TYPE_SEARCH_PRODUCT = 'product';

export const TYPE_SEARCH_CATEGORIES = 'categories';

export const TYPE_SEARCH_SUB_CATEGORIES = 'subCategories';

export const UPLOAD_FILE_ERROR = 'error';

export const UPDATE_DATABASE_TEXT_PLAIN = 'text/plain';

export const Y_SCROLL = `calc(100vh - 64px - 16px - 24px - 40px - 16px - 55px - 65px - 24px - 16px - 30px )`;

export const Y_SCROLL_ORDERS = `calc(100vh - 64px - 16px - 24px - 40px - 16px - 55px - 65px - 24px - 16px - 110px )`;

export const SORT_COLUMN_BY = {
  AGENT: {
    CODE: {
      key: 'code',
      param: 'CODE',
    },
    NAME: {
      key: 'name',
      param: 'NAME',
    },
    SURNAME: {
      key: 'surname',
      param: 'SURNAME',
    },
    EMAIL: {
      key: 'email',
      param: 'EMAIL',
    },
  },
  CLIENT: {
    CODE: {
      key: 'code',
      param: 'CODE',
    },
    NAME: {
      key: 'name',
      param: 'NAME',
    },
    CITY: {
      key: 'city',
      param: 'CITY',
    },
  },
  ORDER: {
    NUMBER: {
      key: 'id',
      param: 'ID',
    },
    DATE: {
      key: 'createdAt',
      param: 'CREATED_BY',
    },
    CLIENT_NAME: {
      key: 'clientName',
      param: 'CLIENT_NAME',
    },
    AGENT: {
      key: 'agentSurname',
      param: 'AGENT',
    },
    TOTAL: {
      key: 'total',
      param: 'TOTAL_PRICE',
    },
  },
};

// Type Order
export const TYPE_ORDER = {
  DESCEND: {
    sortOrder: 'descend',
    sortOrderQuery: 'DESC',
  },
  ASCEND: {
    sortOrder: 'ascend',
    sortOrderQuery: 'ASC',
  },
  CANCEL: {
    sortOrder: undefined,
    sortOrderQuery: 'CANCEL',
  },
};

export const TYPE_ORDER_DESC = 'descend';

export const TYPE_ORDER_ASC = 'ascend';

export const OPTIONS_ORDER_BY = {
  DESC: { LABEL: 'DESC', KEY: 'descend' },
  ASC: { LABEL: 'ASC', KEY: 'ascend' },
};

// Table
export const PAGE_DEFAULT = 1;

export const LIMIT_DEFAULT = 25;

export const FIRST_PAGE = 1;

// Database
export const UPDATE_DATABASE_TYPE = {
  CATEGORY: {
    key: 1,
    type: 'CATEGORY',
  },
  PAYMENT: {
    key: 2,
    type: 'PAYMENT',
  },
  PRODUCT: {
    key: 3,
    type: 'PRODUCT',
  },
  LISTING: {
    key: 4,
    type: 'LISTING',
  },
  CLIENT: {
    key: 5,
    type: 'CLIENT',
  },
  DESTINATION: {
    key: 6,
    type: 'DESTINATION',
  },
};

export const SCROLL_Y_TABLE_LINE_ERROR = 600;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TITLE_LEVEL = 4;

export const MESSAGE_DURATION = 5;

export const UNAUTHORIZE_CODE = 401;

export const UNICODE_EURO = '\u20AC';

export const TIMEZONE = 'Asia/Ho_Chi_Minh';

export const DATA_CODE_MESSAGE: { [key: number]: string } = {
  10000: 'code_message_common_success',
  10001: 'code_message_common_failed',
  10002: 'code_message_common_error',
  10003: 'code_message_common_bad_request',
  10004: 'code_message_common_forbidden',
  10005: 'code_message_common_unauthorized',
  10006: 'code_message_common_not_found',
  11000: 'code_message_file_file_is_required',
  11001: 'code_message_file_upload_file_successful',
  11002: 'code_message_file_upload_file_failed',
  11003: 'code_message_file_only_accepts_image_files',
  11004: 'code_message_file_file_must_be_video',
  11005: 'code_message_file_only_accepts_text_file',
  12000: 'code_message_auth_invalid_api_key',
  13000: 'code_message_account_account_not_found',
  13001: 'code_message_account_account_already_exist',
  13002: 'code_message_account_account_is_deactivated',
  13003: 'code_message_account_account_is_deleted',
  13004: 'code_message_account_cannot_create_token_api',
  13005: 'code_message_account_get_account_information_successfully',
  13006: 'code_message_account_create_account_successfully',
  13007: 'code_message_account_create_account_failed',
  13008: 'code_message_account_the_new_email_must_not_be_the_same_as_the_current_email',
  13009: 'code_message_account_email_already_exists',
  13010: 'code_message_account_change_email_successfully',
  13011: 'code_message_account_update_account__change_email_failed',
  13012: 'code_message_account_update_account_agent_successfully',
  13013: 'code_message_account_update_account_failed',
  13014: 'code_message_account_delete_account_successfully',
  13015: 'code_message_account_delete_account_failed',
  13016: 'code_message_account_incorrect_account_or_password',
  13017: 'code_message_account_the_new_password_must_not_be_the_same_as_the_current_password',
  13018: 'code_message_account_get_list_agent_successfully',
  13019: 'code_message_account_get_list_agent_failed',
  13020: 'code_message_account_get_agent_successfully',
  13021: 'code_message_account_get_agent_failed',
  14000: 'code_message_migration_import_success',
  14001: 'code_message_migration_import_failed',
  14002: 'code_message_migration_field_is_invalid',
  14003: 'code_message_migration_incomplete_data',
  14004: 'code_message_migration_category_has_not_been_inserted',
  14005: 'code_message_migration_sub_category_has_not_been_inserted',
  14006: 'code_message_migration_product_has_not_been_inserted',
  14007: 'code_message_migration_payment_or_listing_has_not_been_inserted',
  14008: 'code_message_migration_client_has_not_been_inserted',
  15000: 'code_message_product_get_list_product_successfully',
  15001: 'code_message_product_get_list_product_failed',
  15002: 'code_message_product_get_list_product_by_subcategory_successfully',
  15003: 'code_message_product_get_list_product_by_subcategory_failed',
  15004: 'code_message_search_product_failed',
  16000: 'code_message_client_get_list_client_successfully',
  16001: 'code_message_client_get_list_client_failed',
  16002: 'code_message_client_get_client_failed',
  16003: 'code_message_client_get_client_successfully',
  16004: 'code_message_client_client_not_found',
  17000: 'code_message_category_category_not_found',
  17001: 'code_message_category_get_list_category_successfully',
  17002: 'code_message_category_get_list_category_failed',
  18000: 'code_message_sub_category_get_list_sub_category_successfully',
  18001: 'code_message_sub_category_get_list_sub_category_failed',
  18002: 'code_message_sub_category_field_is_invalid',
  18003: 'code_message_sub_category_subcategory_not_found',
  18004: 'code_message_sub_category_get_sub_category_failed',
  19000: 'code_message_order_create_order_successfully',
  19001: 'code_message_order_create_order_failed',
  19002: 'code_message_order_order_not_found',
  19003: 'code_message_order_delete_order_successfully',
  19004: 'code_message_order_delete_order_failed',
  19005: 'code_message_order_get_list_order_successfully',
  19006: 'code_message_order_get_list_order_category_failed',
  19007: 'code_message_order_get_detail_order_successfully',
  19008: 'code_message_order_get_detail_order_failed',
  20000: 'code_message_cart_get_list_cart_successfully',
  20001: 'code_message_cart_get_list_cart_failed',
  20002: 'code_message_cart_create_cart_failed',
  20003: 'code_message_cart_create_successfully',
  20004: 'code_message_cart_update_cart_failed',
  20005: 'code_message_cart_update_cart_successfully',
  20006: 'code_message_cart_get_detail_cart_successfully',
  20007: 'code_message_cart_get_detail_cart_failed',
  20008: 'code_message_cart_cart_not_found',
  20009: 'code_message_cart_empty_cart_successfully',
  20010: 'code_message_cart_empty_cart_failed',
  20011: 'code_message_cart_get_cart_failed',
  21000: 'code_message_cart_product_get_list_cart_product_successfully',
  21001: 'code_message_cart_product_get_list_cart_product_failed',
  21002: 'code_message_cart_product_delete_cart_product_successfully',
  21003: 'code_message_cart_product_delete_cart_product_failed',
  21004: 'code_message_cart_product_cart_product_not_found',
  21005: 'code_message_cart_product_get_cart_product_failed',
  22000: 'code_message_payment_get_list_payment_successfully',
  22001: 'code_message_payment_get_list_payment_failed',
  22002: 'code_message_payment_get_payment_failed',
  23000: 'code_message_mail_send_mail_failed',
  23001: 'code_message_mail_mailer_validation_error',
  23002: 'code_message_mail_mailer_validation_success',
};

// Search result type
export const SEARCH_RESULT_TYPE = {
  CATEGORY: 'CATEGORY',
  SUB_CATEGORY: 'SUB_CATEGORY',
  PRODUCT: 'PRODUCT',
};

export const MAX_DISCOUNT = "S"

export const HIGHLIGHT_PRODUCT = "productId"