// Libs
import { Button, Layout, Result } from 'antd';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
// Scss
import styles from './NotFound.module.scss';
// Utils
import { EXACT_PATH, ROUTER_PATHS } from '~/utils/constants';
import { StoragesEnum } from '~/utils/enum';

type Props = {};
const cx = classNames.bind(styles);

const NotFoundPage = (props: Props) => {
  const navigate = useNavigate();

  const accessToken = localStorage.getItem(StoragesEnum.ACCESS_TOKEN)!;
  const currentRole = localStorage.getItem(StoragesEnum.ROLE);

  const handleGoHome = () => {
    if (accessToken && currentRole) {
      navigate(EXACT_PATH.MAIN);
    } else {
      navigate(ROUTER_PATHS.LOGIN);
    }
  };

  return (
    <Layout className={cx('main_layout')}>
      <div className={cx('main_content')}>
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button type='primary' onClick={handleGoHome}>
              Back Home
            </Button>
          }
        />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
