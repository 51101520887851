import { URL_API_PROFILE } from '~/utils/constants';
import axiosClient from './axiosClient';
import { IChangeEmail } from '~/pages/profile/Profile.interface';

export const profileApi = {
  getProfile: () => {
    const url = URL_API_PROFILE.GET_PROFILE;
    return axiosClient.get(url);
  },
};

export const changeEmailApi = {
  changeEmail: (payload: IChangeEmail) => {
    const url = URL_API_PROFILE.CHANGE_EMAIL;
    return axiosClient.put(url, payload);
  },
};
