import { URL_API_ORDER } from '~/utils/constants';
import { removeLastCharacter } from '~/utils/helper';
import axiosClient from './axiosClient';
import { IParamsOrder, IPayloadCreateOrder, IPayloadDeleteOrder, IPayloadGetDetailOrder, IPayloadSendPDFEmail } from '~/pages/orders/Orders.interface';

export const orderApi = {
  getOrders: (payload: IParamsOrder) => {
    let url = `${URL_API_ORDER.GET_ORDERS}?`;

    if (payload?.searchKey) {
      url += `searchKey=${payload?.searchKey}&`;
    }
    if (payload?.page) {
      url += `page=${payload.page}&`;
    }
    if (payload?.limit) {
      url += `limit=${payload.limit}&`;
    }
    if (payload?.sort) {
      url += `sort=${payload.sort}&`;
    }
    if (payload?.order) {
      url += `order=${payload.order}&`;
    }
    if (payload?.dateFrom) {
      url += `dateFrom=${payload.dateFrom}&`;
    }
    if (payload?.dateTo) {
      url += `dateTo=${payload.dateTo}&`;
    }
    if (payload?.timezone) {
      url += `timezone=${payload.timezone}&`;
    }

    return axiosClient.get(removeLastCharacter(url));
  },
  deleteOrder: (payload: IPayloadDeleteOrder) => {
    let url = `${URL_API_ORDER.DELETE_ORDER}/${payload.orderId}`;

    return axiosClient.delete(url);
  },
  createOrder: (payload: IPayloadCreateOrder) => {
    const url = URL_API_ORDER.CREATE_ORDERS;

    return axiosClient.post(url, payload);
  },

  getOrder: (payload: IPayloadGetDetailOrder) => {
    let url = `${URL_API_ORDER.GET_ORDER}/${payload.orderId}`;

    return axiosClient.get(url);
  },

  sendPDFEmail: (payload: FormData) => {
    let url = `${URL_API_ORDER.SEND_PDF_EMAIL}`;
    return axiosClient.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
};
