import { createSlice } from '@reduxjs/toolkit';
import { getCategories, getCategoriesSearchResult } from './categoriesThunk';

interface ICategoriesState {
  categories: [];
  searchResult: [];
  loading: boolean;
}

const initialState: ICategoriesState = {
  categories: [],
  searchResult: [],
  loading: false,
};

const categoriesSlice = createSlice({
  initialState,
  name: 'categories',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCategories.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectCategories = (state: any) => state.categories.categories;
export const selectLoadingCategories = (state: any) => state.categories.loading;

export const categoriesActions = categoriesSlice.actions;

const categoriesReducer = categoriesSlice.reducer;
export default categoriesReducer;
