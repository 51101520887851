import { createSlice } from '@reduxjs/toolkit';
import { sendPDFOrderEmail } from './orderThunk';

interface ISendPDFOrderEmailState {
  loading: boolean;
}

const initialState: ISendPDFOrderEmailState = {
  loading: false,
};

const sendPDFEmailSlice = createSlice({
  name: 'sendPDFEmail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendPDFOrderEmail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(sendPDFOrderEmail.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendPDFOrderEmail.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const sendPDFOrderEmailActions = sendPDFEmailSlice.actions;

export const selectLoading = (state: any) => state.sendPDFOrderEmail.loading;

const sendPDFEmailOrderReducer = sendPDFEmailSlice.reducer;
export default sendPDFEmailOrderReducer;
