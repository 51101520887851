import { createSlice } from '@reduxjs/toolkit';
import { getProductsBySubCategory } from './productThunk';

interface IProductsState {
  products: {};
  loading: boolean;
}

const initialState: IProductsState = {
  products: {},
  loading: false,
};

const productsSlice = createSlice({
  initialState,
  name: 'product/listProduct',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProductsBySubCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProductsBySubCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action?.payload?.data;
      })
      .addCase(getProductsBySubCategory.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectListProducts = (state: any) => state.listProduct.products;
export const selectLoadingProducts = (state: any) => state.listProduct.loading;

export const productsActions = productsSlice.actions;

const productsReducer = productsSlice.reducer;
export default productsReducer;
