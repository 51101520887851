// Libs
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { LoadingContext } from '~/contexts/LoadingContext';
import { pdf } from '@react-pdf/renderer';
// Ant design
import { Button, Col, Flex, Row, Typography, message } from 'antd';
// i18next
import { useTranslation } from 'react-i18next';
// Styles
import styles from './ClientDetailsOrderCompleted.module.scss';
// Components
import CartTable from '~/components/specific/cartTable/CartTable';
import NewOrder from '~/components/specific/newOrder/NewOrder';
import { DocumentPDF } from '~/components/documentPDF/DocumentPdf';
import { sendPDFOrderEmail } from '~/thunks/order/orderThunk';
import { getErrorMessageByCode, splitDateTime } from '~/utils/helper';
import { RolesEnum, StoragesEnum } from '~/utils/enum';
import { TITLE_LEVEL, UNICODE_EURO } from '~/utils/constants';
import { ColumnsType } from 'antd/es/table';
import { IOrderDetail } from '~page/orders/orderDetail/OrderDetail.interface';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectDetailCreateOrder } from '~/thunks/order/createOrderSlice';
import { selectProductsInCart } from '~/thunks/cart/getProductsInCartSlice';

const cx = classNames.bind(styles);

const { Title } = Typography;

const ClientDetailsOrderCompleted: React.FC = ({ ...props }) => {
  const order = useAppSelector(selectDetailCreateOrder);
  const productInCart = useAppSelector(selectProductsInCart);
  const { detailCart } = productInCart;
  const [isAdmin, setIsAdmin] = useState(false);
  const [dataDetailCart, setDataDetailCart] = useState(detailCart);
  const [dataOrder, setDataOrder] = useState(order);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);

  const getRoleLocalStorage = localStorage.getItem(StoragesEnum.ROLE);

  const { dateString, timeString } = splitDateTime(order?.createdAt);

  useEffect(() => {
    const dataOrder = {
      ...order.client,
      total: order.order?.total,
    };
    setDataOrder(dataOrder);
  }, [order]);

  useEffect(() => {
    const dataDetailCart = detailCart?.map((product: any) => {
      return {
        ...product,
        priceProduct: product.price,
      };
    });
    setDataDetailCart(dataDetailCart);
  }, [detailCart]);

  const dateTimePDF = {
    orderId: order?.order?.id,
    dateString,
    timeString,
  };

  const columns: ColumnsType<IOrderDetail> = [
    {
      title: t('orders_orderDetail_cart_table_id'),
      dataIndex: 'productCode',
    },
    {
      title: t('orders_orderDetail_cart_table_productName'),
      dataIndex: 'productName',
    },
    {
      title: t(`${t('orders_orderDetail_cart_table_price')} (${UNICODE_EURO})`),
      dataIndex: 'priceProduct',
    },
    {
      title: t('orders_orderDetail_cart_table_discount'),
      dataIndex: 'discount',
    },
    {
      title: t('orders_orderDetail_cart_table_quantity'),
      dataIndex: 'quantity',
    },
    {
      title: t(`${t('orders_orderDetail_cart_table_total')} (${UNICODE_EURO})`),
      dataIndex: 'totalPrice',
    },
  ];

  useEffect(() => {
    if (getRoleLocalStorage === RolesEnum.ADMIN) {
      setIsAdmin(true);
    }
  }, []);

  const handleSendPDFEmail = async () => {
    const blob = await pdf(<DocumentPDF data={dataOrder} dataTable={dataDetailCart} dateTimePDF={dateTimePDF} />).toBlob();
    const pdfFile = new File([blob], 'order.pdf', { type: 'application/pdf' });
    const formData = new FormData();
    formData.append('file', pdfFile);
    formData.append('orderId', String(order?.order?.id));
    formData.append('type', RolesEnum.AGENT);

    loadingContext?.show();
    dispatch(sendPDFOrderEmail(formData))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        message.success(`${t('code_message_mail_mailer_validation_success')}`);
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  return (
    <div id='clientDetailsCompleted' className={cx('clientDetailsContainer')}>
      <NewOrder orderCompleted />

      <CartTable actions={false} clearCart={false} isCompletedOrder={true} />
      <Row className={cx('clientDetailsBottom')}>
        <Col span={8}></Col>
        <Col className={cx('clientDetailsBottomText')} span={8}>
          <Flex className={cx('clientDetailsBottomWrapTitle')}>
            <Title className={cx('clientDetailsBottomTitle')} type='success' level={TITLE_LEVEL}>
              {t('clients_clientDetails_cart_orderCompleted')}
            </Title>
          </Flex>
        </Col>
        <Col className={cx('clientDetailsBottomButton')} span={8}>
          {!isAdmin && (
            <Button size='large' type='primary' onClick={handleSendPDFEmail}>
              {t('clients_clientDetails_cart_send_pdf_email')}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ClientDetailsOrderCompleted;
