import { createSlice } from '@reduxjs/toolkit';
import { detailOrder } from './orderThunk';

interface IDetailOrderState {
  loading: boolean;
  order: {};
}

const initialState: IDetailOrderState = {
  loading: false,
  order: {},
};

const detailOrderSlice = createSlice({
  name: 'detailOrder',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(detailOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(detailOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.data;
      })
      .addCase(detailOrder.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const detailOrderActions = detailOrderSlice.actions;

export const selectLoading = (state: any) => state.detailOrder.loading;
export const selectDetailOrder = (state: any) => state.detailOrder.order;

const detailOrderReducer = detailOrderSlice.reducer;
export default detailOrderReducer;
