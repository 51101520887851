// Libs
import axiosClient from './axiosClient';
// Interface
import { IParamsSearchSubCategories, IParamsSubCategories } from '~/pages/clients/Clients.interface';
// Utils
import { URL_API_SUB_CATEGORIES } from '~/utils/constants';
import { removeLastCharacter } from '~/utils/helper';

export const subCategoriesApi = {
  getSubCategoriesByCategory: (params: IParamsSubCategories) => {
    let url = `${URL_API_SUB_CATEGORIES.GET_SUB_CATEGORIES_BY_CATEGORY}/${params.categoryId}/${params.clientId}?`;

    if (params?.categoryId) {
      if (params?.page) {
        url += `page=${params.page}&`;
      }

      if (params?.searchKey) {
        url += `searchKey=${params.searchKey}&`;
      }
    }

    return axiosClient.get(removeLastCharacter(url));
  },
  searchSubCategoriesByCategory: (params: IParamsSearchSubCategories) => {
    let url = `${URL_API_SUB_CATEGORIES.SEARCH_SUB_CATEGORY}?`;

    if (params?.clientId) {
      url += `clientId=${params.clientId}&`;
    }

    if (params?.categoryId) {
      url += `categoryId=${params.categoryId}&`;
    }

    if (params?.searchKey) {
      url += `searchKey=${params.searchKey}&`;
    }

    if (params?.type) {
      url += `type=${params.type}&`;
    }

    return axiosClient.get(removeLastCharacter(url));
  },
};
