import { createAsyncThunk } from '@reduxjs/toolkit';
import { categoriesApi } from '~/apis/categories';
import { IParamsCategories, IParamsSearchCategories } from '~/pages/clients/Clients.interface';
import { GET_CATEGORIES, SEARCH_CATEGORIES } from '~/utils/actionType';

export const getCategories = createAsyncThunk(GET_CATEGORIES, async (params: IParamsCategories, { rejectWithValue }) => {
  try {
    const res = await categoriesApi.getCategories(params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCategoriesSearchResult = createAsyncThunk(SEARCH_CATEGORIES, async (params: IParamsSearchCategories, { rejectWithValue }) => {
  try {
    const res = await categoriesApi.searchCategories(params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
