// Libs
import React, { useContext, useEffect } from 'react';
import { Button, Form, Input, Typography, Layout, Flex, message } from 'antd';
import classNames from 'classnames/bind';
// Shares
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch } from '~/redux/hooks';
// i18next
import { useTranslation } from 'react-i18next';
// Interface
import { IChangeEmail, IChangePassword } from './Profile.interface';
// Styles
import styles from './Profile.module.scss';
// Redux
import { useSelector } from 'react-redux';
import { changeEmail, changePassword, getProfile } from '~/thunks/profile/profileThunk';
import { selectLoading, selectProfile } from '~/thunks/profile/profileSlice';
// Utils
import { CHARACTER_MIN_PASSWORD, TITLE_LEVEL } from '~/utils/constants';
import { getErrorMessageByCode } from '~/utils/helper';

const cx = classNames.bind(styles);

const { Title } = Typography;

const Profile: React.FC = ({ ...props }) => {
  const loading = useSelector(selectLoading);
  const profileData = useSelector(selectProfile);

  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const [changeEmailForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  useEffect(() => {
    handleGetProfile();
  }, []);

  useEffect(() => {
    changeEmailForm.setFieldsValue(loading ? { newEmail: '' } : { newEmail: profileData.email });
  }, [profileData.email]);

  const resetPasswordForm = () => {
    changePasswordForm.resetFields();
  };

  const handleChangeEmailForm = (values: IChangeEmail) => {
    loadingContext?.show();
    const payloadChangeEmail = {
      newEmail: values.newEmail,
    };
    dispatch(changeEmail(payloadChangeEmail))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        message.success(t('message_change_email_success'));
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleChangePasswordForm = (values: IChangePassword) => {
    loadingContext?.show();
    const payloadChangePassword = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    dispatch(changePassword(payloadChangePassword))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        resetPasswordForm();
        message.success(t('message_change_password_success'));
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
        resetPasswordForm();
      });
  };

  const handleGetProfile = () => {
    loadingContext?.show();
    dispatch(getProfile())
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  return (
    <Layout id='profile' className={cx('profileContainer')}>
      <div className={cx('profileChangeEmailContainer')}>
        <Form layout='vertical' className={cx('formProfile')} name='basic' form={changeEmailForm} onFinish={handleChangeEmailForm} size='large' autoComplete='off'>
          <Title level={TITLE_LEVEL}>{t('profile_email_button_change')}</Title>

          <Form.Item
            name='newEmail'
            label={t('profile_email_label')}
            rules={[
              { required: true, message: t('common_email_require') },
              {
                type: 'email',
                message: t('common_email_invalid'),
              },
            ]}
          >
            <Input placeholder={t('profile_email_placeholder')} allowClear />
          </Form.Item>

          <Flex align='center' justify='flex-start'>
            <Button loading={loading} type='primary' htmlType='submit' className={cx('btnChange')}>
              {t('profile_email_button_change')}
            </Button>
          </Flex>
        </Form>
      </div>

      <div className={cx('profileChangePasswordContainer')}>
        <Form layout='vertical' className={cx('formProfile')} name='basic' form={changePasswordForm} onFinish={handleChangePasswordForm} size='large' autoComplete='off'>
          <Title level={TITLE_LEVEL}>{t('profile_password_button_change')}</Title>

          <Form.Item name='currentPassword' label={t('profile_password_current_label')} rules={[{ required: true, message: t('common_password_require'), whitespace: true }]}>
            <Input.Password allowClear />
          </Form.Item>

          <Form.Item>
            <Form.Item
              name='newPassword'
              label={t('profile_password_label')}
              extra={t('profile_password_extra')}
              rules={[
                { required: true, message: t('common_password_require') },
                {
                  min: CHARACTER_MIN_PASSWORD,
                  message: t('profile_password_extra'),
                },
              ]}
              className={cx('profileChangePasswordNewPassword')}
            >
              <Input.Password allowClear />
            </Form.Item>

            <Form.Item
              name='confirmNewPassword'
              label={t('profile_confirm_password_label')}
              rules={[
                {
                  required: true,
                  message: t('common_confirm_password_require'),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('common_confirm_password_match'));
                  },
                }),
              ]}
              className={cx('profileChangePasswordConfirmNewPassword')}
            >
              <Input.Password allowClear />
            </Form.Item>
          </Form.Item>

          <Flex align='center' justify='flex-start'>
            <Button loading={loading} type='primary' htmlType='submit' className={cx('btnChange')}>
              {t('profile_password_button_change')}
            </Button>
          </Flex>
        </Form>
      </div>
    </Layout>
  );
};

export default Profile;
