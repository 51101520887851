export const calendarItaLy = {
  lang: {
    locale: 'it_IT',
    yearFormat: 'YYYY',
    today: 'Oggi',
    now: 'Ora',
    backToToday: 'Torna a oggi',
    ok: 'Ok',
    timeSelect: "Scegli un'ora",
    dateSelect: 'Scegli una data',
    clear: 'Chiaro',
    month: 'Mese',
    year: 'Anno',
    previousMonth: 'Lo scorso mese (PageUp)',
    nextMonth: 'Il prossimo mese (PageDown)',
    monthSelect: 'Scegli un mese',
    yearSelect: "Scelta dell'anno'",
    decadeSelect: 'Una decade',
    dayFormat: 'D',
    dateFormat: 'YYY',
    dateTimeFormat: 'D M YYYY HH:mm:ss',
    previousYear: "L'anno scorso (Control + left)",
    nextYear: "l'anno prossimo (Control + right)",
    previousDecade: 'Un decennio fa',
    nextDecade: 'Il prossimo decennio',
    previousCentury: "L'ultimo secolo",
    nextCentury: 'Next Il prossimo secolo',
    shortWeekDays: ['lu', 'ma', 'me', 'gi', 've', 'sa', 'do'],
    shortMonths: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
    placeholder: 'Selezionare la data',
    rangePlaceholder: ["Data d'inizio", 'Data di fine'] as [string, string],
    monthBeforeYear: true,
  },
  timePickerLocale: {
    placeholder: "Scegli un'ora",
  },
};
