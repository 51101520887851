// Local Storage
export enum StoragesEnum {
  ACCESS_TOKEN = 'accessToken',
  DATA_SAVE = 'dataSave',
  REFRESH_TOKEN = 'refreshToken',
  EMAIL = 'email',
  USER_NAME = 'userName',
  ROLE = 'role',
  LANGUAGE = 'languageInventory',
  PAYMENT_ID = 'paymentId',
  ORDER_NOTE = 'orderNote',
  DESTINATION_ID = 'destinationId'
}

export enum LanguagesEnum {
  EN = 'en',
  IT = 'it',
}

export const SEARCH_PLACEHOLDER = {
  DEFAULT: 'search',
  CLIENTS_PAGE: 'search_clients_page',
  AGENT: 'search by agent',
  DATE: 'search by date',
};

export enum ErrorCodeEnum {
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
}

export enum CodeResponseApiEnum {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  ERR_NETWORK = 'ERR_NETWORK',
}

export enum RolesEnum {
  ADMIN = '00001',
  AGENT = '00002',
}
