// Libs
import { Outlet, Navigate } from 'react-router-dom';
// Components
import NotFoundPage from '../notFound/NotFound';
// Shares
import { StoragesEnum } from '~/utils/enum';
import { ROUTER_PATHS } from '~/utils/constants';

interface PrivateRoutesProps {
  role?: string[];
}

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ role }) => {
  const auth = localStorage.getItem(StoragesEnum.ACCESS_TOKEN);
  const currentRole = localStorage.getItem(StoragesEnum.ROLE);

  if (!auth) {
    return <Navigate to={ROUTER_PATHS.LOGIN} />;
  }

  return currentRole && role?.includes(currentRole) ? <Outlet /> : <NotFoundPage />;
};

export default PrivateRoutes;
