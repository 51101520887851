import classNames from 'classnames/bind';
// Libs
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Input, Typography, Table, RowProps, Pagination, AutoComplete, DatePicker, Flex } from 'antd';
import { ColumnType } from 'antd/es/table';
import en_US from 'antd/es/date-picker/locale/en_US';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
// Shares
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingContext } from '~/contexts/LoadingContext';
// Scss
import styles from './Orders.module.scss';
// Thunk
import { selectLoading, selectOrders, selectOrdersPagination } from '~/thunks/order/orderSlice';
import { getOrders, searchOrders } from '~/thunks/order/orderThunk';
// Interface
import { IDataOrders, IParamsOrder } from './Orders.interface';
// Utils
import { DATE_FORMAT, LIMIT_DEFAULT, OPTIONS_ORDER_BY, ROUTER_PATHS, SCROLL_X_TABLE, SORT_COLUMN_BY, TITLE_LEVEL, UNICODE_EURO, Y_SCROLL } from '~/utils/constants';
import { StoragesEnum } from '~/utils/enum';
import { defaultSortOrder, formatDate, getTimezone } from '~/utils/helper';
import { calendarItaLy } from '~/utils/locales';

const cx = classNames.bind(styles);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { Search } = Input;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const Orders: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const timezone = getTimezone();

  const [pageSelected, setPageSelected] = useState<number>(Number(params?.page));
  const [limitSelected, setLimitSelected] = useState<number>(Number(params?.limit));
  const [dateFromSelected, setDateFromSelected] = useState<string>(params?.dateFrom);
  const [dateToSelected, setDateToSelected] = useState<string>(params?.dateTo);
  const [searchResults, setSearchResults] = useState<IParamsOrder[]>([]);
  const [paramObject, setParamObject] = useState<IParamsOrder>({
    sort: params?.sort,
    order: params?.order,
    page: pageSelected,
    limit: limitSelected,
    dateFrom: params?.dateFrom,
    dateTo: params?.dateTo,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);

  const orders = useAppSelector(selectOrders);
  const ordersPagination = useAppSelector(selectOrdersPagination);
  const loading = useAppSelector(selectLoading);

  useEffect(() => {
    setSearchParams({ ...params });
    handleGetListOrder(paramObject);
  }, [params?.page, params?.limit]);

  const row = (props: RowProps) => {
    const style: React.CSSProperties = {
      ...props.style,
      cursor: 'pointer',
    };

    return <tr {...props} style={style} />;
  };

  const columns: ColumnType<IDataOrders>[] = [
    {
      title: t('orders_table_columns_title_number'),
      dataIndex: 'id',
      sorter: orders?.length > 0,
      width: 140,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.ORDER.NUMBER.param),
      render: (_, record) => record?.order?.id,
    },
    {
      title: t('orders_table_columns_title_date'),
      dataIndex: 'createdAt',
      sorter: orders?.length > 0,
      width: 200,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.ORDER.DATE.param),
      render: (_, record) => formatDate(record?.order?.createAt),
    },
    {
      title: t('orders_table_columns_title_clientName'),
      dataIndex: 'clientName',
      sorter: orders?.length > 0,
      width: 200,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.ORDER.CLIENT_NAME.param),
      render: (_, record) => record?.client?.name,
    },
    {
      title: t('orders_table_columns_title_agent'),
      dataIndex: 'agentSurname',
      sorter: orders?.length > 0,
      width: 200,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.ORDER.AGENT.param),
      render: (_, record) => record?.order?.agentSurname,
    },
    {
      title: `${t('orders_table_columns_title_total')} (${UNICODE_EURO})`,
      dataIndex: 'total',
      sorter: orders?.length > 0,
      width: 140,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.ORDER.TOTAL.param),
      render: (_, record) => record?.order?.total,
    },
  ];

  const handleGetListOrder = (params: IParamsOrder) => {
    loadingContext?.show();
    dispatch(getOrders(params))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleCallSearchOrder = (searchParams: IParamsOrder) => {
    dispatch(searchOrders(searchParams))
      .unwrap()
      .then((res) => {
        const convertSearchResults = res?.data?.responses?.map((order: IDataOrders) => {
          return { value: order?.order?.id, label: `${order?.order?.code || ''} ${order?.client?.name}` };
        });
        setSearchResults(convertSearchResults);
      })
      .catch((error) => {
        console.log(`[Orders] handleCallSearchOrder: ${JSON.stringify(error, null, 2)}`);
      });
  };

  const handleCommonChange = (newParamObject: IParamsOrder, currentPage?: number, currentLimit?: number) => {
    setParamObject(newParamObject);
    handleGetListOrder(newParamObject);

    if (currentPage) {
      setPageSelected(currentPage);
    }

    if (currentLimit) {
      setLimitSelected(currentLimit);
    }
  };

  // sort table
  const handleSort = (sorter: any, order: string) => {
    let newParamObject = {};
    let columnParam = '';
    if (order) {
      switch (sorter?.field) {
        case SORT_COLUMN_BY.ORDER.NUMBER.key:
          columnParam = SORT_COLUMN_BY.ORDER.NUMBER.param;
          break;
        case SORT_COLUMN_BY.ORDER.DATE.key:
          columnParam = SORT_COLUMN_BY.ORDER.DATE.param;
          break;
        case SORT_COLUMN_BY.ORDER.CLIENT_NAME.key:
          columnParam = SORT_COLUMN_BY.ORDER.CLIENT_NAME.param;
          break;
        case SORT_COLUMN_BY.ORDER.AGENT.key:
          columnParam = SORT_COLUMN_BY.ORDER.AGENT.param;
          break;
        case SORT_COLUMN_BY.ORDER.TOTAL.key:
          columnParam = SORT_COLUMN_BY.ORDER.TOTAL.param;
          break;
        default:
          break;
      }
      newParamObject = { ...paramObject, sort: columnParam, order: order };
      setSearchParams({ ...params, sort: columnParam, order: order });
    } else {
      searchParams.delete('sort');
      searchParams.delete('order');
      setSearchParams(searchParams);
      newParamObject = { page: pageSelected, limit: limitSelected, dateFrom: dateFromSelected, dateTo: dateToSelected, timezone };
    }

    handleCommonChange(newParamObject);
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any, extra: any) => {
    let orderBy = '';

    switch (sorter?.order) {
      case OPTIONS_ORDER_BY.DESC.KEY:
        orderBy = OPTIONS_ORDER_BY.DESC.LABEL;
        break;
      case OPTIONS_ORDER_BY.ASC.KEY:
        orderBy = OPTIONS_ORDER_BY.ASC.LABEL;
        break;

      default:
        orderBy = '';
        break;
    }

    handleSort(sorter, orderBy);
  };

  const handleSearchOrder = (value: string) => {
    let newParamObject = {};
    if (value === '') {
      setSearchResults([]);
      return;
    }

    const searchParams = {
      searchKey: value,
    };

    if (ordersPagination?.totalItems > 0) {
      newParamObject = { page: pageSelected, limit: limitSelected, searchKey: searchParams.searchKey, dateFrom: dateFromSelected, dateTo: dateToSelected, timezone };
      handleCallSearchOrder(newParamObject);
    }
  };

  const handleSelectOrder = (value: string) => {
    navigate(`${ROUTER_PATHS.ORDER_DETAIL}/${value}`);
  };

  const handleNavigateDetailOrder = (id: string | number) => {
    navigate(`${ROUTER_PATHS.ORDER_DETAIL}/${id}`);
  };

  const handleChangePagination = (page: number, pageSize: number) => {
    const newParamObject = { ...paramObject, page, limit: pageSize };
    setSearchParams({ ...params, page: page.toString(), limit: pageSize.toString() });
    handleCommonChange(newParamObject, page, pageSize);
  };

  const handleSortByDateRange = (_: any, dateRangePicker: any) => {
    setSearchParams({ ...params, dateFrom: dateRangePicker[0], dateTo: dateRangePicker[1] });
    setDateFromSelected(dateRangePicker[0]);
    setDateToSelected(dateRangePicker[1]);

    const newParamObject = { ...paramObject, dateFrom: dateRangePicker[0], dateTo: dateRangePicker[1], timezone };
    handleCommonChange(newParamObject);
  };

  const locale = localStorage.getItem(StoragesEnum.LANGUAGE) === 'it' ? calendarItaLy : en_US;

  return (
    <div id='orders' className={cx('ordersContainer')}>
      <Flex align='center' justify='space-between' className={cx('titleTableOrder')}>
        <Title level={TITLE_LEVEL} className={cx('ordersTitle')}>
          {t('orders_list_title')}
        </Title>

        <Flex align='center' justify='flex-end' gap={8} className={cx('orderActions')}>
          <RangePicker locale={locale} format={DATE_FORMAT} onChange={handleSortByDateRange} className={cx('orderRangePicker')} />
          <AutoComplete className={cx('autoCompleteOrdersSearch')} onSearch={debounce(handleSearchOrder, 800)} onSelect={handleSelectOrder} options={searchResults}>
            <Search placeholder={t('orders_list_search_placeholder')} className={cx('ordersSearch')} allowClear enterButton />
          </AutoComplete>
        </Flex>
      </Flex>
      {!loading && (
        <>
          <Table
            className={cx('clientsTableContent')}
            pagination={false}
            rowKey={(record) => record?.order?.id}
            onChange={handleChangeTable}
            columns={columns}
            dataSource={orders}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleNavigateDetailOrder(record?.order?.id);
                },
              };
            }}
            components={{
              body: {
                row: row,
              },
            }}
            scroll={{ x: SCROLL_X_TABLE, y: Y_SCROLL }}
            locale={{
              triggerDesc: t('table_asc_order_sort_tooltip'),
              triggerAsc: t('table_desc_order_sort_tooltip'),
              cancelSort: t('table_cancel_order_sort_tooltip'),
              emptyText: <EmptyTable />,
            }}
          />

          {ordersPagination?.totalItems > 0 && (
            <div className={cx('tablePaginationContainer')}>
              <Pagination
                onChange={handleChangePagination}
                total={ordersPagination?.totalItems}
                showSizeChanger
                responsive
                pageSize={Number(ordersPagination?.limit) || LIMIT_DEFAULT}
                current={Number(ordersPagination?.page)}
                locale={{ items_per_page: t('table_pagination_text_page') }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;
