import { createSlice } from '@reduxjs/toolkit';
import { updateAgent } from './agentThunk';

interface IAgentState {
  loading: boolean;
}

const initialState: IAgentState = {
  loading: false,
};

const updateAgentSlice = createSlice({
  name: 'agent/updateAgent',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateAgent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const updateAgentActions = updateAgentSlice.actions;

export const selectUpdateAgent = (state: any) => state.updateAgent.agents;

const agentReducer = updateAgentSlice.reducer;
export default agentReducer;
