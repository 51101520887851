import { createSlice } from '@reduxjs/toolkit';
import { getSubCategoriesByCategory } from './subCategoriesThunk';

interface ISubCategoriesState {
  subCategories: [];
  loading: boolean;
}

const initialState: ISubCategoriesState = {
  subCategories: [],
  loading: false,
};

const subCategoriesSlice = createSlice({
  initialState,
  name: 'subCategories',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSubCategoriesByCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSubCategoriesByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.subCategories = action.payload;
      })
      .addCase(getSubCategoriesByCategory.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectLoadingSubCategory = (state: any) => state.subCategories.loading;

export const subCategoriesActions = subCategoriesSlice.actions;

const subCategoriesReducer = subCategoriesSlice.reducer;
export default subCategoriesReducer;
