import { createSlice } from '@reduxjs/toolkit';
import { addClientDetailAdminNote } from './clientDetailThunk';

interface IClientDetailState {
  clientDetailAdminNote: {};
  loading: boolean;
}

const initialState: IClientDetailState = {
  clientDetailAdminNote: {},
  loading: false,
};

const clientDetailAdminNoteSlice = createSlice({
  initialState,
  name: 'clientDetailAdminNote',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addClientDetailAdminNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addClientDetailAdminNote.fulfilled, (state, action) => {
        state.loading = false;
        state.clientDetailAdminNote = action.payload?.data;
      })
      .addCase(addClientDetailAdminNote.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const clientDetailAdminNoteActions = clientDetailAdminNoteSlice.actions;

export const selectClientDetailAdminNote = (state: any) => state.clientDetailAdminNote;

const clientDetailAdminNoteReducer = clientDetailAdminNoteSlice.reducer;
export default clientDetailAdminNoteReducer;
