import { URL_API_AUTH } from '~/utils/constants';
import axiosClient from './axiosClient';
import { IPayloadRefreshToken } from '~/data/account';

const authApi = {
  login(payload: any) {
    const url = `${URL_API_AUTH.LOGIN}`;
    return axiosClient.post(url, payload);
  },
  getRefreshToken(params: IPayloadRefreshToken) {
    const url = `${URL_API_AUTH.REFRESH_TOKEN}`;
    return axiosClient.post(url, params);
  },
};

export default authApi;
