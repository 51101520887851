import React, { ReactNode, createContext, useState } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames/bind';
import styles from './LoadingContext.module.scss';

const cx = classNames.bind(styles);

interface ILoadingContext {
  show: () => void;
  hide: () => void;
}

const LoadingContext = createContext<ILoadingContext | null>(null);

type LoadingContextProps = {
  children: ReactNode;
};

const LoadingProvider = (props: LoadingContextProps) => {
  const [isLoadingGlobal, setIsLoadingGlobal] = useState<boolean>(false);

  const contextValue = {
    show: () => setIsLoadingGlobal(true),
    hide: () => setIsLoadingGlobal(false),
  };

  return (
    <LoadingContext.Provider value={contextValue}>
      {props.children}
      {isLoadingGlobal && (
        <div className={cx('loadingContainer')}>
          <div className={cx('loading_wrapper_global')}>
            <Spin size='large' />
          </div>
        </div>
      )}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
