import { createSlice } from '@reduxjs/toolkit';
import { getOrders } from './orderThunk';

interface IOrderState {
  loading: boolean;
  orders: [];
  pagination: {};
}

const initialState: IOrderState = {
  loading: false,
  orders: [],
  pagination: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload?.data.responses;
        state.pagination = action.payload?.data?.pagination;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const orderActions = orderSlice.actions;

export const selectOrders = (state: any) => state.order.orders;
export const selectOrdersPagination = (state: any) => state.order.pagination;
export const selectLoading = (state: any) => state.order.loading;

const orderReducer = orderSlice.reducer;
export default orderReducer;
