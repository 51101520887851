import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import agentReducer from '~/thunks/agent/agentSlice';
import listAgentReducer from '~/thunks/agent/listAgentSlice';
import deleteAgentReducer from '~/thunks/agent/deleteAgentSlice';
import detailAgentReducer from '~/thunks/agent/detailAgentSlice';
import updateAgentReducer from '~/thunks/agent/updateAgentSlice';
import authReducer from '~/thunks/auth/authSlice';
import categoriesReducer from '~/thunks/categories/categoriesSlice';
import clientDetailReducer from '~/thunks/clientDetail/clientDetailSlice';
import clientsReducer from '~/thunks/clients/clientsSlice';
import profileReducer from '~/thunks/profile/profileSlice';
import subCategoriesReducer from '~/thunks/subCategories/subCategoriesSlice';
import updateDatabaseReducer from '~/thunks/updateDatabase/updateDatabaseSlice';
import listProductReducer from '~/thunks/product/listProductSlice';
import paymentsReducer from '~/thunks/payments/paymentsSlice';
import createAndUpdateCartReducer from '~/thunks/cart/createAndUpdateCartSlice';
import orderReducer from '~/thunks/order/orderSlice';
import deleteOrderReducer from '~/thunks/order/deleteOrderSlice';
import getProductsInCartReducer from '~/thunks/cart/getProductsInCartSlice';
import updateProductInCartReducer from '~/thunks/cart/updateProductInCartSlice';
import createOrderReducer from '~/thunks/order/createOrderSlice';
import detailOrderReducer from '~/thunks/order/detailOrderSlice';
import sendPDFOrderEmailReducer from '~/thunks/order/sendPDFEmailSlice';
import searchProductsReducer from '~/thunks/product/searchProductSlice';
import clientDetailAdminNoteReducer from '~/thunks/clientDetail/clientDetailAdminNoteSlice';
const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  agent: agentReducer,
  listAgent: listAgentReducer,
  deleteAgent: deleteAgentReducer,
  detailAgent: detailAgentReducer,
  updateAgent: updateAgentReducer,
  categories: categoriesReducer,
  subCategories: subCategoriesReducer,
  clients: clientsReducer,
  updateDatabase: updateDatabaseReducer,
  clientDetail: clientDetailReducer,
  listProduct: listProductReducer,
  payments: paymentsReducer,
  createAndUpdateCart: createAndUpdateCartReducer,
  getProductsInCart: getProductsInCartReducer,
  updateProductInCart: updateProductInCartReducer,
  order: orderReducer,
  deleteOrderReducer: deleteOrderReducer,
  createOrder: createOrderReducer,
  detailOrder: detailOrderReducer,
  sendPDFOrderEmail: sendPDFOrderEmailReducer,
  searchProduct: searchProductsReducer,
  clientDetailAdminNote: clientDetailAdminNoteReducer,
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
