import { createSlice } from '@reduxjs/toolkit';
import { getClientDetail } from './clientDetailThunk';

interface IClientDetailState {
  clientDetail: {};
  loading: boolean;
}

const initialState: IClientDetailState = {
  clientDetail: {},
  loading: false,
};

const clientDetailSlice = createSlice({
  initialState,
  name: 'clientDetail',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClientDetail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClientDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.clientDetail = action.payload?.data;
      })
      .addCase(getClientDetail.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const clientDetailActions = clientDetailSlice.actions;

export const selectClientDetail = (state: any) => state.clientDetail.clientDetail;

const clientDetailReducer = clientDetailSlice.reducer;
export default clientDetailReducer;
