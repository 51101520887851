import { createSlice } from '@reduxjs/toolkit';
import { createOrder } from './orderThunk';

interface ICreateOrderState {
  loading: boolean;
  order: {};
}

const initialState: ICreateOrderState = {
  loading: false,
  order: {},
};

const createOrderSlice = createSlice({
  name: 'order/create',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.data.detailOrder;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectDetailCreateOrder = (state: any) => state.createOrder.order;
export const createOrderActions = createOrderSlice.actions;

const createOrderReducer = createOrderSlice.reducer;
export default createOrderReducer;
