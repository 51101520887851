import { createAsyncThunk } from '@reduxjs/toolkit';
import { productsApi } from '~/apis/product';
import { IParamGetProductsBySubCategoryId, IParamSearchProducts } from '~/data/product';
import { GET_PRODUCTS_BY_SUB_CATEGORY, SEARCH_PRODUCTS_BY_NAME } from '~/utils/actionType';

export const getProductsBySubCategory = createAsyncThunk(GET_PRODUCTS_BY_SUB_CATEGORY, async (payload: IParamGetProductsBySubCategoryId, { rejectWithValue }) => {
  try {
    const res = await productsApi.getProductsBySubCategory(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchProductsByName = createAsyncThunk(SEARCH_PRODUCTS_BY_NAME, async (payload: IParamSearchProducts, { rejectWithValue }) => {
  try {
    const res = await productsApi.searchProduct(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
