import classNames from 'classnames/bind';
// Libs
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
import { Button, Form, Input, Layout, message } from 'antd';
// i18next
import { useTranslation } from 'react-i18next';
// Thunks
import { handleLogin } from '~/thunks/auth/authThunk';
import { authActions, authLoading } from '~/thunks/auth/authSlice';
// Interface
import { ILoginPayload } from './Login.interface';
// Styles
import styles from './Login.module.scss';
// Utils
import { EXACT_PATH, CHARACTER_MIN_PASSWORD } from '~/utils/constants';
import { imgLogo } from '~/utils/image.constants';
import { ErrorCodeEnum, StoragesEnum } from '~/utils/enum';
import { handleResponseError } from '~/utils/helper';
// Components
import { LoadingContext } from '~/contexts/LoadingContext';

const cx = classNames.bind(styles);

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useSelector(authLoading);
  const loadingContext = useContext(LoadingContext);

  const accessToken = localStorage.getItem(StoragesEnum.ACCESS_TOKEN)!;
  const currentRole = localStorage.getItem(StoragesEnum.ROLE);

  const { t } = useTranslation();

  useEffect(() => {
    if (accessToken && currentRole) {
      navigate(EXACT_PATH.MAIN);
    } else {
      dispatch(authActions.handleLogout());
    }
  }, [navigate]);

  const handleLoginForm = (values: ILoginPayload) => {
    loadingContext?.show();

    const loginPayload = {
      username: values.username,
      password: values.password,
    };

    dispatch(handleLogin(loginPayload))
      .unwrap()
      .then((resp) => {
        loadingContext?.hide();
        const { token } = resp?.data;
        if (token?.access) {
          message.success(t(`login_message_success`));
          navigate(EXACT_PATH.MAIN);
        }
      })
      .catch((err: any) => {
        if (err?.code === ErrorCodeEnum.ERR_BAD_REQUEST) {
          handleResponseError(err, t('error_login_message'));
        } else {
          handleResponseError(err, t('error_other_message'));
        }
        loadingContext?.hide();
        console.log(`err: => ${JSON.stringify(err, null, 2)}`);
        dispatch(authActions.handleLogout());
      });
  };

  return (
    <>
      {!accessToken && (
        <Layout id='login' className={cx('mainLayout')}>
          <div className={cx('formContainer')}>
            <div className={cx('layoutLoginLeft')}>
              <img src={imgLogo} className={cx('loginLogoImg')} alt='Login Banner' /> <span className={cx('loginLogoText')}>{t('text_logo')}</span>
            </div>
            <div className={cx('loginForm')}>
              <Form name='formLogin' onFinish={handleLoginForm} layout='vertical' size='large' autoComplete='off'>
                <Form.Item
                  name='username'
                  rules={[
                    { required: true, message: t('common_email_require') },
                    {
                      type: 'email',
                      message: t('common_email_invalid'),
                    },
                  ]}
                >
                  <Input placeholder={t('login_email_placeholder')} />
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[
                    { required: true, message: t('login_require_password_message') },
                    {
                      min: CHARACTER_MIN_PASSWORD,
                      message: t('login_length_password_message'),
                    },
                  ]}
                >
                  <Input.Password placeholder={t('login_password_placeholder')} />
                </Form.Item>
                <Form.Item>
                  <Button loading={loading} type='primary' htmlType='submit' className={cx('btnLogin')}>
                    {t('login_button')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Login;
