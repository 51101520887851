// Libs
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
// Ant design
import { Button, Col, Row, Space, Input, Typography, Flex, AutoComplete, Tooltip } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
// i18next
import { useTranslation } from 'react-i18next';
// Interface
import { IParamsSubCategories, ISubCategory } from '../../Clients.interface';
// Styles
import styles from './ClientDetailsSubCategories.module.scss';
// Components
import CartTable from '~/components/specific/cartTable/CartTable';
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';
import { LoadingContext } from '~/contexts/LoadingContext';
// Thunk
import { getSubCategoriesByCategory, getSubCategoriesSearchResult } from '~/thunks/subCategories/subCategoriesThunk';
import { selectLoadingSubCategory } from '~/thunks/subCategories/subCategoriesSlice';
import { searchProductsByName } from '~/thunks/product/productThunk';
// Hooks
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Utils
import { PAGE_DEFAULT, ROUTER_PATHS, SEARCH_RESULT_TYPE, TITLE_LEVEL, TYPE_SEARCH_CATEGORIES, TYPE_SEARCH_PRODUCT, TYPE_SEARCH_SUB_CATEGORIES } from '~/utils/constants';
import { getErrorMessageByCode } from '~/utils/helper';

const cx = classNames.bind(styles);

const { Search } = Input;
const { Title, Text } = Typography;

interface OptionType {
  id: string;
  type: string;
}

const ClientDetailsSubCategories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { categoryId, clientId } = useParams();

  const [subCategoriesData, setSubCategoriesData] = useState<ISubCategory[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchResult, setSearchResult] = useState<ISubCategory[]>([]);
  const [pageSelected, setPageSelected] = useState<number>(Number(params?.page) || PAGE_DEFAULT);
  const [searchKey, setSearchKey] = useState<string>(params?.searchKey || '');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);

  const loadingSubCategory = useAppSelector(selectLoadingSubCategory);

  const goBack = () => {
    navigate(-1);
  };

  const handleSearchSubCategory = (value: string) => {
    setSearchKey(value);

    if (value === '') {
      setSearchResult([]);
      return;
    }

    if (categoryId) {
      const params = {
        clientId: clientId!,
        categoryId: categoryId,
        searchKey: value,
        type: SEARCH_RESULT_TYPE.SUB_CATEGORY,
      };

      dispatch(getSubCategoriesSearchResult(params))
        .unwrap()
        .then((res) => {
          const convertSearchResult = res?.data?.map((subCategory: ISubCategory, index: number) => {
            return {
              ...subCategory,
              value: index,
              label: `${(subCategory?.type === SEARCH_RESULT_TYPE.PRODUCT && subCategory?.code) || ''} ${subCategory?.name}`,
              type: subCategory?.type || TYPE_SEARCH_SUB_CATEGORIES,
            };
          });
          setSearchResult(convertSearchResult);
        })
        .catch((error) => {
          console.log(`[Sub Categories] handleSearchSubCategory: ${JSON.stringify(error, null, 2)}`);
        });
    }
  };

  const handleSearchProduct = (value: string) => {
    setSearchKey(value);

    const payload = {
      clientId: Number(clientId),
      searchKey: value,
      type: TYPE_SEARCH_SUB_CATEGORIES,
    };
    dispatch(searchProductsByName(payload))
      .unwrap()
      .then((res) => {
        const subCategoryId = res?.data?.subCategoryId;
        navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.PRODUCTS}/${subCategoryId}`);
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  const debounceSearchSubCategories = debounce(handleSearchSubCategory, 400);

  const handleBackToCategories = () => {
    navigate(-1);
  };

  const handleLoadMoreSubCategories = () => {
    setPageSelected(pageSelected + 1);
  };

  const handleClickSubCategory = (subCategoryId: string | number) => {
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.PRODUCTS}/${subCategoryId}`);
  };

  const handleSelectedSearchResult = (id: string, option: OptionType) => {
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.PRODUCTS}/${option?.id}`);
  };

  useEffect(() => {
    if (categoryId) {
      loadingContext?.show();

      const payload: IParamsSubCategories = {
        clientId: clientId,
        categoryId: categoryId,
        page: pageSelected,
      };

      dispatch(getSubCategoriesByCategory(payload))
        .unwrap()
        .then((res) => {
          loadingContext?.hide();
          const newSubCategories = res?.data?.responses;
          setSubCategoriesData([...subCategoriesData, ...newSubCategories]);
          setTotalPage(res?.data?.pagination?.totalPages);
        })
        .catch((error) => {
          loadingContext?.hide();
        });
    }
  }, [pageSelected]);

  return (
    <>
      <div className={`${cx('clientDetailsSubCategories')}`}>
        <Row align={'middle'} justify={'space-between'} gutter={{ xs: 8, sm: 16, md: 24 }} className={cx('clientDetailsSubCategoriesLayout')}>
          <Col className={cx('clientsTableHeaderTitle')}>
            <Title level={TITLE_LEVEL}>{t('clients_clientDetails_sub_categories_title')}</Title>
          </Col>
          <Col>
            <AutoComplete
              className={cx('autoCompleteClientDetailsSubCategoriesSearch')}
              onSearch={debounceSearchSubCategories}
              options={searchResult}
              onSelect={handleSelectedSearchResult}
            >
              <Search
                size='middle'
                // onSearch={handleSearchProduct}
                className={cx('clientDetailsSubCategoriesSearch')}
                enterButton
                placeholder={t('clients_clientDetails_sub_categories_search_placeholder')}
                allowClear
              />
            </AutoComplete>
          </Col>
        </Row>

        {!loadingSubCategory &&
          (subCategoriesData?.length > 0 ? (
            <Flex vertical>
              <Row gutter={[8, 8]}>
                {subCategoriesData.map((subCategory) => (
                  <Col xs={24} sm={8} md={6} lg={4} xxl={3} key={subCategory.id}>
                    <Tooltip overlay={subCategory.name}>
                      <Flex className={cx('clientDetailsSubCategoriesItem')} onClick={() => handleClickSubCategory(subCategory.id)}>
                        <Flex gap={8} align='center' justify='center'>
                          <TagsOutlined className={cx('clientDetailsSubCategoriesItemIcon')} />
                          <Text className={cx('clientDetailsSubCategoriesItemLabel')}>{subCategory.code + ' ' + subCategory.name}</Text>
                        </Flex>
                      </Flex>
                    </Tooltip>
                  </Col>
                ))}
              </Row>
            </Flex>
          ) : (
            <EmptyTable />
          ))}

        <Flex align='center' justify='space-between'>
          <Button type='primary' className={cx('clientDetailsSubCategoriesBackBtn')} onClick={handleBackToCategories} size='large'>
            {t('common_button_back')}
          </Button>
          {pageSelected < totalPage && (
            <Button onClick={handleLoadMoreSubCategories} size='large' type='primary'>
              {t('common_button_loadingMore')}
            </Button>
          )}
          <Button type='primary' className={cx('clientDetailsSubCategoriesHiddenBtn')} size='large'>
            {t('common_button_back')}
          </Button>
        </Flex>
      </div>

      <CartTable handleGoBack={goBack} />
    </>
  );
};

export default ClientDetailsSubCategories;
