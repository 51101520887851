import { createAsyncThunk } from '@reduxjs/toolkit';
import { changePasswordApi } from '~/apis/changePassword';
import { changeEmailApi, profileApi } from '~/apis/profile';
import { IChangeEmail, IChangePassword } from '~/pages/profile/Profile.interface';
import { CHANGE_EMAIL, CHANGE_PASSWORD, GET_PROFILE } from '~/utils/actionType';

export const getProfile = createAsyncThunk(GET_PROFILE, async (_, { rejectWithValue }) => {
  try {
    const res = await profileApi.getProfile();
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changePassword = createAsyncThunk(CHANGE_PASSWORD, async (payload: IChangePassword, { rejectWithValue }) => {
  try {
    const res = await changePasswordApi.changePassword(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeEmail = createAsyncThunk(CHANGE_EMAIL, async (payload: IChangeEmail, { rejectWithValue }) => {
  try {
    const res = await changeEmailApi.changeEmail(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
