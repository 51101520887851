import { URL_API_CATEGORIES } from '~/utils/constants';
import axiosClient from './axiosClient';

interface IParamsCategories {
  clientId: string;
  page?: number;
  searchKey?: string;
}

interface IParamsSearchCategories {
  clientId: string;
  searchKey?: string;
  type?: string;
}

export const categoriesApi = {
  getCategories: (params: IParamsCategories) => {
    let url = `${URL_API_CATEGORIES.GET_CATEGORIES}/${params.clientId}?`;

    if (params?.page) {
      url += `page=${params.page}&`;
    }

    if (params?.searchKey) {
      url += `searchKey=${params.searchKey}&`;
    }

    return axiosClient.get(url);
  },
  searchCategories: (params: IParamsSearchCategories) => {
    let url = `${URL_API_CATEGORIES.SEARCH_CATEGORY}?`;

    if (params?.clientId) {
      url += `clientId=${params.clientId}&`;
    }

    if (params?.searchKey) {
      url += `searchKey=${params.searchKey}&`;
    }

    if (params?.type) {
      url += `type=${params.type}&`;
    }

    return axiosClient.get(url);
  },
};
