import React from 'react';
import { Empty } from 'antd';
import classNames from 'classnames/bind';
import styles from './EmptyTable.module.scss';
// i18next
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

const customDescription = (desc: string) => <span className={cx('emptyTableDesc')}>{desc}</span>;

interface IEmptyTable {
  emptyList?: boolean;
}

const EmptyTable: React.FC<IEmptyTable> = ({ emptyList = false, ...props }) => {
  const { t } = useTranslation();

  return (
    <div id='emptyTable' className={cx('emptyTableContainer')}>
      {emptyList ? (
        <Empty description={customDescription(t('no_data_table'))} />
      ) : (
        <Empty description={customDescription(t('no_data_table'))} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export default EmptyTable;
