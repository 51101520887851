import { useEffect } from 'react';
import { message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { t } from 'i18next';
import dayjs from 'dayjs';
import i18n from './i18n';
import { ADMIN_PROTECTED_PATH, DATA_CODE_MESSAGE, TYPE_ORDER, TYPE_ORDER_ASC, TYPE_ORDER_DESC, UNICODE_EURO } from './constants';
import { CodeResponseApiEnum } from './enum';

export const getSideNav = (list: string[]) => {
  if (list.length <= 1) {
    return ADMIN_PROTECTED_PATH.CLIENTS;
  } else {
    if (!list[1]) {
      return ADMIN_PROTECTED_PATH.CLIENTS;
    } else {
      const foundKey = Object.keys(ADMIN_PROTECTED_PATH).find((key) => ADMIN_PROTECTED_PATH[key as keyof typeof ADMIN_PROTECTED_PATH]?.key === list[list.length - 1]);

      return ADMIN_PROTECTED_PATH[foundKey as keyof typeof ADMIN_PROTECTED_PATH];
    }
  }
};

export const formatPrice = (price: number) => {
  if (!price) return 0;
  return Number(price).toFixed(2) + ' ' + UNICODE_EURO;
};

export const handleResponseError = (err: any, msg: string) => {
  if (err?.code === CodeResponseApiEnum.ERR_NETWORK) {
    message.error(t('error_network_message'));
    return;
  }

  message.error(msg);
};

export const removeLastCharacter = (string: string) => {
  return string.slice(0, -1);
};

export const defaultSortOrder = (order?: string, paramSort?: string, sort?: string) => {
  if (paramSort !== sort) {
    return;
  }

  if (order === TYPE_ORDER.ASCEND.sortOrderQuery) {
    return TYPE_ORDER_ASC;
  }

  if (order === TYPE_ORDER.DESCEND.sortOrderQuery) {
    return TYPE_ORDER_DESC;
  }

  return;
};

// translate error message form antd when change select language
export const useLanguageValidation = (form: FormInstance<any>) => {
  useEffect(() => {
    const errorFields = form.getFieldsError().reduce<string[]>((arr, field) => {
      if (field.errors.length) {
        arr.push(field.name[0] as string);
      }
      return arr;
    }, []);

    form.validateFields(errorFields);
  }, [i18n.language, form]);
};

export const formatCurrency = (price: number) => {
  return Math.round(price * 100) / 100;
};

export const formatDate = (dateTime: string) => {
  const formattedDate = dayjs(dateTime).format('DD-MM-YYYY');
  return formattedDate;
};

export const getTimezone = () => {
  var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

export const getErrorMessageByCode = (code: number) => {
  return message.error(t(DATA_CODE_MESSAGE[code]));
};

export const splitDateTime = (dateTimeString: string) => {
  const datetime = dayjs(dateTimeString);
  const dateString = datetime.format('DD/MM/YYYY');
  const timeString = datetime.format('hh:mm A');
  return { dateString, timeString };
};

export const isDivisible = (quantityInput: number, minimumQuantity: number) => {
  return quantityInput % minimumQuantity === 0;
};

export const isObjectEmpty = (obj: {}) => {
  return Object.keys(obj).length === 0;
};

export const isValidNumberInRange = (input: string) => {
  return /^\d+$/.test(input) && parseInt(input, 10) >= 0 && parseInt(input, 10) <= 100;
};
