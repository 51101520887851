// Libs
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
// Ant design
import { Col, Row, Table, Input, Pagination, Typography, AutoComplete, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
// i18next
import { useTranslation } from 'react-i18next';
// Styles
import styles from './Clients.module.scss';
// Interface
import { IClientsParams, IDataClients } from './Clients.interface';
// Utils
import { defaultSortOrder } from '~/utils/helper';
// Constants
import { LIMIT_DEFAULT, OPTIONS_ORDER_BY, ROUTER_PATHS, Y_SCROLL, SORT_COLUMN_BY, TITLE_LEVEL } from '~/utils/constants';
// Hooks
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Thunks
import { getClients, searchClients } from '~/thunks/clients/clientsThunk';
// Slice
import { selectClients, selectLoading, selectPagination } from '~/thunks/clients/clientsSlice';
// Components
import { LoadingContext } from '~/contexts/LoadingContext';
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';

const cx = classNames.bind(styles);

const { Search } = Input;
const { Title } = Typography;

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Clients: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const [searchResults, setSearchResults] = useState<IDataClients[]>([]);
  const [pageSelected, setPageSelected] = useState<number>(Number(params?.page));
  const [limitSelected, setLimitSelected] = useState<number>(Number(params?.limit));
  const [paramObject, setParamObject] = useState<IClientsParams>({
    searchKey: params?.searchKey,
    sort: params?.sort,
    order: params?.order,
    page: pageSelected,
    limit: limitSelected,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);

  const clients = useAppSelector(selectClients);
  const clientsPagination = useAppSelector(selectPagination);
  const loadingGetList = useAppSelector(selectLoading);

  useEffect(() => {
    setSearchParams({ ...params });
    handleGetAllClient(paramObject);
  }, [params?.page, params?.limit]);

  const row = (props: RowProps) => {
    const style: React.CSSProperties = {
      ...props.style,
      cursor: 'pointer',
    };

    return <tr {...props} style={style} />;
  };

  const columns: ColumnsType<IDataClients> = [
    {
      title: t('clients_list_table_code'),
      dataIndex: 'code',
      width: 250,
      sorter: clients?.length > 0,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.CLIENT.CODE.param),
      render: (data) => (data ? data : '-'),
    },
    {
      title: t('clients_list_table_clientName'),
      dataIndex: 'name',
      sorter: clients?.length > 0,
      width: 400,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.CLIENT.NAME.param),
      render: (data) => (data ? data : `-`),
    },
    {
      title: t('clients_list_table_city'),
      dataIndex: 'city',
      sorter: clients?.length > 0,
      width: 300,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.CLIENT.CITY.param),
      render: (data) => (data ? data : `-`),
    },
  ];

  const handleGetAllClient = (params: IClientsParams) => {
    loadingContext?.show();
    dispatch(getClients(params))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleCommonChange = (newParamObject: IClientsParams, currentPage?: number, currentLimit?: number) => {
    setParamObject(newParamObject);
    handleGetAllClient(newParamObject);

    if (currentPage) {
      setPageSelected(currentPage);
    }

    if (currentLimit) {
      setLimitSelected(currentLimit);
    }
  };

  // sort table
  const handleSort = (sorter: any, order: string) => {
    let newParamObject = {};
    let columnParam = '';
    if (order) {
      switch (sorter?.field) {
        case SORT_COLUMN_BY.CLIENT.CODE.key:
          columnParam = SORT_COLUMN_BY.CLIENT.CODE.param;
          break;
        case SORT_COLUMN_BY.CLIENT.NAME.key:
          columnParam = SORT_COLUMN_BY.CLIENT.NAME.param;
          break;
        case SORT_COLUMN_BY.CLIENT.CITY.key:
          columnParam = SORT_COLUMN_BY.CLIENT.CITY.param;
          break;
        default:
          break;
      }
      newParamObject = { ...paramObject, sort: columnParam, order: order };
      setSearchParams({ ...params, sort: columnParam, order: order });
    } else {
      searchParams.delete('sort');
      searchParams.delete('order');
      setSearchParams(searchParams);
      newParamObject = { page: pageSelected, limit: limitSelected };
    }

    handleCommonChange(newParamObject);
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any, extra: any) => {
    let orderBy = '';

    switch (sorter?.order) {
      case OPTIONS_ORDER_BY.DESC.KEY:
        orderBy = OPTIONS_ORDER_BY.DESC.LABEL;
        break;
      case OPTIONS_ORDER_BY.ASC.KEY:
        orderBy = OPTIONS_ORDER_BY.ASC.LABEL;
        break;

      default:
        orderBy = '';
        break;
    }

    handleSort(sorter, orderBy);
  };

  const handleSearchClient = (value: string) => {
    if (value === '') {
      setSearchResults([]);
      return;
    }

    const searchParams = {
      searchKey: value,
    };

    if (clientsPagination?.totalItems > 0) {
      dispatch(searchClients(searchParams))
        .unwrap()
        .then((res) => {
          const convertSearchResults = res?.data?.responses?.map((client: IDataClients) => {
            return { ...client, value: client?.id, label: `${client?.code || ''} ${client?.name}` };
          });
          setSearchResults(convertSearchResults);
        })
        .catch((error) => {
          console.log(`[Clients] handleSearchClient: ${JSON.stringify(error, null, 2)}`);
        });
    }
  };

  const handleSelectClient = (value: string) => {
    handleNavigateClientDetails(value);
  };

  const handleNavigateClientDetails = (id: string) => {
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${id}`);
  };

  const handleChangePagination = (page: number, pageSize: number) => {
    const newParamObject = { ...paramObject, page, limit: pageSize };
    setSearchParams({ ...params, page: page.toString(), limit: pageSize.toString() });

    handleCommonChange(newParamObject, page, pageSize);
  };

  return (
    <div id='clients' className={cx('clientsContainer')}>
      <Row align={'middle'} justify={'space-between'} gutter={{ xs: 8, sm: 16, md: 24 }} className={cx('clientsTableHeader')}>
        <Col className={cx('clientsTableHeaderTitle')}>
          <Title className={cx('clientsTitle')} level={TITLE_LEVEL}>
            {t('clients_list_title')}
          </Title>
        </Col>
        <Col className={cx('clientsActions')}>
          <Tooltip title={t('clients_list_search')}>
            <AutoComplete className={cx('autoCompleteClientSearch')} onSearch={debounce(handleSearchClient, 800)} onSelect={handleSelectClient} options={searchResults}>
              <Search className={cx('clientsSearch')} placeholder={t('clients_list_search')} allowClear enterButton />
            </AutoComplete>
          </Tooltip>
        </Col>
      </Row>
      {!loadingGetList && (
        <>
          <Table
            rowKey={(record) => record.code}
            onRow={(record: any) => ({ onClick: () => handleNavigateClientDetails(record.id) })}
            onChange={handleChangeTable}
            className={cx('clientsTableContent')}
            components={{
              body: {
                row: row,
              },
            }}
            columns={columns}
            dataSource={clients}
            pagination={false}
            scroll={{ y: Y_SCROLL }}
            locale={{
              triggerDesc: t('table_asc_order_sort_tooltip'),
              triggerAsc: t('table_desc_order_sort_tooltip'),
              cancelSort: t('table_cancel_order_sort_tooltip'),
              emptyText: <EmptyTable />,
            }}
          />

          {clientsPagination?.totalItems > 0 && (
            <div className={cx('clientsPaginationContainer')}>
              <Pagination
                onChange={handleChangePagination}
                total={clientsPagination?.totalItems}
                showSizeChanger
                responsive
                pageSize={Number(clientsPagination?.limit) || LIMIT_DEFAULT}
                current={Number(clientsPagination?.page)}
                locale={{ items_per_page: t('table_pagination_text_page') }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Clients;
