import React from 'react';
import classNames from 'classnames/bind';
import { Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '~/redux/hooks';

import { IRevenueByYear } from '~/data/revenue';
import { TITLE_LEVEL } from '~/utils/constants';
import { formatPrice } from '~/utils/helper';
import { selectClientDetail } from '~/thunks/clientDetail/clientDetailSlice';
import styles from './RevenueTable.module.scss';

const cx = classNames.bind(styles);

const RevenueTable = () => {
  const { t } = useTranslation();

  const clientDetail = useAppSelector(selectClientDetail);

  const columns: ColumnsType<IRevenueByYear> = [
    {
      title: t('clients_clientDetails_year_title'),
      dataIndex: 'year',
    },
    {
      title: 'ROSALIQUE',
      dataIndex: 'c_09',
      render: (text) => <span>{formatPrice(text)}</span>,
    },
    {
      title: 'RAYSISTANT',
      dataIndex: 'c_10',
      render: (text) => <span>{formatPrice(text)}</span>,
    },
    {
      title: 'AUSTRALIAN GOLD',
      dataIndex: 'c_12',
      render: (text) => <span>{formatPrice(text)}</span>,
    },
    {
      title: 'BAIJA',
      dataIndex: 'c_13',
      render: (text) => <span>{formatPrice(text)}</span>,
    },
  ];

  return (
    <div className={cx('revenue-wrap')}>
      <Title level={TITLE_LEVEL}>{t('clients_clientDetails_revenue_title')}</Title>
      <Table dataSource={clientDetail.revenue} rowKey={(record) => record.year} columns={columns} pagination={false} />
    </div>
  );
};

export default RevenueTable;
