import React from 'react';
import classNames from 'classnames/bind';
import { Layout, theme } from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Shares
import { HeaderLayout, SideBar } from '~/components';
import packageJson from '../../package.json';
import styles from './LayoutPanel.module.scss';

const cx = classNames.bind(styles);

const LayoutPanel = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout id='layoutPanel' className={cx('adminLayout_container')}>
      {/* <SideBar /> */}
      <Layout className={cx('adminLayout_info')}>
        <HeaderLayout />
        <Content className={cx('adminLayout_content')}>
          <div style={{ background: colorBgContainer }} className={cx('adminLayout_content_detail')}>
            <Outlet />
          </div>
        </Content>
        <Footer className={cx('adminFooter')}>
          <div>
            <span>&copy; {currentYear} </span>
            <span>{t('common_footer_title_before')}</span>
            <span>
              <a href='http://htmlside.com' target='_blank' rel='noopener noreferrer' className={cx('adminLayout_text_target')}>
                {t('common_footer_title_target')}
              </a>
            </span>
            <span>{t('common_footer_title_after')}</span>
          </div>
          <div className={cx('version')}>{`${t('footer_version')} ${packageJson.version}`}</div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutPanel;
