// Libs
import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
// Ant design
import { Button, Col, Flex, Form, Input, InputNumber, Popconfirm, Row, Select, Table, Tooltip, Typography, message } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import type { FormInstance } from 'antd/es/form';
import { ColumnsType } from 'antd/es/table';
// i18next
import { useTranslation } from 'react-i18next';
// Shares
import EmptyTable from '../emptyTable/EmptyTable';
import FooterTable from '../footerTable/FooterTable';
import { LoadingContext } from '~/contexts/LoadingContext';
import { DocumentPDF } from '~/components/documentPDF/DocumentPdf';
// Interface
import { CartTableProps, EditableCellCartProps, EditableRowProps, ICart } from '~/pages/clients/clientDetails/clientDetailsProducts/ClientDetailsProducts.interface';
import { IPayment } from '../newOrder/NewOrder.interface';
// Hooks
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Thunk
import { deleteProductInCart, emptyCart, getProductsInCart, updateProductInCart } from '~/thunks/cart/cartThunk';
import { createOrder, sendPDFOrderEmail } from '~/thunks/order/orderThunk';
import { getPayments } from '~/thunks/payments/paymentsThunk';
import { selectLoadingCart, selectProductsInCart } from '~/thunks/cart/getProductsInCartSlice';
import { selectClientDetail } from '~/thunks/clientDetail/clientDetailSlice';
import { selectDetailCreateOrder } from '~/thunks/order/createOrderSlice';
import { selectPayments } from '~/thunks/payments/paymentsSlice';
// Utils
import {
  DEFAULT_MAX_VALUE_DISCOUNT,
  DEFAULT_MIN_VALUE_DISCOUNT,
  DEFAULT_MIN_VALUE_QUANTITY,
  LIMIT_DEFAULT,
  MAX_DISCOUNT,
  MAX_LENGTH_ORDER_NOTE,
  MIN_X_SCROLL,
  ROUTER_PATHS,
  TITLE_LEVEL,
  UNICODE_EURO,
} from '~/utils/constants';
import { RolesEnum, StoragesEnum } from '~/utils/enum';
import { formatPrice, getErrorMessageByCode, isDivisible, isObjectEmpty, isValidNumberInRange, splitDateTime } from '~/utils/helper';
// Styles
import styles from './CartTable.module.scss';
import './CartTable.css';

const EditableContext = React.createContext<FormInstance<any> | null>(null);
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const cx = classNames.bind(styles);
const { Title } = Typography;
const { TextArea } = Input;

const EditableCell: React.FC<EditableCellCartProps> = ({ title, editable, children, dataIndex, record, handleSaveCart, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const [changeLogic, setChangeLogic] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;
  const { t } = useTranslation();

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (!editing && !changeLogic) {
      form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
    setEditing(!editing);
  };

  const handleFocusInput = async () => {
    try {
      toggleEdit();
      setChangeLogic((prev) => !prev);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleFocusToEdit = async () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSaveCart({ ...record, ...values });
      inputRef.current!.focus();
      setChangeLogic(false);
    } catch (errInfo) {
      toggleEdit();
      console.log(`[EditableCell] save failed: ${JSON.stringify(errInfo, null, 2)}`);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        normalize={(value) => {
          if (dataIndex === 'discount') {
            if (value.charAt(0) === '0') {
              value = value.slice(1);
            }

            if (value.toUpperCase() === MAX_DISCOUNT) {
              return value.toUpperCase();
            }

            if (isValidNumberInRange(value)) {
              return value;
            }

            const valuePrevious = value.slice(0, -1);
            if (isValidNumberInRange(valuePrevious) || valuePrevious === MAX_DISCOUNT) {
              return valuePrevious;
            }

            return '0';
          } else {
            return value || record.minQuantity;
          }
        }}
        rules={[
          {
            message: t('cartTable_change_quantity_failed_minimum'),
            validator: (rule: any, value) => {
              if (rule?.field === 'discount' || changeLogic || isDivisible(value, record.minQuantity)) {
                return Promise.resolve();
              } else {
                return Promise.reject();
              }
            },
          },
          {
            pattern: /^[0-9S]*$/,
            message: t('clients_clientDetails_products_subCategory_discount_validate_fail'),
          },
        ]}
      >
        {dataIndex === 'discount' ? (
          <Input
            min={DEFAULT_MIN_VALUE_DISCOUNT}
            max={DEFAULT_MAX_VALUE_DISCOUNT}
            className={'editTableCellInputNumber'}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onFocus={handleFocusToEdit}
          />
        ) : (
          <InputNumber min={DEFAULT_MIN_VALUE_QUANTITY} className={'editTableCellInputNumber'} ref={inputRef} onPressEnter={save} onBlur={save} onFocus={handleFocusToEdit} />
        )}
      </Form.Item>
    ) : (
      <Flex justify='space-between'>
        {dataIndex === 'quantity' && (
          <Tooltip title={t('clients_clientDetails_tooltip_change_quantity')} placement='left'>
            <Button className={cx('tableIconEdit')} type='text' shape='circle' icon={<EditFilled />} onClick={handleFocusInput} />
          </Tooltip>
        )}
        <div className={cx('editableCellValueWrap')} onClick={toggleEdit}>
          {children}
        </div>
      </Flex>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const CartTable: React.FC<CartTableProps> = ({ clearCart = true, actions = true, isCompletedOrder = false, isShowBackToTop = false, handleGoBack, ...restProps }) => {
  const [dataCart, setDataCart] = useState<ICart[]>([]);
  const productInCart = useAppSelector(selectProductsInCart);
  const loadingCart = useAppSelector(selectLoadingCart);
  const clientDetail = useAppSelector(selectClientDetail);
  const order = useAppSelector(selectDetailCreateOrder);
  const listPayment = useAppSelector(selectPayments);
  const [paymentSelected, setPaymentSelected] = useState<string>(clientDetail?.paymentName);
  const [paymentSelectedId, setPaymentSelectedId] = useState<string>(clientDetail?.paymentId);
  const [searchPaymentResults, setSearchPaymentResults] = useState<IPayment[]>([]);
  const [orderNote, setOrderNote] = useState<string>('');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const { clientId } = useParams();
  const navigate = useNavigate();

  const { detailCart, cartId } = productInCart;

  const totalPriceCart = dataCart?.reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue?.totalPrice);
  }, 0);
  const storedDataString = sessionStorage.getItem(StoragesEnum.DATA_SAVE);

  useEffect(() => {
    if (clientId) {
      dispatch(getProductsInCart({ clientId: clientId! }));
    }
  }, []);

  useEffect(() => {
    setDataCart(detailCart);
  }, [detailCart]);

  useEffect(() => {
    if (isCompletedOrder) {
      setOrderNote(order?.order?.note);

      return;
    }

    setPaymentSelected(clientDetail?.paymentName);
    if (clientDetail?.paymentId) {
      setPaymentSelectedId(clientDetail?.paymentId);
    }
    setOrderNote(clientDetail?.orderNote);
    const storedDataString = sessionStorage.getItem(StoragesEnum.DATA_SAVE);

    if (!storedDataString) {
      sessionStorage.setItem(StoragesEnum.DATA_SAVE, JSON.stringify({ paymentName: clientDetail?.paymentName, paymentId: clientDetail?.paymentId }));
    }

    if (storedDataString) {
      const storedDataObject = JSON.parse(storedDataString);

      if (storedDataObject.clientId !== clientId) {
        sessionStorage.setItem(StoragesEnum.DATA_SAVE, JSON.stringify({ paymentName: clientDetail?.paymentName, paymentId: clientDetail?.paymentId }));
      } else {
        setOrderNote(storedDataObject.orderNote);
        setPaymentSelected(storedDataObject.paymentName);
        setPaymentSelectedId(storedDataObject.paymentId);
      }
    }
  }, [clientDetail]);

  useEffect(() => {
    if (!isCompletedOrder) {
      handleGetPayments();
    }
  }, []);

  useEffect(() => {
    if (!isCompletedOrder) {
      setSearchPaymentResults(listPayment);
    }
  }, [listPayment]);

  const handleGetPayments = () => {
    dispatch(getPayments({ searchKey: '' }))
      .unwrap()
      .then((res) => {})
      .catch((error) => {});
  };

  const handleDeleteProduct = (record: any) => {
    loadingContext?.show();

    dispatch(deleteProductInCart({ productId: record.id }))
      .unwrap()
      .then((res) => {
        dispatch(getProductsInCart({ clientId: clientId! }));
      })
      .then((res) => {
        loadingContext?.hide();
        message.success(`${t('cartTable_delete_product')} ${record.productName} ${t('cartTable_delete_product_success')}`);
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  const handleEmptyCart = () => {
    loadingContext?.show();

    dispatch(emptyCart({ clientId: clientId! }))
      .unwrap()
      .then((res) => {
        dispatch(getProductsInCart({ clientId: clientId! }));
      })
      .then((res) => {
        loadingContext?.hide();
        message.success(t('cartTable_empty_cart_success'));
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  const columnsCart: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = actions
    ? [
        {
          title: t('clients_clientDetails_cart_table_id'),
          dataIndex: 'productCode',
        },
        {
          title: t('clients_clientDetails_cart_table_productName'),
          dataIndex: 'productName',
        },
        {
          title: `${t('clients_clientDetails_cart_table_price')} (${UNICODE_EURO})`,
          dataIndex: 'price',
        },
        {
          title: t('clients_clientDetails_cart_table_quantity'),
          dataIndex: 'quantity',
          width: 160,
          editable: true,
        },
        {
          title: t('clients_clientDetails_cart_table_discount'),
          dataIndex: 'discount',
          width: 120,
          editable: true,
          render: (_, record) => {
            if (+record.discount === 100) {
              return MAX_DISCOUNT;
            }
            return <>{record.discount}</>;
          },
        },
        {
          title: `${t('clients_clientDetails_cart_table_total')} (${UNICODE_EURO})`,
          dataIndex: 'totalPrice',
        },
        {
          title: t('clients_clientDetails_cart_table_actions'),
          dataIndex: 'actions',
          width: 110,
          align: 'center',
          render: (_, record) => (
            <Popconfirm
              onConfirm={() => handleDeleteProduct && handleDeleteProduct(record)}
              placement='topRight'
              title={t('clients_clientDetails_products_delete_confirm_title')}
              description={t('clients_clientDetails_products_delete_confirm_desc')}
              okText={t('common_button_delete')}
              okType='danger'
              cancelText={t('common_button_cancel')}
            >
              <Tooltip title={t('table_action_button_delete')} placement='left'>
                <Button className={cx('clientDetailsCartTableAction')} type='text' shape='circle' icon={<DeleteFilled />} />
              </Tooltip>
            </Popconfirm>
          ),
        },
      ]
    : [
        {
          title: t('clients_clientDetails_cart_table_id'),
          dataIndex: 'productCode',
        },
        {
          title: t('clients_clientDetails_cart_table_productName'),
          dataIndex: 'productName',
        },
        {
          title: `${t('clients_clientDetails_cart_table_price')} (${UNICODE_EURO})`,
          dataIndex: 'price',
        },
        {
          title: t('clients_clientDetails_cart_table_quantity'),
          dataIndex: 'quantity',
        },
        {
          title: t('clients_clientDetails_cart_table_discount'),
          dataIndex: 'discount',
          render: (_, record) => {
            if (+record.discount === 100) {
              return MAX_DISCOUNT;
            }
            return <>{record.discount}</>;
          },
        },
        {
          title: `${t('clients_clientDetails_cart_table_total')} (${UNICODE_EURO})`,
          dataIndex: 'totalPrice',
        },
      ];

  const handleSaveCart = (row: ICart) => {
    loadingContext?.show();

    const payloadAddProduct = {
      cartProductId: row.id,
      quantity: row.quantity,
      discount: row.discount,
    };

    dispatch(updateProductInCart(payloadAddProduct))
      .unwrap()
      .then((res) => {
        dispatch(getProductsInCart({ clientId: clientId! }));
      })
      .then((res) => {
        loadingContext?.hide();
        message.success(t('cartTable_update_success'));
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });

    const newProductsInCart = [...dataCart];
    const index = newProductsInCart.findIndex((item) => row.id === item.id);
    const item = newProductsInCart[index];
    newProductsInCart.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataCart(newProductsInCart);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = columnsCart.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ICart) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveCart,
      }),
    };
  });

  const handleCreateOrder = async () => {
    try {
      loadingContext?.show();
      const storedDataString = sessionStorage.getItem(StoragesEnum.DATA_SAVE);

      if (storedDataString) {
        const storedDataObject = JSON.parse(storedDataString);
        const res = await dispatch(
          createOrder({
            cartId: cartId?.cartId,
            paymentId: Number(storedDataObject.paymentId),
            note: storedDataObject.orderNote,
            destinationId: Number(storedDataObject.destinationId),
          })
        ).unwrap();

        sessionStorage.removeItem(StoragesEnum.DATA_SAVE);

        const dataDetailCart = detailCart?.map((product: any) => {
          return {
            ...product,
            priceProduct: product.price,
          };
        });

        const dataOrder = {
          ...res?.data?.detailOrder?.client,
          total: res?.data?.detailOrder?.order.total,
          note: res?.data?.detailOrder?.order.note,
        };

        const { dateString, timeString } = splitDateTime(res?.data?.order?.createdAt);

        const dateTimePDF = {
          orderId: res?.data?.order?.id,
          dateString,
          timeString,
        };

        const blob = await pdf(<DocumentPDF data={dataOrder} dataTable={dataDetailCart} dateTimePDF={dateTimePDF} />).toBlob();
        const pdfFile = new File([blob], 'order.pdf', { type: 'application/pdf' });
        const formData = new FormData();
        formData.append('file', pdfFile);
        formData.append('orderId', String(res?.data?.order?.id));
        formData.append('type', RolesEnum.ADMIN);

        loadingContext?.show();
        dispatch(sendPDFOrderEmail(formData))
          .unwrap()
          .then((res) => {
            loadingContext?.hide();
          })
          .catch((error) => {
            loadingContext?.hide();
            getErrorMessageByCode(error.response.data.code);
          });

        loadingContext?.hide();
        navigate(ROUTER_PATHS.ORDER_COMPLETED);
        message.success(t('order_create_message_success'));
      }
    } catch (error: any) {
      loadingContext?.hide();
      getErrorMessageByCode(error.response.data.code);
    }
  };

  const handlePaymentSelected = (value: string, option: any) => {
    setPaymentSelected(option.label);
    setPaymentSelectedId(value);

    if (storedDataString) {
      const storedDataObject = JSON.parse(storedDataString);
      const dataToSave = { ...storedDataObject, paymentName: option.label, paymentId: value };
      sessionStorage.setItem(StoragesEnum.DATA_SAVE, JSON.stringify(dataToSave));
    } else {
      const dataToSave = { paymentName: option.label, paymentId: value };
      sessionStorage.setItem(StoragesEnum.DATA_SAVE, JSON.stringify(dataToSave));
    }
  };

  const handleSearchPayment = (value: string) => {
    dispatch(getPayments({ searchKey: value }))
      .unwrap()
      .then((res) => {
        const convertSearchResults = res?.data?.responses?.map((payment: IPayment) => {
          return { ...payment, value: payment?.id, label: payment?.name };
        });
        setSearchPaymentResults(convertSearchResults);
      })
      .catch((error) => {});
  };

  const handleChangeOrderNote = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOrderNote(e.target.value);
  };

  const handleSaveOrderNote = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (storedDataString) {
      const storedDataObject = JSON.parse(storedDataString);
      const dataToSave = { ...storedDataObject, orderNote: e.target.value ?? '' };
      sessionStorage.setItem(StoragesEnum.DATA_SAVE, JSON.stringify(dataToSave));
    } else {
      const dataToSave = { orderNote: e.target.value ?? '' };
      sessionStorage.setItem(StoragesEnum.DATA_SAVE, JSON.stringify(dataToSave));
    }
  };

  const renderTableFooter = () => {
    if (!dataCart || dataCart.length === 0) {
      return <></>;
    }

    return <FooterTable total={formatPrice(totalPriceCart)} />;
  };

  const handleBackToTop = () => {
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.CATEGORIES}`);
  };

  return (
    <div id='cartTable'>
      <div className={cx('clientDetailsCart')}>
        <Flex className={cx('clientDetailsCartHeader')} align='center' justify='space-between'>
          <Title level={TITLE_LEVEL}>{t('clients_clientDetails_cart_title')}</Title>
          {!loadingCart && clearCart && detailCart && (
            <Popconfirm
              title={t('cartTable_empty_cart_title')}
              description={t('cartTable_empty_cart_description')}
              placement='topRight'
              okText={t('common_button_delete')}
              okType='danger'
              cancelText={t('common_button_cancel')}
              onConfirm={handleEmptyCart}
            >
              <Button size='large' danger>
                {t('clients_clientDetails_cart_emptyCart')}
              </Button>
            </Popconfirm>
          )}
        </Flex>

        {!loadingCart && (
          <>
            <Table
              rowKey={(record) => record.id}
              className={cx('clientDetailsCartTable')}
              components={components}
              rowClassName={() => 'editable-row'}
              columns={columns as ColumnTypes}
              dataSource={dataCart}
              pagination={false}
              scroll={{ x: MIN_X_SCROLL }}
              locale={{
                emptyText: <EmptyTable />,
              }}
              footer={renderTableFooter}
            />
            {detailCart?.length > LIMIT_DEFAULT && (
              <Flex className={cx('clientDetailsCartLoadingMore')} align='center' justify='center'>
                <Button size='large' type='primary'>
                  {t('common_button_loadingMore')}
                </Button>
              </Flex>
            )}
          </>
        )}
        {!isCompletedOrder && (
          <Form layout='vertical' size='large'>
            <Row gutter={{ sm: 16 }}>
              <Col xs={24} lg={12}>
                <Form.Item label={t('clients_clientDetails_newOrder_paymentType')}>
                  <Select
                    showSearch
                    value={paymentSelected}
                    placeholder={t('clients_clientDetails_newOrder_paymentType_placeholder')}
                    onChange={handlePaymentSelected}
                    onSearch={handleSearchPayment}
                    options={(searchPaymentResults || []).map((d) => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={false}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label={t('clients_clientDetails_newOrder_orderNote')}>
                  <TextArea
                    showCount={true}
                    allowClear={true}
                    onBlur={handleSaveOrderNote}
                    className={cx('clientDetailsNewOrderNote')}
                    style={{ resize: 'none' }}
                    maxLength={MAX_LENGTH_ORDER_NOTE}
                    onChange={handleChangeOrderNote}
                    value={orderNote}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>

      <Flex className={cx('clientDetailCartFooter')} align='center' justify='space-between' gap={16}>
        {actions && (
          <>
            <Flex gap={10}>
              <Button type='primary' size='large' onClick={handleGoBack}>
                {t('common_button_back')}
              </Button>

              {isShowBackToTop && (
                <Button type='primary' size='large' onClick={handleBackToTop}>
                  {t('common_button_back_to_top')}
                </Button>
              )}
            </Flex>

            {!loadingCart && detailCart?.length > 0 && (
              <div className={cx('createOrderBtn')} onClick={handleCreateOrder}>
                {t('clients_clientDetails_cart_createOrder')}
              </div>
            )}
          </>
        )}
      </Flex>
    </div>
  );
};

export default CartTable;
