import axios from 'axios';
import { BASE_URL, UNAUTHORIZE_CODE } from '~/utils/constants';
import { StoragesEnum } from '~/utils/enum';
import authApi from './auth';

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRequestSuccess = (config) => {
  const token = localStorage.getItem(StoragesEnum.ACCESS_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onResponseError = async (error) => {
  const originalRequest = error.config;
  if (error.response.status === UNAUTHORIZE_CODE && !originalRequest._retry && localStorage.getItem(StoragesEnum.ACCESS_TOKEN)) {
    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const refreshResponse = await authApi.getRefreshToken({
          refreshToken: localStorage.getItem(StoragesEnum.REFRESH_TOKEN),
        });
        const newAccessToken = refreshResponse.data.data.access;
        const newRefreshToken = refreshResponse.data.data.refresh;

        localStorage.setItem(StoragesEnum.ACCESS_TOKEN, newAccessToken);
        localStorage.setItem(StoragesEnum.REFRESH_TOKEN, newRefreshToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        const response = await axios(originalRequest);

        // Mark the token refreshing process as finished
        isRefreshing = false;

        // Call any subscribers that were registered during the token refreshing process and pass the new token to them
        refreshSubscribers.forEach((subscriber) => subscriber(newAccessToken));
        refreshSubscribers = [];

        return response;
      } catch (error) {
        isRefreshing = false;
        // Notify all registered subscribers about the error occurred during token refreshing
        refreshSubscribers.forEach((reject) => reject(error));
        refreshSubscribers = [];

        return Promise.reject(error);
      }
    } else {
      const retrySubscribersPromise = new Promise((resolve, reject) => {
        // If a new token is received from the subscriber, update the header and retry the request
        refreshSubscribers.push((newAccessToken, error) => {
          if (newAccessToken) {
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            resolve(axios(originalRequest));
          } else {
            reject(error);
          }
        });
      });
      return retrySubscribersPromise;
    }
  }
  return Promise.reject(error);
};

// Add a request interceptor
axiosClient.interceptors.request.use(onRequestSuccess, (error) => Promise.reject(error));
// Add a response interceptor
axiosClient.interceptors.response.use((response) => response, onResponseError);

export default axiosClient;
