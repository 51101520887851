import { DatabaseOutlined, ShoppingCartOutlined, TeamOutlined } from '@ant-design/icons';
import { Menu, MenuProps, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getSideNav } from '~/utils/helper';
import { imgLogo, imgLogoFull } from '~/utils/image.constants';
import styles from './SideBar.module.scss';
import { ADMIN_PROTECTED_PATH } from '~/utils/constants';

const cx = classNames.bind(styles);

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const locations = useLocation().pathname.split('/');

  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    if (locations.includes('settings')) {
      return ['settings'];
    }
    return [];
  });

  const items: MenuItem[] = [
    getItem(<Link to={'' + ADMIN_PROTECTED_PATH.CLIENTS.path}>{ADMIN_PROTECTED_PATH.CLIENTS.title}</Link>, ADMIN_PROTECTED_PATH.CLIENTS.key, <TeamOutlined />),
    getItem(<Link to={'' + ADMIN_PROTECTED_PATH.ORDERS.path}>{ADMIN_PROTECTED_PATH.ORDERS.title}</Link>, ADMIN_PROTECTED_PATH.ORDERS.key, <ShoppingCartOutlined />),
    getItem(
      <Link to={'' + ADMIN_PROTECTED_PATH.UPDATE_DATABASE.path}>{ADMIN_PROTECTED_PATH.UPDATE_DATABASE.title}</Link>,
      ADMIN_PROTECTED_PATH.UPDATE_DATABASE.key,
      <ShoppingCartOutlined />
    ),
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys?.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Sider width={240} style={{ background: colorBgContainer }} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} className={cx('sidebar_container')}>
      <div className={cx('adminLayout_logo')}>
        <Link to='/'>{collapsed ? <img src={imgLogo} alt='logo' height={48} /> : <img src={imgLogoFull} alt='logo' height={48} />}</Link>
      </div>
      <Menu mode='inline' openKeys={openKeys} onOpenChange={handleOpenChange} defaultSelectedKeys={[getSideNav(locations).selectedKey]} items={items} />
    </Sider>
  );
};

export default SideBar;
