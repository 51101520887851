import { useState, useEffect } from 'react';

interface Breakpoints {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
}

const useBreakpoint = (): Breakpoints => {
  const [breakpoints, setBreakpoints] = useState<Breakpoints>({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  });

  useEffect(() => {
    const updateBreakpoints = () => {
      const xsMatch = window.matchMedia('(max-width: 480px)');
      const smMatch = window.matchMedia('(max-width: 576px)');
      const mdMatch = window.matchMedia('(max-width: 768px)');
      const lgMatch = window.matchMedia('(min-width: 992px)');
      const xlMatch = window.matchMedia('(min-width: 1200px)');
      const xxlMatch = window.matchMedia('(min-width: 1600px)');

      setBreakpoints({
        xs: xsMatch.matches,
        sm: smMatch.matches,
        md: mdMatch.matches,
        lg: lgMatch.matches,
        xl: xlMatch.matches,
        xxl: xxlMatch.matches,
      });
    };

    updateBreakpoints();

    window.addEventListener('resize', updateBreakpoints);

    return () => {
      window.removeEventListener('resize', updateBreakpoints);
    };
  }, []);

  return breakpoints;
};

export default useBreakpoint;
