import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderApi } from '~/apis/order';
import { IParamsOrder, IPayloadCreateOrder, IPayloadDeleteOrder, IPayloadGetDetailOrder, IPayloadSendPDFEmail } from '~/pages/orders/Orders.interface';
import { DELETE_ORDER, GET_ORDERS, SEARCH_ORDERS, CREATE_ORDER, GET_DETAIL_ORDER, SEND_PDF_ORDER_EMAIL } from '~/utils/actionType';

export const getOrders = createAsyncThunk(GET_ORDERS, async (payload: IParamsOrder, { rejectWithValue }) => {
  try {
    const res = await orderApi.getOrders(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchOrders = createAsyncThunk(SEARCH_ORDERS, async (payload: IParamsOrder, { rejectWithValue }) => {
  try {
    const res = await orderApi.getOrders(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteOrder = createAsyncThunk(DELETE_ORDER, async (payload: IPayloadDeleteOrder, { rejectWithValue }) => {
  try {
    const res = await orderApi.deleteOrder(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createOrder = createAsyncThunk(CREATE_ORDER, async (payload: IPayloadCreateOrder, { rejectWithValue }) => {
  try {
    const res = await orderApi.createOrder(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const detailOrder = createAsyncThunk(GET_DETAIL_ORDER, async (payload: IPayloadGetDetailOrder, { rejectWithValue }) => {
  try {
    const res = await orderApi.getOrder(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendPDFOrderEmail = createAsyncThunk(SEND_PDF_ORDER_EMAIL, async (payload: FormData, { rejectWithValue }) => {
  try {
    const res = await orderApi.sendPDFEmail(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
