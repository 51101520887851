import { createSlice } from '@reduxjs/toolkit';
import { getDetailAgent } from './agentThunk';

interface IAgentState {
  loading: boolean;
  agentDetail: {};
}

const initialState: IAgentState = {
  loading: false,
  agentDetail: {},
};

const detailAgentSlice = createSlice({
  name: 'agent/detailAgent',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDetailAgent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDetailAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agentDetail = action.payload.data
      })
      .addCase(getDetailAgent.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const detailAgentActions = detailAgentSlice.actions;

export const selectDetailAgent = (state: any) => state.detailAgent.agentDetail;
export const selectLoading = (state: any) => state.detailAgent.loading;

const agentReducer = detailAgentSlice.reducer;
export default agentReducer;
