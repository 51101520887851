import { createAsyncThunk } from '@reduxjs/toolkit';
import { subCategoriesApi } from '~/apis/subCategories';
import { IParamsSearchSubCategories, IParamsSubCategories } from '~/pages/clients/Clients.interface';
import { GET_SUB_CATEGORIES_BY_CATEGORY, SEARCH_SUB_CATEGORIES_BY_CATEGORY } from '~/utils/actionType';

export const getSubCategoriesByCategory = createAsyncThunk(GET_SUB_CATEGORIES_BY_CATEGORY, async (payload: IParamsSubCategories, { rejectWithValue }) => {
  try {
    const res = await subCategoriesApi.getSubCategoriesByCategory(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSubCategoriesSearchResult = createAsyncThunk(SEARCH_SUB_CATEGORIES_BY_CATEGORY, async (payload: IParamsSearchSubCategories, { rejectWithValue }) => {
  try {
    const res = await subCategoriesApi.searchSubCategoriesByCategory(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
