import classNames from 'classnames/bind';
// Libs
import { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Dropdown, Flex, Menu, MenuProps, Row, Select, Space, theme } from 'antd';
import { SettingOutlined, UserOutlined, DatabaseOutlined, ShoppingCartOutlined, TeamOutlined, MenuOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
// Scss
import styles from './Header.module.scss';
// I18next
import { useTranslation } from 'react-i18next';
// Thunk
import { authActions } from '~/thunks/auth/authSlice';
// Shares utils
import { ADMIN_PROTECTED_PATH, LANGUAGE_OPTION, MENU_KEY, ROUTER_PATHS, ROLE_ADMIN_MENU, EXACT_PATH } from '~/utils/constants';
import { icLogout } from '~/utils/icon.constants';
import { imgLogo } from '~/utils/image.constants';
import { getSideNav } from '~/utils/helper';
import { LanguagesEnum, RolesEnum, StoragesEnum } from '~/utils/enum';
// Shares hook
import useBreakpoint from '~/hooks/useBreakPoint';

const cx = classNames.bind(styles);

const { Option } = Select;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const HeaderLayout = () => {
  const savedLanguage = localStorage.getItem(StoragesEnum.LANGUAGE);
  const currentRole = localStorage.getItem(StoragesEnum.ROLE);
  const languageFromLocal = savedLanguage && (savedLanguage as LanguagesEnum);

  const [userName, setUserName] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguagesEnum.EN | LanguagesEnum.IT>(languageFromLocal || LanguagesEnum.IT);

  const { t, i18n } = useTranslation();
  const { lg } = useBreakpoint();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locations = useLocation().pathname.split('/');
  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    if (locations.includes('settings')) {
      return ['settings'];
    }
    return [];
  });

  useLayoutEffect(() => {
    const userName = localStorage.getItem(StoragesEnum.USER_NAME);
    if (userName) {
      setUserName(userName);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const SelectLanguage = () => {
    return (
      <Select value={selectedLanguage} onChange={handleChangeLanguage} className={cx('headerSelectLanguage')}>
        {LANGUAGE_OPTION.map((language) => (
          <Option key={language.KEY} value={language.VALUE}>
            {language.LABEL}
          </Option>
        ))}
      </Select>
    );
  };

  const items: MenuProps['items'] =
    currentRole === RolesEnum.ADMIN
      ? [
          {
            label: <div>{`${t('header_menu_hello')} ${userName || ROLE_ADMIN_MENU}`}</div>,
            key: '0',
          },
          {
            type: 'divider',
          },
          {
            label: (
              <div className={cx('headerLogout')}>
                <div className={cx('menuItemIcon')}>
                  <UserOutlined />
                </div>
                <span>{t('header_menu_profile')}</span>
              </div>
            ),
            key: MENU_KEY.PROFILE,
          },
          {
            label: (
              <div className={cx('headerLogout')}>
                <img className={cx('headerLogoutIcon')} alt='logout' src={icLogout} />
                {t('header_menu_logout')}
              </div>
            ),
            key: MENU_KEY.LOGOUT,
          },
        ]
      : [
          {
            label: <div>{`${t('header_menu_hello')} ${userName || ROLE_ADMIN_MENU}`}</div>,
            key: '0',
          },
          {
            type: 'divider',
          },
          {
            label: (
              <div className={cx('headerLogout')}>
                <img className={cx('headerLogoutIcon')} alt='logout' src={icLogout} />
                {t('header_menu_logout')}
              </div>
            ),
            key: MENU_KEY.LOGOUT,
          },
        ];

  const menuItems: MenuItem[] =
    currentRole === RolesEnum.ADMIN
      ? [
          getItem(<Link to={'' + ADMIN_PROTECTED_PATH.CLIENTS.path}>{t('header_menu_clients')}</Link>, ADMIN_PROTECTED_PATH.CLIENTS.key, <TeamOutlined />),
          getItem(<Link to={'' + ADMIN_PROTECTED_PATH.ORDERS.path}>{t('header_menu_orders')}</Link>, ADMIN_PROTECTED_PATH.ORDERS.key, <ShoppingCartOutlined />),
          getItem(<Link to={'' + ADMIN_PROTECTED_PATH.AGENT.path}>{t('header_menu_agents')}</Link>, ADMIN_PROTECTED_PATH.AGENT.key, <UsergroupAddOutlined />),
          getItem(
            <Link to={'' + ADMIN_PROTECTED_PATH.UPDATE_DATABASE.path}>{t('header_menu_updateDatabase')}</Link>,
            ADMIN_PROTECTED_PATH.UPDATE_DATABASE.key,
            <DatabaseOutlined />
          ),
        ]
      : [
          getItem(<Link to={'' + ADMIN_PROTECTED_PATH.CLIENTS.path}>{t('header_menu_clients')}</Link>, ADMIN_PROTECTED_PATH.CLIENTS.key, <TeamOutlined />),
          getItem(<Link to={'' + ADMIN_PROTECTED_PATH.ORDERS.path}>{t('header_menu_orders')}</Link>, ADMIN_PROTECTED_PATH.ORDERS.key, <ShoppingCartOutlined />),
        ];

  const menuItemPlusHorizontal: MenuItem[] = [
    { type: 'divider' },
    getItem(
      <Link to={'' + MENU_KEY.PROFILE}>
        <div className={cx('headerLogout')}>
          <div className={cx('menuItemIcon')}>
            <UserOutlined />
          </div>
          <span>{t('header_menu_profile')}</span>
        </div>
      </Link>,
      MENU_KEY.PROFILE
    ),
    getItem(
      <div
        onClick={() => {
          dispatch(authActions.handleLogout());
          navigate(ROUTER_PATHS.LOGIN);
        }}
        className={cx('headerLogout')}
      >
        <img className={cx('headerLogoutIcon')} alt='logout' src={icLogout} />
        {t('header_menu_logout')}
      </div>,
      MENU_KEY.LOGOUT
    ),
  ];
  const handleOpenChangeMenu = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys?.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleLogout = () => {
    dispatch(authActions.handleLogout());
    navigate(ROUTER_PATHS.LOGIN);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === MENU_KEY.LOGOUT) {
      setOpen(false);
      handleLogout();
    }

    if (e.key === MENU_KEY.PROFILE) {
      setOpen(false);
      navigate(MENU_KEY.PROFILE);
    }
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const handleChangeLanguage = (lng: LanguagesEnum) => {
    setSelectedLanguage(lng);
    localStorage.setItem(StoragesEnum.LANGUAGE, lng);
  };

  return (
    <div id='header'>
      <Header style={{ background: colorBgContainer }} className={cx('adminHeader')}>
        <div className={cx('adminHeaderLogo')}>
          <Link to={EXACT_PATH.MAIN}>
            <Flex align='center' justify='center' gap={8}>
              <img src={imgLogo} alt='logo' height={28} />
              <span className={cx('adminHeaderLogoText')}>{t('text_logo')}</span>
            </Flex>
          </Link>
        </div>

        {lg && <Menu mode='horizontal' openKeys={openKeys} onOpenChange={handleOpenChangeMenu} selectedKeys={[locations[1]]} items={menuItems} className={cx('headerMenu')} />}

        {!lg && (
          <Space>
            <SelectLanguage />
            <div className={cx('adminHeaderMenuToggle')}>
              <Menu
                mode='horizontal'
                openKeys={openKeys}
                onOpenChange={handleOpenChangeMenu}
                selectedKeys={[locations[1]]}
                items={!lg && [...menuItems, ...menuItemPlusHorizontal]}
                overflowedIndicator={<MenuOutlined className={cx('iconMenu')} />}
                className={cx('headerMenuToggle')}
              />
            </div>
          </Space>
        )}

        {lg && (
          <Row align='middle' gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col>
              <SelectLanguage />
            </Col>
            <Col>
              <div className={cx('headerAction')}>
                <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                    selectedKeys: [locations[1]],
                  }}
                  onOpenChange={handleOpenChange}
                  open={open}
                  trigger={['click']}
                  overlayClassName={cx('dropdownContainer')}
                >
                  <span onClick={(e) => e.preventDefault()} className={cx('headerDropdown')}>
                    <SettingOutlined className={cx('headerDropdownIcon')} />
                  </span>
                </Dropdown>
              </div>
            </Col>
          </Row>
        )}
      </Header>
    </div>
  );
};

export default HeaderLayout;
