import { createSlice } from '@reduxjs/toolkit';
import { updateProductInCart } from './cartThunk';

interface ICartState {
  loading: boolean;
}

const initialState: ICartState = {
  loading: false,
};

const updateProductInCartSlice = createSlice({
  initialState,
  name: 'cart/updateProductInCart',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateProductInCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProductInCart.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProductInCart.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const updateProductInCartActions = updateProductInCartSlice.actions;

const updateProductInCartReducer = updateProductInCartSlice.reducer;
export default updateProductInCartReducer;
