// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Apis
import { cartApi } from '~/apis/cart';
// Interface
import { IPayloadCreateAndUpdateCart, IPayloadDeleteProductInCart, IPayloadEmptyCart, IPayloadGetProductsInCart, IPayloadUpdateProductInCart } from '~/data/cart';
// Utils
import { ADD_PRODUCT_TO_CART, DELETE_PRODUCT_IN_CART, EMPTY_CART, GET_PRODUCTS_IN_CART, UPDATE_PRODUCT_IN_CART } from '~/utils/actionType';

export const createAndUpdateCart = createAsyncThunk(ADD_PRODUCT_TO_CART, async (payload: IPayloadCreateAndUpdateCart, { rejectWithValue }) => {
  try {
    const res = await cartApi.createAndUpdateCart(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateProductInCart = createAsyncThunk(UPDATE_PRODUCT_IN_CART, async (payload: IPayloadUpdateProductInCart, { rejectWithValue }) => {
  try {
    const res = await cartApi.updateProductInCart(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProductsInCart = createAsyncThunk(GET_PRODUCTS_IN_CART, async (payload: IPayloadGetProductsInCart, { rejectWithValue }) => {
  try {
    const res = await cartApi.getProductsInCart(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteProductInCart = createAsyncThunk(DELETE_PRODUCT_IN_CART, async (payload: IPayloadDeleteProductInCart, { rejectWithValue }) => {
  try {
    const res = await cartApi.deleteProductInCart(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const emptyCart = createAsyncThunk(EMPTY_CART, async (payload: IPayloadEmptyCart, { rejectWithValue }) => {
  try {
    const res = await cartApi.emptyCart(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
