import { createSlice } from '@reduxjs/toolkit';
import { deleteOrder } from './orderThunk';

interface IDeleteOrderState {
  loading: boolean;
}

const initialState: IDeleteOrderState = {
  loading: false,
};

const deleteOrderSlice = createSlice({
  name: 'deleteOrder',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(deleteOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const deleteOrderActions = deleteOrderSlice.actions;

export const selectLoading = (state: any) => state.deleteOrder.loading;

const deleteOrderReducer = deleteOrderSlice.reducer;
export default deleteOrderReducer;
