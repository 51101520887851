import { createSlice } from '@reduxjs/toolkit';
import { deleteAgent } from './agentThunk';

interface IAgentState {
  loading: boolean;
}

const initialState: IAgentState = {
  loading: false,
};

const deleteAgentSlice = createSlice({
  name: 'agent/deleteAgent',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(deleteAgent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAgent.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const deleteAgentActions = deleteAgentSlice.actions;

export const selectDeleteAgent = (state: any) => state.deleteAgent.agents;

const agentReducer = deleteAgentSlice.reducer;
export default agentReducer;
