import {
  ClientDetails,
  ClientDetailsCategories,
  ClientDetailsOrderCompleted,
  ClientDetailsProducts,
  ClientDetailsSubCategories,
  Clients,
  OrderDetail,
  Orders,
  Profile,
  UpdateDatabase,
  AddAgent,
  Agents,
  EditAgents,
} from '~/pages';

export const publicRoute = [
  {
    name: 'Client List',
    path: '/clients',
    component: Clients,
  },
  {
    name: 'Order List',
    path: '/orders',
    component: Orders,
  },
];

export const publicRouteDetail = [
  {
    name: 'Client Details',
    path: '/clients/clientDetails/:clientId',
    component: ClientDetails,
  },
  {
    name: 'Order Detail',
    path: '/orders/orderDetail/:id',
    component: OrderDetail,
  },
  {
    name: 'Client Details Categories',
    path: '/clients/clientDetails/:clientId/categories',
    component: ClientDetailsCategories,
  },
  {
    name: 'Client Details Sub Categories',
    path: '/clients/clientDetails/:clientId/categories/subCategories/:categoryId',
    component: ClientDetailsSubCategories,
  },
  {
    name: 'Client Details Products',
    path: '/clients/clientDetails/:clientId/products/subCategory/:subCategoryId',
    component: ClientDetailsProducts,
  },
  {
    name: 'Client Details Order Completed',
    path: '/clients/clientDetails/orderCompleted',
    component: ClientDetailsOrderCompleted,
  },
];

export const privateRoute = [
  {
    name: 'Agent',
    path: '/agents',
    component: Agents,
  },
  {
    name: 'Update Database',
    path: '/updateDatabase',
    component: UpdateDatabase,
  },
];

export const privateRouteDetails = [
  {
    name: 'Add Agent',
    path: '/agents/addAgent',
    component: AddAgent,
  },
  {
    name: 'Edit Agent',
    path: '/agents/editAgent/:id',
    component: EditAgents,
  },
  {
    name: 'Profile',
    path: '/profile',
    component: Profile,
  },
];
