import { createSlice } from '@reduxjs/toolkit';
import { getProductsInCart } from './cartThunk';

interface IGetProductsInCartState {
  loading: boolean;
  products: {};
}

const initialState: IGetProductsInCartState = {
  loading: false,
  products: {},
};

const getProductsInCartSlice = createSlice({
  initialState,
  name: 'cart/getProducts',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProductsInCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProductsInCart.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload?.data;
      })
      .addCase(getProductsInCart.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const getProductsInCartActions = getProductsInCartSlice.actions;

export const selectProductsInCart = (state: any) => state.getProductsInCart.products;
export const selectLoadingCart = (state: any) => state.getProductsInCart.loading;

const getProductsInCartReducer = getProductsInCartSlice.reducer;
export default getProductsInCartReducer;
