import { createAsyncThunk } from '@reduxjs/toolkit';

import { LOGIN } from '~/utils/actionType';
import { LanguagesEnum, StoragesEnum } from '~/utils/enum';
import { ILoginPayload } from '~/pages/login/Login.interface';
import authApi from '~/apis/auth';

export const handleLogin = createAsyncThunk(LOGIN, async (payload: ILoginPayload, { rejectWithValue }) => {
  try {
    const res = await authApi.login(payload);

    localStorage.setItem(StoragesEnum.ACCESS_TOKEN, res.data?.data?.token?.access);
    localStorage.setItem(StoragesEnum.REFRESH_TOKEN, res.data?.data?.token?.refresh);
    localStorage.setItem(StoragesEnum.USER_NAME, res.data?.data?.account?.name);
    if (!localStorage.getItem(StoragesEnum.LANGUAGE)) localStorage.setItem(StoragesEnum.LANGUAGE, LanguagesEnum.IT);

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
