// Libs
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// I18next
import { useTranslation } from 'react-i18next';
// Components
import NotFoundPage from '~/components/common/notFound/NotFound';
import { Login } from '~/pages';
import PrivateRoutes from './components/common/privateRoutes/PrivateRoutes';
// Shares
import LayoutPanel from './layouts/LayoutPanel';
import LayoutMultipleBlocks from './layouts/LayoutMultipleBlocks';
// Utils
import { privateRoute, privateRouteDetails, publicRoute, publicRouteDetail } from './utils/routers/routers';
import { ADMIN_PROTECTED_PATH, ROUTER_PATHS } from './utils/constants';
import { LanguagesEnum, RolesEnum, StoragesEnum } from './utils/enum';

type Props = {};

const App = (props: Props) => {
  const { i18n } = useTranslation();

  const defaulLanguage = localStorage.getItem(StoragesEnum.LANGUAGE) || LanguagesEnum.IT;

  useEffect(() => {
    i18n.changeLanguage(defaulLanguage);
  }, [defaulLanguage]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes role={[RolesEnum.ADMIN, RolesEnum.AGENT]} />}>
            <Route element={<LayoutPanel />}>
              <Route path='/' element={<Navigate to={`/${ADMIN_PROTECTED_PATH.CLIENTS.path}`} replace />} />
              {publicRoute.map((route: any, index: number) => (
                <Route key={index} path={route.path} element={<route.component />} />
              ))}
            </Route>

            <Route element={<LayoutMultipleBlocks />}>
              {publicRouteDetail.map((route: any, index: number) => (
                <Route key={index} path={route.path} element={<route.component />} />
              ))}
            </Route>
          </Route>

          <Route element={<PrivateRoutes role={[RolesEnum.ADMIN]} />}>
            <Route element={<LayoutPanel />}>
              {privateRoute.map((route: any, index: number) => (
                <Route key={index} path={route.path} element={<route.component />} />
              ))}
            </Route>

            <Route element={<LayoutMultipleBlocks />}>
              {privateRouteDetails.map((route: any, index: number) => (
                <Route key={index} path={route.path} element={<route.component />} />
              ))}
            </Route>
          </Route>

          <Route path={ROUTER_PATHS.LOGIN} element={<Login />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
