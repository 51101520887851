// Libs
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Input, Typography, Table, Space, Row, Col, Popconfirm, Button, Tooltip, Pagination, message, AutoComplete } from 'antd';
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import classNames from 'classnames/bind';
import { debounce } from 'lodash';
// Ant design
import { Flex } from 'antd';
// Thunk
import { deleteAgent, getListAgent, searchAgents } from '~/thunks/agent/agentThunk';
import { selectListAgent, selectLoading } from '~/thunks/agent/listAgentSlice';
// Shares
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { IParamAccountAgent } from '~/data/account';
import styles from './Agent.module.scss';
// Utils
import { FIRST_PAGE, LIMIT_DEFAULT, OPTIONS_ORDER_BY, ROUTER_PATHS, SCROLL_X_TABLE, SORT_COLUMN_BY, TITLE_LEVEL, Y_SCROLL } from '~/utils/constants';
import { defaultSortOrder, getErrorMessageByCode, getTimezone } from '~/utils/helper';
// Interface
import { IDataAgent } from './Agent.interface';
// Components
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';
const cx = classNames.bind(styles);

const { Search } = Input;
const { Title } = Typography;

const Agents: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const [searchResults, setSearchResults] = useState<IDataAgent[]>([]);
  const [pageSelected, setPageSelected] = useState<number>(Number(params?.page));
  const [limitSelected, setLimitSelected] = useState<number>(Number(params?.limit));
  const [paramObject, setParamObject] = useState<IParamAccountAgent>({
    sort: params?.sort,
    order: params?.order,
    page: pageSelected,
    limit: limitSelected,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);

  const loadingGetList = useAppSelector(selectLoading);
  const dataSourceAgent = useAppSelector(selectListAgent);
  const { responses, pagination } = dataSourceAgent;

  useEffect(() => {
    setSearchParams({ ...params });
    handleGetListAgent(paramObject);
  }, [params?.page, params?.limit]);

  const columns: ColumnType<IDataAgent>[] = [
    {
      title: t('common_agent_name'),
      dataIndex: 'name',
      sorter: responses?.length > 0,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.AGENT.NAME.param),
      render: (data) => (data ? data : '-'),
    },
    {
      title: t('common_agent_surname'),
      dataIndex: 'surname',
      sorter: responses?.length > 0,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.AGENT.SURNAME.param),
      render: (data) => (data ? data : '-'),
    },
    {
      title: t('common_agent_email'),
      dataIndex: 'email',
      sorter: responses?.length > 0,
      defaultSortOrder: defaultSortOrder(paramObject?.order, paramObject?.sort, SORT_COLUMN_BY.AGENT.EMAIL.param),
      render: (data) => (data ? data : '-'),
    },
    {
      title: t('agent_list_table_actions'),
      dataIndex: 'actions',
      width: 140,
      render: (_, agent) => {
        return (
          <Space size='middle'>
            <Popconfirm
              title={t('agent_list_table_popup_title')}
              description={t('agent_list_table_popup_confirm')}
              onConfirm={() => handleDeleteAgent(agent.id)}
              okText={t('agent_list_button_confirm')}
              okType='danger'
              cancelText={t('agent_list_button_cancel')}
            >
              <Tooltip title={t('table_action_button_delete')} placement='left'>
                <Button className={cx('agentsIconDelete')} type='text' shape='circle' icon={<DeleteFilled />} />
              </Tooltip>
            </Popconfirm>
            <Tooltip title={t('table_action_button_edit')} placement='left'>
              <Button className={cx('agentsIconEdit')} type='text' shape='circle' icon={<EditFilled />} onClick={() => handleNavigateEditAgent(agent.id)} />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const handleGetListAgent = (params: IParamAccountAgent) => {
    loadingContext?.show();
    dispatch(getListAgent(params))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleCommonChange = (newParamObject: IParamAccountAgent, currentPage?: number, currentLimit?: number) => {
    setParamObject(newParamObject);
    handleGetListAgent(newParamObject);

    if (currentPage) {
      setPageSelected(currentPage);
    }

    if (currentLimit) {
      setLimitSelected(currentLimit);
    }
  };

  // sort table
  const handleSort = (sorter: any, order: string) => {
    let newParamObject = {};
    let columnParam = '';
    if (order) {
      switch (sorter?.field) {
        case SORT_COLUMN_BY.AGENT.CODE.key:
          columnParam = SORT_COLUMN_BY.AGENT.CODE.param;
          break;
        case SORT_COLUMN_BY.AGENT.NAME.key:
          columnParam = SORT_COLUMN_BY.AGENT.NAME.param;
          break;
        case SORT_COLUMN_BY.AGENT.SURNAME.key:
          columnParam = SORT_COLUMN_BY.AGENT.SURNAME.param;
          break;
        case SORT_COLUMN_BY.AGENT.EMAIL.key:
          columnParam = SORT_COLUMN_BY.AGENT.EMAIL.param;
          break;
        default:
          break;
      }
      newParamObject = { ...paramObject, sort: columnParam, order: order };
      setSearchParams({ ...params, sort: columnParam, order: order });
    } else {
      searchParams.delete('sort');
      searchParams.delete('order');
      setSearchParams(searchParams);
      newParamObject = { page: pageSelected, limit: limitSelected };
    }

    handleCommonChange(newParamObject);
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    let orderBy = '';

    switch (sorter?.order) {
      case OPTIONS_ORDER_BY.DESC.KEY:
        orderBy = OPTIONS_ORDER_BY.DESC.LABEL;
        break;
      case OPTIONS_ORDER_BY.ASC.KEY:
        orderBy = OPTIONS_ORDER_BY.ASC.LABEL;
        break;

      default:
        orderBy = '';
        break;
    }
    handleSort(sorter, orderBy);
  };

  const handleSelectAgent = (value: string | number) => {
    handleNavigateAgentDetails(value);
  };

  const handleNavigateAgentDetails = (id: string | number) => {
    navigate(`${ROUTER_PATHS.AGENTS_EDIT}/${id}`);
  };

  const handleSearchAgent = (value: string) => {
    if (value === '') {
      setSearchResults([]);
      return;
    }

    const searchParams = {
      searchKey: value,
    };

    if (pagination?.totalItems > 0) {
      dispatch(searchAgents(searchParams))
        .unwrap()
        .then((res) => {
          const convertSearchResults = res?.data?.responses?.map((agent: IDataAgent) => {
            return { ...agent, value: agent?.id, label: agent?.name + ' ' + agent?.surname };
          });
          setSearchResults(convertSearchResults);
        })
        .catch((error) => {
          console.log(`[Agents] handleSearchAgent: ${JSON.stringify(error, null, 2)}`);
        });
    }
  };

  const handleDeleteAgent = (id: number | string) => {
    const currentPage = pageSelected;
    loadingContext?.show();
    dispatch(deleteAgent({ accountId: Number(id) }))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        if (responses.length === 1 && currentPage > 1) {
          // navigate first page if delete last item
          setSearchParams({ ...params, page: FIRST_PAGE.toString() });
          setParamObject({ ...params, page: FIRST_PAGE });
        } else {
          handleGetListAgent(params);
        }
        message.success(t('agent_delete_message_success'));
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  const handleNavigateEditAgent = (id: number) => {
    navigate(`${ROUTER_PATHS.AGENTS_EDIT}/${id}`);
  };

  const handleNavigateAddAgent = () => {
    navigate(ROUTER_PATHS.AGENTS_ADD);
  };

  const handleChangePagination = (page: number, pageSize: number) => {
    const newParamObject = { ...paramObject, page, limit: pageSize };
    setSearchParams({ ...params, page: page.toString(), limit: pageSize.toString() });

    handleCommonChange(newParamObject, page, pageSize);
  };

  return (
    <div id='agents' className={cx('agentsContainer')}>
      <Row justify='space-between' gutter={{ xs: 8, sm: 16, md: 24 }} className={cx('titleTableAgent')}>
        <Col xs={24} sm={24} md={12}>
          <Title className={cx('agentsTitle')} level={TITLE_LEVEL}>
            {t('agent_list_title')}
          </Title>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Flex gap={8} align='center' justify='end'>
            <Tooltip title={t('agents_list_search')}>
              <AutoComplete className={cx('autoCompleteAgentsSearch')} onSearch={debounce(handleSearchAgent, 800)} onSelect={handleSelectAgent} options={searchResults}>
                <Search className={cx('agentsSearch')} placeholder={t('agent_list_search_placeholder')} allowClear enterButton />
              </AutoComplete>
            </Tooltip>
            <Tooltip title={t('agent_add_button')} placement='top'>
              <Button onClick={handleNavigateAddAgent} type='primary'>
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Flex>
        </Col>
      </Row>
      {!loadingGetList && (
        <>
          <Table
            className={cx('agentsTableContent')}
            onChange={handleChangeTable}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={responses}
            scroll={{ x: SCROLL_X_TABLE, y: Y_SCROLL }}
            pagination={false}
            locale={{
              triggerDesc: t('table_asc_order_sort_tooltip'),
              triggerAsc: t('table_desc_order_sort_tooltip'),
              cancelSort: t('table_cancel_order_sort_tooltip'),
              emptyText: <EmptyTable />,
            }}
          />

          {pagination?.totalItems > 0 && (
            <div className={cx('tablePaginationContainer')}>
              <Pagination
                total={pagination?.totalItems}
                pageSize={Number(pagination?.limit) || LIMIT_DEFAULT}
                current={Number(pagination?.page)}
                showSizeChanger
                responsive
                onChange={handleChangePagination}
                locale={{ items_per_page: t('table_pagination_text_page') }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Agents;
