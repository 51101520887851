// Libs
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Layout, Row, message, Typography, Space } from 'antd';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Shares
import useBreakpoint from '~/hooks/useBreakPoint';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingContext } from '~/contexts/LoadingContext';
import { getDetailAgent, updateAgent } from '~/thunks/agent/agentThunk';
import { selectDetailAgent } from '~/thunks/agent/detailAgentSlice';
import styles from './EditAgent.module.scss';
// Utils
import { CHARACTER_MIN_PASSWORD, TITLE_LEVEL } from '~/utils/constants';
import { getErrorMessageByCode, useLanguageValidation } from '~/utils/helper';
// Interface
import { IEditAgent } from './editAgent.interface';
import { IParamDetailAgent } from '~/data/account';

const cx = classNames.bind(styles);
const { Title } = Typography;

const EditAgent = () => {
  const [loading, setLoading] = useState(true);
  const [isChangedForm, setIsChangedForm] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);
  const [form] = Form.useForm();
  const { id: accountId } = useParams();
  const { t } = useTranslation();
  const { md } = useBreakpoint();

  const agentDetail = useAppSelector(selectDetailAgent);

  useLanguageValidation(form);

  useEffect(() => {
    if (accountId) {
      handleGetAccountDetail({ accountId });
      setLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    form.setFieldsValue(loading ? { email: '', name: '', surname: '' } : { email: agentDetail.email, name: agentDetail.name, surname: agentDetail.surname });
  }, [agentDetail]);

  const handleGetAccountDetail = (param: IParamDetailAgent) => {
    loadingContext?.show();
    dispatch(getDetailAgent({ accountId: param.accountId }))
      .unwrap()
      .then((response) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleSubmitEditAgent = (values: IEditAgent) => {
    const dataInput = values;
    if (dataInput?.password?.trim() === '') {
      delete dataInput.password;
      form.setFieldsValue({ password: '' });
    }
    loadingContext?.show();
    dispatch(updateAgent({ accountId: Number(accountId), ...dataInput }))
      .unwrap()
      .then((res) => {
        setIsChangedForm(false);
        form.setFieldsValue({ password: '' });
        loadingContext?.hide();
        message.success(t('agent_edit_message_success'));
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  const handleFormChange = () => {
    setIsChangedForm(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Layout id='editAgent' className={cx('mainLayout')}>
      <div className={cx('editAgentContainer')}>
        <Title level={TITLE_LEVEL}>{t('agent_edit_button')}</Title>
        <Form layout='vertical' size='large' form={form} onFinish={handleSubmitEditAgent} onValuesChange={handleFormChange}>
          <Row gutter={{ sm: 8, md: 12, lg: 16 }} className={cx('contentRow')}>
            <Col span={md ? 24 : 12}>
              <Row gutter={{ sm: 8, md: 12, lg: 16 }}>
                <Col span={md ? 24 : 12}>
                  <Form.Item<IEditAgent> name='name' label={t('common_agent_name')} rules={[{ required: true, message: t('common_agent_name_require'), whitespace: true }]}>
                    <Input placeholder={t('common_agent_name_placeholder')} allowClear />
                  </Form.Item>
                </Col>
                <Col span={md ? 24 : 12}>
                  <Form.Item<IEditAgent>
                    name='surname'
                    label={t('common_agent_surname')}
                    rules={[{ required: true, message: t('common_agent_surname_require'), whitespace: true }]}
                  >
                    <Input placeholder={t('common_agent_surname_placeholder')} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item<IEditAgent>
                name='email'
                label={t('common_agent_email_placeholder')}
                rules={[
                  { required: true, message: t('common_email_require'), whitespace: true },
                  {
                    type: 'email',
                    message: t('common_email_invalid'),
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item<IEditAgent>
                name='password'
                label={t('common_agent_password_placeholder')}
                rules={[
                  {
                    min: CHARACTER_MIN_PASSWORD,
                    message: t('common_length_password'),
                  },
                ]}
              >
                <Input.Password allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space className={cx('editAgentAction')}>
              <Button type='primary' size='large' onClick={goBack}>
                {t('common_button_back')}
              </Button>
              <Button htmlType='submit' type='primary'>
                {t('agent_edit_button')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default EditAgent;
