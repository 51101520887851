export const LOGIN = 'LOGIN';
export const GET_PROFILE = 'profile/get';
export const CHANGE_PASSWORD = 'profile/changePassword';
export const CHANGE_EMAIL = 'profile/changeEmail';
export const CREATE_AGENT = 'agents/create';
export const GET_CATEGORIES = 'categories/get';
export const SEARCH_CATEGORIES = 'categories/search';
export const GET_SUB_CATEGORIES_BY_CATEGORY = 'subCategories/get';
export const SEARCH_SUB_CATEGORIES_BY_CATEGORY = 'subCategories/search';
export const GET_LIST_AGENT = 'agents/getList';
export const SEARCH_LIST_AGENT = 'agents/search';
export const DELETE_AGENT = 'agents/delete';
export const GET_DETAIL_AGENT = 'agents/getDetail';
export const UPDATE_AGENT = 'agents/update';
export const GET_CLIENTS = 'clients/get';
export const SEARCH_CLIENTS = 'clients/search';
export const UPDATE_DATABASE = 'database/update';
export const GET_CLIENT_DETAIL = 'client/detail';
export const ADD_CLIENT_DETAIL_ADMIN_NOTE = 'clients/note';
export const GET_PRODUCTS_BY_SUB_CATEGORY = 'products/get';
export const SEARCH_PRODUCTS_BY_NAME = 'product/searchProducts';
export const GET_PAYMENTS = 'payments/get';
export const ADD_PRODUCT_TO_CART = 'cart/addProduct';
export const UPDATE_PRODUCT_IN_CART = 'cart/updateProduct';
export const GET_PRODUCTS_IN_CART = 'cart/getProducts';
export const DELETE_PRODUCT_IN_CART = 'cart/deleteProduct';
export const EMPTY_CART = 'cart/deleteProduct';
export const GET_ORDERS = 'orders/get';
export const DELETE_ORDER = 'orders/delete';
export const SEARCH_ORDERS = 'orders/search';
export const CREATE_ORDER = 'orders/create';
export const GET_DETAIL_ORDER = 'orders/detail';
export const SEND_PDF_ORDER_EMAIL = 'orders/sendEmail';
