import classNames from 'classnames/bind';
// Libs
import { useEffect, useState } from 'react';
import { Button, Form, Input, Layout } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Thunks
import { authLoading } from '~/thunks/auth/authSlice';
// Scss
import styles from './ResetPassword.module.scss';
// Shares utils
import { imgBannerLogin } from '~/utils/image.constants';
import { CHARACTER_MIN_PASSWORD } from '~/utils/constants';
// Shares interface
import { IResetPassword } from './ResetPassword.interface';

const cx = classNames.bind(styles);

const ResetPassword = () => {
  const loading = useSelector(authLoading);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleResetPassword = (values: IResetPassword) => {};

  return (
    <Layout id='resetPassword' className={cx('mainLayout')}>
      <div className={cx('layoutResetTop')}>
        <img className={cx('imgBanner')} src={imgBannerLogin} alt='Login Banner' />
      </div>
      <div className={cx('resetForm')}>
        <Form name='basic' form={form} onFinish={handleResetPassword} layout='vertical' size='large' autoComplete='off'>
          <Form.Item<IResetPassword>
            name='newPassword'
            label={t('reset_password_label')}
            rules={[
              { required: true, message: t('login_require_password_message') },
              {
                min: CHARACTER_MIN_PASSWORD,
                message: t('login_length_password_message'),
              },
            ]}
          >
            <Input placeholder={t('reset_password_placeholder')} />
          </Form.Item>
          <Form.Item<IResetPassword>
            name='confirmNewPassword'
            label={t('confirm_password_label')}
            rules={[
              {
                required: true,
                message: t('confirm_password_require_message'),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('confirm_password_match_message'));
                },
              }),
            ]}
          >
            <Input placeholder={t('confirm_password_placeholder')} />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type='primary' htmlType='submit' className={cx('btnReset')}>
              {t('reset_password_button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default ResetPassword;
