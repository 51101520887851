import { createSlice } from '@reduxjs/toolkit';
import { updateDatabase } from './updateDatabaseThunk';

interface IUpdateDatabaseState {
  loading: boolean;
  lineTextErrors: [];
}

const initialState: IUpdateDatabaseState = {
  loading: false,
  lineTextErrors: [],
};

const updateDatabaseSlice = createSlice({
  initialState,
  name: 'updateDatabase',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateDatabase.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateDatabase.fulfilled, (state, action) => {
        state.loading = false;
        state.lineTextErrors = action.payload?.data.lineTextErrors;
      })
      .addCase(updateDatabase.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const updateDatabaseActions = updateDatabaseSlice.actions;

export const selectListErrors = (state: any) => state.updateDatabase.lineTextErrors;

const updateDatabaseReducer = updateDatabaseSlice.reducer;
export default updateDatabaseReducer;
