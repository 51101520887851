import { createSlice } from '@reduxjs/toolkit';
import { getProfile } from './profileThunk';

export interface ProfileState {
  profile: {};
  loading: boolean;
}

const initialState: ProfileState = {
  profile: {},
  loading: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload.data;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const profileActions = profileSlice.actions;

export const selectProfile = (state: any) => state.profile.profile;
export const selectLoading = (state: any) => state.profile.loading;

const profileReducer = profileSlice.reducer;
export default profileReducer;
