// Libs
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import { debounce } from 'lodash';
// Ant design
import { Button, Form, InputNumber, Table, Flex, Typography, AutoComplete, Input, Tooltip } from 'antd';
import { PlusOutlined, CheckOutlined, EditFilled } from '@ant-design/icons';
import type { FormInstance } from 'antd/es/form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// i18next
import { useTranslation } from 'react-i18next';
// Interface
import { EditableCellProductProps, EditableRowProps, IProduct } from './ClientDetailsProducts.interface';
import { ISearchResultProduct } from '../../Clients.interface';
// Components
import CartTable from '~/components/specific/cartTable/CartTable';
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';
import { IParamGetProductsBySubCategoryId } from '~/data/product';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Styles
import styles from './ClientDetailsProducts.module.scss';
import './ClientDetailsProducts.css';
// Thunk
import { getProductsBySubCategory, searchProductsByName } from '~/thunks/product/productThunk';
import { createAndUpdateCart, getProductsInCart } from '~/thunks/cart/cartThunk';
import { selectLoadingProducts } from '~/thunks/product/listProductSlice';
// Utils
import {
  DEFAULT_MAX_VALUE_DISCOUNT,
  DEFAULT_MIN_VALUE_DISCOUNT,
  DEFAULT_MIN_VALUE_QUANTITY,
  HIGHLIGHT_PRODUCT,
  MAX_DISCOUNT,
  MIN_X_SCROLL,
  PAGE_DEFAULT,
  PLUS_ICON_COLOR,
  ROUTER_PATHS,
  SEARCH_RESULT_TYPE,
  TITLE_LEVEL,
  UNICODE_EURO,
} from '~/utils/constants';
// Interface
import { IPayloadCreateAndUpdateCart } from '~/data/cart';
import { isDivisible, isValidNumberInRange } from '~/utils/helper';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const cx = classNames.bind(styles);

const { Title } = Typography;

interface OptionType {
  id: string;
  label: string;
  productId: string;
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const EditableCell: React.FC<EditableCellProductProps> = (props) => {
  const { title, editable, children, checkIcon, handleAddCart, dataIndex, record, handleSaveProduct, ...restProps } = props;
  const [editing, setEditing] = useState(false);
  const [changeLogic, setChangeLogic] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;
  const { t } = useTranslation();

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (!editing && !changeLogic) {
      form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
    setEditing(!editing);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      if (values.discount == 100) {
        values.discount = MAX_DISCOUNT;
      }

      toggleEdit();
      handleSaveProduct({ ...record, ...values });
      inputRef.current!.focus();
      setChangeLogic(false);
    } catch (errInfo) {
      toggleEdit();
      console.log('Save failed:', errInfo);
    }
  };

  const handleFocusInput = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSaveProduct({ ...record, ...values });
      setChangeLogic((prev) => !prev);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleFocusToEdit = async () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        className={cx('clientDetailProductsEditCell')}
        normalize={(value) => {
          if (dataIndex === 'discount') {
            if (value.charAt(0) === '0') {
              value = value.slice(1);
            }

            if (value.toUpperCase() === MAX_DISCOUNT) {
              return value.toUpperCase();
            }

            if (isValidNumberInRange(value)) {
              return value;
            }

            const valuePrevious = value.slice(0, -1);
            if (isValidNumberInRange(valuePrevious) || valuePrevious === MAX_DISCOUNT) {
              return valuePrevious;
            }

            return '0';
          } else {
            return value || record.minQuantity;
          }
        }}
        name={dataIndex as keyof IProduct}
        rules={[
          {
            message: t('cartTable_change_quantity_failed_minimum'),
            validator: (rule: any, value) => {
              if (rule?.field === 'discount' || changeLogic || isDivisible(value, record.minQuantity)) {
                return Promise.resolve();
              } else {
                return Promise.reject();
              }
            },
          },
          {
            pattern: /^[0-9S]*$/,
            message: t('clients_clientDetails_products_subCategory_discount_validate_fail'),
          },
        ]}
      >
        {dataIndex === 'discount' ? (
          <Input
            min={DEFAULT_MIN_VALUE_DISCOUNT}
            max={DEFAULT_MAX_VALUE_DISCOUNT}
            className={'editTableCellInputNumber'}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onFocus={handleFocusToEdit}
          />
        ) : (
          <InputNumber min={DEFAULT_MIN_VALUE_QUANTITY} className={'editTableCellInputNumber'} ref={inputRef} onPressEnter={save} onBlur={save} onFocus={handleFocusToEdit} />
        )}
      </Form.Item>
    ) : (
      <Flex justify='space-between'>
        {dataIndex === 'quantity' && (
          <Tooltip title={t('clients_clientDetails_tooltip_change_quantity')} placement='left'>
            <Button className={cx('tableIconEdit')} type='text' shape='circle' icon={<EditFilled />} onClick={handleFocusInput} />
          </Tooltip>
        )}
        <div className={cx('editableCellValueWrap')} onClick={toggleEdit}>
          {children}
        </div>
      </Flex>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const ClientDetailsProducts = () => {
  const { subCategoryId, clientId } = useParams();
  const [searchOptionSelected, setOptionSelected] = useState<string>('');
  const [searchResult, setSearchResult] = useState<ISearchResultProduct[]>([]);

  const [dataProducts, setDataProducts] = useState<IProduct[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [pageSelected, setPageSelected] = useState<number>(PAGE_DEFAULT);
  const [tempDataProduct, setTempDataProduct] = useState([] as any[]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);
  const { Search } = Input;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get(HIGHLIGHT_PRODUCT);

  const loadingProduct = useAppSelector(selectLoadingProducts);

  useEffect(() => {
    if (subCategoryId && clientId) {
      getListProductsBySubCategory(PAGE_DEFAULT);
    }
  }, [subCategoryId, productId]);

  const handleLoadMoreSubCategories = () => {
    getListProductsBySubCategory(pageSelected + 1);
    setPageSelected(pageSelected + 1);
  };

  const goBack = () => {
    navigate(-1);
  };

  const getListProductsBySubCategory = (pageIndex: number) => {
    loadingContext?.show();
    dispatch(getProductsBySubCategory({ subCategoryId: subCategoryId!, clientId: clientId!!, page: pageIndex }))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        setTotalPage(res?.data?.pagination?.totalPages);

        const newDataProducts = res?.data?.responses?.map((product: IProduct) => {
          const isHighlighted = product.id === productId;

          return {
            ...product,
            quantity: product.minQuantity,
            discount: DEFAULT_MIN_VALUE_DISCOUNT,
            isHighlighted,
          };
        });

        if (pageIndex > 1) {
          setDataProducts((productsPrevious: IProduct[]) => productsPrevious.concat(newDataProducts));
        } else {
          setDataProducts(newDataProducts);
        }
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const createAndUpdateCartClient = (params: IPayloadCreateAndUpdateCart) => {
    loadingContext?.show();
    dispatch(createAndUpdateCart(params))
      .unwrap()
      .then((res) => {
        dispatch(getProductsInCart({ clientId: clientId! }));
      })
      .then((res) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleAddProductToCart = (record: any) => {
    setTempDataProduct((prev) => [
      ...prev,
      {
        productId: Number(record.id),
        quantity: record.quantity,
        discount: record.discount,
      },
    ]);

    setDataProducts((prevData) =>
      prevData.map((item) =>
        item.id === record.id
          ? {
              ...item,
              checkIcon: true,
            }
          : item
      )
    );
  };

  const handleRemoveProductFromCart = (record: any) => {
    setTempDataProduct((prev) => prev.filter((item) => +item.productId !== +record.id));

    setDataProducts((prevData) =>
      prevData.map((item) =>
        item.id === record.id
          ? {
              ...item,
              checkIcon: false,
            }
          : item
      )
    );
  };

  const handleAddCart = () => {
    const data = {
      products: tempDataProduct,
      clientId: Number(clientId!),
    };
    createAndUpdateCartClient(data);
    setTempDataProduct([]);
    const dataProductsReset = dataProducts.map((item) => ({
      ...item,
      checkIcon: false,
      quantity: item.minQuantity,
      discount: DEFAULT_MIN_VALUE_DISCOUNT,
    }));

    setDataProducts(dataProductsReset);
  };

  useEffect(() => {}, [tempDataProduct]);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: t('clients_clientDetails_products_table_id'),
      dataIndex: 'code',
      render: (text, record) => {
        return {
          props: {
            style: record.isHighlighted ? { backgroundColor: '#fafad1' } : {},
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: t('clients_clientDetails_products_table_productName'),
      dataIndex: 'name',
      render: (text, record) => {
        return {
          props: {
            style: record.isHighlighted ? { backgroundColor: '#fafad1' } : {},
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: `${t('clients_clientDetails_products_table_price')} (${UNICODE_EURO})`,
      dataIndex: 'price',
      render: (text, record) => {
        return {
          props: {
            style: record.isHighlighted ? { backgroundColor: '#fafad1' } : {},
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: t('clients_clientDetails_products_table_quantity'),
      dataIndex: 'quantity',
      width: 220,
      editable: true,
      render: (text, record) => {
        return {
          props: {
            style: record.isHighlighted ? { backgroundColor: '#fafad1' } : {},
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: t('clients_clientDetails_products_table_discount'),
      dataIndex: 'discount',
      width: 220,
      editable: true,
      render: (text, record) => {
        return {
          props: {
            style: record.isHighlighted ? { backgroundColor: '#fafad1' } : {},
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: `${t('clients_clientDetails_products_table_total')} (${UNICODE_EURO})`,
      dataIndex: 'total',
      render: (_, record) => {
        const price = record.price || 0;
        const quantity = record.quantity || 1;
        const discountPercentage = record.discount || 0;

        if (discountPercentage === MAX_DISCOUNT) {
          return (0).toFixed(2);
        }

        const priceNotPercent = price * (100 - discountPercentage) * quantity;
        return {
          props: {
            style: record.isHighlighted ? { backgroundColor: '#fafad1' } : {},
          },
          children: <div>{(Math.round(priceNotPercent) / 100).toFixed(2)}</div>,
        };
      },
    },
    {
      title: t('clients_clientDetails_products_table_actions'),
      dataIndex: 'actions',
      width: 110,
      align: 'center',
      render: (text, record) => {
        return {
          props: {
            style: record.isHighlighted ? { backgroundColor: '#fafad1' } : {},
          },
          children: record.checkIcon ? (
            <Button
              className={cx('clientDetailsCartTableActionAdd')}
              onClick={() => {
                handleRemoveProductFromCart(record);
              }}
              type='text'
              shape='circle'
              icon={<CheckOutlined />}
            />
          ) : (
            <Button
              onClick={() => {
                handleAddProductToCart(record);
              }}
              className={cx('clientDetailsCartTableActionAdd')}
              type='text'
              shape='circle'
              icon={<PlusOutlined color={PLUS_ICON_COLOR} />}
            />
          ),
        };
      },
    },
  ];

  const handleSaveProduct = (row: IProduct) => {
    const isProductInTempData = tempDataProduct.some((item) => item.productId === Number(row.id));
    const isValidProduct = row.quantity >= 1 || row.discount >= 0;

    if (isValidProduct) {
      const newProducts = dataProducts.map((item) => ({
        ...item,
        quantity: item.id === row.id ? row.quantity : item.quantity,
        discount: item.id === row.id ? row.discount : item.discount,
        checkIcon: item.id === row.id ? isValidProduct : item.checkIcon,
      }));

      if (newProducts.some((item) => item.quantity >= 1 || item.discount >= 0)) {
        setDataProducts(newProducts);
      }

      setTempDataProduct((prev) => {
        if (isProductInTempData) {
          return prev.map((item) =>
            item.productId === Number(row.id)
              ? {
                  ...item,
                  quantity: row.quantity,
                  discount: row.discount,
                }
              : item
          );
        } else {
          return [...prev, { productId: Number(row.id), quantity: row.quantity, discount: row.discount }];
        }
      });
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IProduct) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveProduct,
      }),
    };
  });

  const handleSearchProducts = (value: string) => {
    if (value === '') {
      setSearchResult([]);
      return;
    }

    const payload = {
      searchKey: value,
      clientId: clientId!,
      type: SEARCH_RESULT_TYPE.PRODUCT,
    };

    dispatch(searchProductsByName(payload))
      .unwrap()
      .then((res) => {
        const convertSearchResult = res?.data.map((product: ISearchResultProduct, index: number) => {
          return { ...product, value: index, label: `${product?.code} ${product?.name}` };
        });
        setSearchResult(convertSearchResult);
      })
      .catch((error) => {});
  };

  const debounceSearchProducts = debounce(handleSearchProducts, 400);

  const handleSelectedSearchResult = (_: string, option: OptionType) => {
    const { id, productId, label } = option;
    const queryString = `?productId=${productId}`;

    setOptionSelected(label);
    setPageSelected(PAGE_DEFAULT);
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.PRODUCTS}/${id}${queryString}`);
  };

  const handleSearchChange = (value: string) => {
    setOptionSelected(value);
  };

  const handleBackToTop = () => {
    navigate(`${ROUTER_PATHS.CLIENT_DETAILS}/${clientId}${ROUTER_PATHS.CATEGORIES}`);
  };

  return (
    <>
      <div className={cx('clientDetailsProducts')}>
        <Flex className={cx('clientDetailsProductsHeader')} align='center' justify='space-between'>
          <Title level={TITLE_LEVEL}>{t('clients_clientDetails_products_title')}</Title>

          <AutoComplete
            className={cx('autoCompleteClientDetailsProductsSearch')}
            value={searchOptionSelected}
            onSearch={debounceSearchProducts}
            onSelect={handleSelectedSearchResult}
            options={searchResult}
            onChange={handleSearchChange}
          >
            <Search size='middle' className={cx('clientDetailsProductsSearch')} enterButton placeholder={t('clients_clientDetails_products_search_placeholder')} allowClear />
          </AutoComplete>
        </Flex>
        {!loadingProduct && (
          <Table
            rowKey={(record) => record.id}
            size='large'
            components={components}
            rowClassName={() => 'editable-row'}
            dataSource={dataProducts}
            columns={columns as ColumnTypes}
            pagination={false}
            scroll={{ x: MIN_X_SCROLL }}
            locale={{
              emptyText: <EmptyTable />,
            }}
          />
        )}
        <Flex className={cx('clientDetailsProductsLoadingMore')} align='center' justify='space-between'>
          <Flex gap={10}>
            <Button type='primary' size='large' onClick={goBack}>
              {t('common_button_back')}
            </Button>

            <Button type='primary' size='large' onClick={handleBackToTop}>
              {t('common_button_back_to_top')}
            </Button>
          </Flex>

          {pageSelected < totalPage && (
            <Button onClick={handleLoadMoreSubCategories} size='large' type='primary'>
              {t('common_button_loadingMore')}
            </Button>
          )}

          <Button type='primary' size='large' className={cx('clientDetailProductsBtnHidden')}>
            {t('common_button_back')}
          </Button>

          {tempDataProduct.length > 0 && (
            <Button size='large' className={cx('bgSuccess')} onClick={handleAddCart}>
              {t('common_button_add_cart')}
            </Button>
          )}
        </Flex>
      </div>

      <CartTable handleGoBack={goBack} isShowBackToTop={true} />
    </>
  );
};
export default ClientDetailsProducts;
