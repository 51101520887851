import { createSlice } from '@reduxjs/toolkit';
import { searchProductsByName } from './productThunk';

interface ISearchProductsState {
  subCategoryId: number | null;
  loading: boolean;
}

const initialState: ISearchProductsState = {
  subCategoryId: null,
  loading: false,
};

const searchProductsSlice = createSlice({
  initialState,
  name: 'product/searchProduct',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(searchProductsByName.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchProductsByName.fulfilled, (state, action) => {
        state.loading = false;
        state.subCategoryId = action?.payload?.data;
      })
      .addCase(searchProductsByName.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectSubCategoryId = (state: any) => state.searchProduct.subCategoryId;
export const searchProductsActions = searchProductsSlice.actions;

const searchProductsReducer = searchProductsSlice.reducer;
export default searchProductsReducer;
