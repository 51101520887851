import classNames from 'classnames/bind';
// Libs react
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
// Libs antd
import { Button, Col, Flex, Form, Input, Layout, Popconfirm, Row, Table, Tooltip, Typography, message } from 'antd';
import { DeleteOutlined, FilePptOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import type { ColumnsType } from 'antd/es/table';
// I18next
import { useTranslation } from 'react-i18next';
// Hooks
import useBreakpoint from '~/hooks/useBreakPoint';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Shares
import { DocumentPDF } from '~/components/documentPDF/DocumentPdf';
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';
import FooterTable from '~/components/specific/footerTable/FooterTable';
import { LoadingContext } from '~/contexts/LoadingContext';
import styles from './OrderDetail.module.scss';
// Utils
import { formatPrice, getErrorMessageByCode, splitDateTime } from '~/utils/helper';
import { MAX_DISCOUNT, ROUTER_PATHS, SCROLL_X_TABLE, TITLE_LEVEL, UNICODE_EURO } from '~/utils/constants';
// Thunk
import { deleteOrder, detailOrder } from '~/thunks/order/orderThunk';
import { selectDetailOrder } from '~/thunks/order/detailOrderSlice';
// Interface
import { IOrderDetail } from './OrderDetail.interface';
import { IPayloadGetDetailOrder } from '../Orders.interface';

const cx = classNames.bind(styles);
const { TextArea } = Input;
const { Title } = Typography;

const OrderDetail = () => {
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const { sm, md } = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const loadingContext = useContext(LoadingContext);
  const { id: orderId } = useParams();

  const dataOrder = useAppSelector(selectDetailOrder);
  const { detailOrderProduct } = dataOrder;

  const order = dataOrder?.detailOrder?.order;
  const { dateString, timeString } = splitDateTime(order?.createdAt);

  const dateTimePDF = {
    orderId,
    dateString,
    timeString,
  };

  const columns: ColumnsType<IOrderDetail> = [
    {
      title: t('orders_orderDetail_cart_table_id'),
      dataIndex: 'productCode',
    },
    {
      title: t('orders_orderDetail_cart_table_productName'),
      dataIndex: 'productName',
    },
    {
      title: t(`${t('orders_orderDetail_cart_table_price')} (${UNICODE_EURO})`),
      dataIndex: 'priceProduct',
    },
    {
      title: t('orders_orderDetail_cart_table_discount'),
      dataIndex: 'discount',
      render: (_, record) => {
        if (+record.discount === 100) {
          return MAX_DISCOUNT;
        }
        return <>{record.discount}</>;
      },
    },
    {
      title: t('orders_orderDetail_cart_table_quantity'),
      dataIndex: 'quantity',
    },
    {
      title: t(`${t('orders_orderDetail_cart_table_total')} (${UNICODE_EURO})`),
      dataIndex: 'totalPrice',
    },
  ];

  let dataPrintPdf;

  if (dataOrder) {
    dataPrintPdf = {
      ...dataOrder.detailOrder?.client,
      total: dataOrder.detailOrder?.order?.total,
    };
  }

  useEffect(() => {
    if (orderId) {
      handleGetDetailOrder({ orderId });
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    form.setFieldsValue(
      loading
        ? {
            clientCode: '',
            listN: '',
            clientName: '',
            clientAddress: '',
            cap: '',
            city: '',
            province: '',
            codiceFiscale: '',
            paymentType: '',
            note: '',
            numberVAT: '',
            email: '',
            phone: '',
          }
        : {
            clientCode: dataOrder?.detailOrder?.client?.clientCode,
            clientName: dataOrder?.detailOrder?.client?.name,
            clientAddress: dataOrder?.detailOrder?.client?.address,
            cap: dataOrder?.detailOrder?.client?.zipCode,
            city: dataOrder?.detailOrder?.client?.city,
            province: dataOrder?.detailOrder?.client?.province,
            numberVAT: dataOrder?.detailOrder?.client?.vatNumber,
            paymentType: dataOrder?.detailOrder?.client?.paymentName,
            listN: dataOrder?.detailOrder?.client?.listingCode,
            codiceFiscale: dataOrder?.detailOrder?.client?.healthCode,
            note: dataOrder?.detailOrder?.order?.note,
            email: dataOrder?.detailOrder?.client?.email,
            phone: dataOrder?.detailOrder?.client?.phone,
          }
    );
  }, [dataOrder]);

  const handleGetDetailOrder = (param: IPayloadGetDetailOrder) => {
    loadingContext?.show();
    dispatch(detailOrder({ orderId: param.orderId }))
      .unwrap()
      .then((response: any) => {
        loadingContext?.hide();
      })
      .catch((error: any) => {
        loadingContext?.hide();
      });
  };

  const handleNavigateOrderList = () => {
    navigate(ROUTER_PATHS.ORDER);
  };

  const handleDeleteOrder = () => {
    loadingContext?.show();
    dispatch(deleteOrder({ orderId: Number(orderId) }))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        message.success(t('order_delete_message_success'));
        handleNavigateOrderList();
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  return (
    <Layout id='detailOrder' className={cx('mainLayout')}>
      <div className={cx('detailOrdersContainer')}>
        <div className={cx('orderDetailInfo')}>
          <Title level={TITLE_LEVEL}>
            {t('orders_details_title', {
              orderId,
              dateString,
              timeString,
            })}
          </Title>
          <Form layout='vertical' size='large' form={form}>
            <Row gutter={{ sm: 8, md: 12, lg: 16 }} className={cx('formRow')}>
              <Col span={md ? 24 : 12}>
                {!sm && (
                  <Form.Item className={cx('orderDetailsFormItemContainer')}>
                    <Form.Item name='clientCode' label={t('orders_details_clientCode')} className={cx('orderDetailCalcWidth')}>
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item name='listN' label={t('orders_details_listN')} className={cx('orderDetailCalcWidthMargin')}>
                      <Input readOnly />
                    </Form.Item>
                  </Form.Item>
                )}

                {sm && (
                  <Form.Item name='clientCode' label={t('orders_details_clientCode')} className={cx('orderDetailFormItemFullWidth')}>
                    <Input readOnly />
                  </Form.Item>
                )}

                {sm && (
                  <Form.Item name='listN' label={t('orders_details_listN')} className={cx('orderDetailFormItemFullWidth')}>
                    <Input readOnly />
                  </Form.Item>
                )}

                <Form.Item name='clientName' label={t('orders_details_clientName')}>
                  <Input readOnly />
                </Form.Item>

                {md && (
                  <Form.Item name='clientAddress' label={t('orders_details_clientAddress')}>
                    <Input readOnly />
                  </Form.Item>
                )}

                {md && (
                  <Form.Item className={cx('orderDetailsFormItemContainer')}>
                    <Row gutter={{ sm: 8 }}>
                      <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Form.Item name='cap' label={t('orders_details_zipCode')} className={cx('orderDetailFormItemFullWidth')}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Form.Item name='city' label={t('orders_details_city')} className={cx('orderDetailFormItemFullWidth')}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Form.Item name='province' label={t('orders_details_province')} className={cx('orderDetailFormItemFullWidth')}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                )}

                <Form.Item name='email' label={t('clients_clientDetails_newOrder_email')}>
                  <Input readOnly />
                </Form.Item>

                <Form.Item name='phone' label={t('clients_clientDetails_newOrder_phone')}>
                  <Input readOnly />
                </Form.Item>

                {md && (
                  <Form.Item name='codiceFiscale' label={t('orders_details_codiceFiscale')}>
                    <Input readOnly />
                  </Form.Item>
                )}

                <Form.Item name='paymentType' label={t('orders_details_paymentType')}>
                  <Input className={cx('formOrderDetailSelect')} placeholder={t('orders_details_paymentType_placeholder')} readOnly></Input>
                </Form.Item>

                {md && (
                  <Form.Item name='note' label={t('orders_details_orderNote')}>
                    <TextArea className={cx('formOrderDetailTextArea')} style={{ resize: 'none' }} readOnly />
                  </Form.Item>
                )}
              </Col>

              {!md && (
                <Col span={12}>
                  <Form.Item name='clientAddress' label={t('orders_details_clientAddress')}>
                    <Input readOnly />
                  </Form.Item>

                  <Form.Item className={cx('orderDetailsFormItemContainer')}>
                    <Row gutter={{ sm: 8 }}>
                      <Col span={8}>
                        <Form.Item name='cap' label={t('orders_details_zipCode')} className={cx('orderDetailFormItemFullWidth')}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name='city' label={t('orders_details_city')} className={cx('orderDetailFormItemFullWidth')}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name='province' label={t('orders_details_province')} className={cx('orderDetailFormItemFullWidth')}>
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item name='numberVAT' label={t('orders_details_vatNumber')}>
                    <Input readOnly />
                  </Form.Item>

                  <Form.Item name='codiceFiscale' label={t('orders_details_codiceFiscale')}>
                    <Input readOnly />
                  </Form.Item>

                  <Form.Item name='note' label={t('orders_details_orderNote')}>
                    <TextArea className={cx('formOrderDetailTextArea')} style={{ resize: 'none' }} readOnly />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </div>

        <div className={cx('orderDetailCart')}>
          <Title level={TITLE_LEVEL}>{t('orders_details_cart_title')}</Title>
          <Table
            dataSource={detailOrderProduct}
            rowKey={(record) => record.id}
            columns={columns}
            pagination={false}
            footer={() => <FooterTable total={formatPrice(dataOrder?.detailOrder?.order?.total)} />}
            scroll={{ x: SCROLL_X_TABLE }}
            locale={{
              emptyText: <EmptyTable />,
            }}
          />
        </div>
      </div>
      <div className={cx('buttonFooter')}>
        <Flex justify='space-between' gap={10} align='middle' wrap='wrap'>
          <Popconfirm
            title={t('orders_details_button_delete')}
            description={t('orders_details_popup_delete_confirm')}
            onConfirm={() => handleDeleteOrder()}
            okText={t('orders_details_button_confirm')}
            okType='danger'
            placement='topRight'
            cancelText={t('orders_details_button_cancel')}
          >
            <Tooltip title={t('table_action_button_delete')} placement='left'>
              <Button size='large' danger type='primary' icon={<DeleteOutlined />}>
                {t('orders_details_button_delete')}
              </Button>
            </Tooltip>
          </Popconfirm>

          <PDFDownloadLink document={<DocumentPDF data={dataPrintPdf} dataTable={detailOrderProduct} dateTimePDF={dateTimePDF} />}>
            <Button size='large' type='primary' icon={<FilePptOutlined />}>
              {t('orders_details_button_print')}
            </Button>
          </PDFDownloadLink>
        </Flex>
      </div>
    </Layout>
  );
};

export default OrderDetail;
