import { URL_API_AGENT } from '~/utils/constants';
import { removeLastCharacter } from '~/utils/helper';
import axiosClient from './axiosClient';
import { IParamAccountAgent, IPayloadDeleteAgent, IPayloadUpdateAgent, IParamDetailAgent } from '~/data/account';

export const agentApi = {
  createAgent: (payload: any) => {
    const url = URL_API_AGENT.CREATE_AGENT;
    return axiosClient.post(url, payload);
  },

  getListAgent(params: IParamAccountAgent) {
    let url = `${URL_API_AGENT.GET_LIST_AGENT}?`;

    if (params?.sort) {
      url += `sort=${params.sort}&`;
    }
    if (params?.order) {
      url += `order=${params.order}&`;
    }
    if (params?.page && params?.limit) {
      url += `page=${params.page}&limit=${params.limit}&`;
    }
    if (params?.searchKey) {
      url += `searchKey=${params.searchKey}&`;
    }

    return axiosClient.get(removeLastCharacter(url));
  },

  deleteAccountAgent(payload: IPayloadDeleteAgent) {
    let url = URL_API_AGENT.DELETE_AGENT;
    return axiosClient.delete(url, { data: payload });
  },

  getDetailAgent(param: IParamDetailAgent) {
    let url = `${URL_API_AGENT.GET_DETAIL_AGENT}/${param.accountId}`;
    return axiosClient.get(url);
  },

  updateAccountAgent(payload: IPayloadUpdateAgent) {
    let url = URL_API_AGENT.UPDATE_AGENT;
    return axiosClient.put(url, payload);
  },
};
