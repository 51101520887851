import { createSlice } from '@reduxjs/toolkit';
import { createAndUpdateCart } from './cartThunk';

interface ICartState {
  loading: boolean;
}

const initialState: ICartState = {
  loading: false,
};

const cartSlice = createSlice({
  initialState,
  name: 'cart/createAndUpdateCart',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createAndUpdateCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createAndUpdateCart.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createAndUpdateCart.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const cartActions = cartSlice.actions;

const cartReducer = cartSlice.reducer;
export default cartReducer;
