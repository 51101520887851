// Libs
import React, { useContext } from 'react';
import { Button, Col, Form, Input, Layout, Row, message, Typography, Space } from 'antd';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
// i18next
import { useTranslation } from 'react-i18next';
// Hooks
import useBreakpoint from '~/hooks/useBreakPoint';
import { useAppDispatch } from '~/redux/hooks';
// Thunk
import { createAgent } from '~/thunks/agent/agentThunk';
// Shares
import { LoadingContext } from '~/contexts/LoadingContext';
import styles from './AddAgent.module.scss';
// Utils
import { CHARACTER_MIN_PASSWORD, ROUTER_PATHS, TITLE_LEVEL } from '~/utils/constants';
import { getErrorMessageByCode, useLanguageValidation } from '~/utils/helper';
// Interface
import { IAddAgent } from './addAgent.interface';

const cx = classNames.bind(styles);

const { Title } = Typography;

const AddAgent = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { md } = useBreakpoint();

  useLanguageValidation(form);

  const handleAddAgentSuccess = () => {
    message.success(t('agent_add_message_success'));
    navigate(ROUTER_PATHS.AGENTS);
    form.resetFields();
  };

  const handleAddAgent = (values: IAddAgent) => {
    loadingContext?.show();
    dispatch(createAgent(values))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        handleAddAgentSuccess();
      })
      .catch((error) => {
        loadingContext?.hide();
        getErrorMessageByCode(error.response.data.code);
      });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Layout id='addAgent' className={cx('mainLayout')}>
      <div className={cx('addAgentContainer')}>
        <Title level={TITLE_LEVEL}>{t('agent_add_button')}</Title>
        <Form layout='vertical' size='large' form={form} onFinish={handleAddAgent}>
          <Row gutter={{ sm: 8, md: 12, lg: 16 }} className={cx('contentRow')}>
            <Col span={md ? 24 : 12}>
              <Row gutter={{ sm: 8, md: 12, lg: 16 }}>
                <Col span={md ? 24 : 12}>
                  <Form.Item<IAddAgent> name='name' label={t('common_agent_name')} rules={[{ required: true, message: t('common_agent_name_require') }]}>
                    <Input placeholder={t('common_agent_name_placeholder')} allowClear />
                  </Form.Item>
                </Col>
                <Col span={md ? 24 : 12}>
                  <Form.Item<IAddAgent> name='surname' label={t('common_agent_surname')} rules={[{ required: true, message: t('common_agent_surname_require') }]}>
                    <Input placeholder={t('common_agent_surname_placeholder')} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item<IAddAgent>
                name='email'
                label={t('common_agent_email')}
                rules={[
                  { required: true, message: t('common_email_require') },
                  {
                    type: 'email',
                    message: t('common_email_invalid'),
                  },
                ]}
              >
                <Input placeholder={t('common_agent_email_placeholder')} allowClear />
              </Form.Item>

              <Form.Item<IAddAgent>
                name='password'
                label={t('common_agent_password')}
                rules={[
                  { required: true, message: t('common_password_require') },
                  {
                    min: CHARACTER_MIN_PASSWORD,
                    message: t('common_length_password'),
                  },
                ]}
              >
                <Input.Password placeholder={t('common_agent_password_placeholder')} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space className={cx('addAgentAction')}>
              <Button type='primary' size='large' onClick={goBack}>
                {t('common_button_back')}
              </Button>
              <Button htmlType='submit' type='primary'>
                {t('agent_add_button')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default AddAgent;
