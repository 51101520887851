// Libs
import axiosClient from './axiosClient';
// Interface
import { IParamGetProductsBySubCategoryId, IParamSearchProducts } from '~/data/product';
// Utils
import { URL_API_PRODUCTS } from '~/utils/constants';
import { removeLastCharacter } from '~/utils/helper';

export const productsApi = {
  getProductsBySubCategory: (params: IParamGetProductsBySubCategoryId) => {
    let url = `${URL_API_PRODUCTS.GET_PRODUCTS_BY_SUB_CATEGORY}/${params.subCategoryId}${URL_API_PRODUCTS.GET_CLIENT}/${params.clientId}?`;

    if (params?.subCategoryId) {
      if (params?.page) {
        url += `page=${params.page}&`;
      }
    }

    return axiosClient.get(removeLastCharacter(url));
  },

  searchProduct: (params: IParamSearchProducts) => {
    let url = `${URL_API_PRODUCTS.SEARCH_PRODUCT}?`;

    if (params?.searchKey) {
      url += `searchKey=${params.searchKey}&`;
    }

    if (params?.clientId) {
      url += `clientId=${params.clientId}&`;
    }

    if (params?.type) {
      url += `type=${params.type}&`;
    }

    return axiosClient.get(url);
  },
};
