// Libs
import { Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
// Interface
import { formatPrice } from '~/utils/helper';
import { PropsType } from './documentPDF.interface';
import { MAX_DISCOUNT, UNICODE_EURO } from '~/utils/constants';

export const DocumentPDF = (props: PropsType) => {
  const { data, dataTable, dateTimePDF } = props;

  const { t } = useTranslation();

  const styles = StyleSheet.create({
    page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column' },

    tableTitle: {
      fontSize: 8,
      fontStyle: 'bold',
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: '#F6F8FA',
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    tableTitle2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

    tbody: { fontSize: 6, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

    tbody2: { flex: 2, borderRightWidth: 1 },

    container: {
      flexDirection: 'column',
      padding: 10,
      flex: 1,
    },
    textTitle: {
      padding: 10,
    },
    title: {
      fontSize: 15,
      backgroundColor: '#F6F8FA',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    titleCart: {
      fontSize: 15,
      padding: 10,
      backgroundColor: '#F6F8FA',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    titleInfo: {
      fontSize: 6,
      fontWeight: 'bold',
      marginBottom: 5,
    },
    contentInfo: {
      fontSize: 8,
      marginBottom: 10,
      marginRight: 20,
      fontWeight: 'thin',
    },
    detailInfoRow: {
      width: 150,
    },
    column: {
      flexDirection: 'row',
      padding: 10,
    },
    contentOrder: {
      border: '1px solid #EEE',
      padding: 10,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
    row: {
      flexDirection: 'row',
    },

    productData: {
      border: '1px solid #EEE',
      padding: 5,
      borderRadius: 5,
    },
    contentOrderFlex: {
      flex: 1,
    },
    priceTotal: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 5,
      fontSize: 13,
      borderTop: '1px solid #EEE',
      backgroundColor: '#F6F8FA',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
  });

  const InvoiceTitle = () => (
    <View style={styles.title}>
      <Text style={styles.textTitle}>
        {t('orders_details_title', {
          orderId: dateTimePDF.orderId,
          dateString: dateTimePDF.dateString,
          timeString: dateTimePDF.timeString,
        })}
      </Text>
    </View>
  );

  const Address = () =>
    data && (
      <View style={styles.column}>
        <View>
          <View style={styles.row}>
            <View>
              <Text style={styles.titleInfo}>{t('orders_details_clientCode')}</Text>
              <Text style={styles.contentInfo}>{data.clientCode}</Text>
            </View>
            <View>
              <Text style={styles.titleInfo}>{t('orders_details_listN')}</Text>
              <Text style={styles.contentInfo}>{data.listingCode}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.titleInfo}>{t('orders_details_clientName')}</Text>
            <Text style={styles.contentInfo}>{data.name}</Text>
          </View>
          <View>
            <Text style={styles.titleInfo}>{t('clients_clientDetails_newOrder_email')}</Text>
            <Text style={styles.contentInfo}>{data.email}</Text>
          </View>
          <View>
            <Text style={styles.titleInfo}>{t('clients_clientDetails_newOrder_phone')}</Text>
            <Text style={styles.contentInfo}>{data.phone}</Text>
          </View>
          <View>
            <Text style={styles.titleInfo}>{t('orders_details_paymentType')}</Text>
            <Text style={styles.contentInfo}>{data.paymentName}</Text>
          </View>
        </View>
        <View style={styles.contentOrderFlex}>
          <View style={styles.detailInfoRow}>
            <Text style={styles.titleInfo}>{t('orders_details_clientAddress')}</Text>
            <Text style={styles.contentInfo}>{data.address}</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.detailInfoRow}>
              <Text style={styles.titleInfo}>{t('orders_details_zipCode')}</Text>
              <Text style={styles.contentInfo}>{data.zipCode}</Text>
            </View>
            <View style={styles.detailInfoRow}>
              <Text style={styles.titleInfo}>{t('orders_details_city')}</Text>
              <Text style={styles.contentInfo}>{data.city}</Text>
            </View>
            <View style={styles.detailInfoRow}>
              <Text style={styles.titleInfo}>{t('orders_details_province')}</Text>
              <Text style={styles.contentInfo}>{data.province}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.titleInfo}>{t('orders_details_vatNumber')}</Text>
            <Text style={styles.contentInfo}>{data.vatNumber}</Text>
          </View>
          <View style={styles.detailInfoRow}>
            <Text style={styles.titleInfo}>{t('orders_details_codiceFiscale')}</Text>
            <Text style={styles.contentInfo}>{data.healthCode}</Text>
          </View>
          {data.note && (
            <View style={styles.detailInfoRow}>
              <Text style={styles.titleInfo}>{t('orders_details_orderNote')}</Text>
              <Text style={styles.contentInfo}>{data.note}</Text>
            </View>
          )}
        </View>
      </View>
    );

  const TableHead = () => (
    <View style={{ width: '100%', flexDirection: 'row' }}>
      <View style={{ width: '15%' }}>
        <View style={styles.tableTitle}>
          <Text>{t('orders_orderDetail_cart_table_id')}</Text>
        </View>
      </View>
      <View style={{ width: '45%' }}>
        <View style={[styles.tableTitle, styles.tableTitle2]}>
          <Text>{t('orders_orderDetail_cart_table_productName')}</Text>
        </View>
      </View>

      <View style={styles.tableTitle}>
        <Text>{t(`${t('orders_orderDetail_cart_table_price')} (${UNICODE_EURO})`)}</Text>
      </View>

      <View style={styles.tableTitle}>
        <Text>{t('orders_orderDetail_cart_table_discount')}</Text>
      </View>

      <View style={styles.tableTitle}>
        <Text>{t('orders_orderDetail_cart_table_quantity')}</Text>
      </View>

      <View style={styles.tableTitle}>
        <Text>{t(`${t('orders_orderDetail_cart_table_total')} (${UNICODE_EURO})`)}</Text>
      </View>
    </View>
  );

  const TableBody = () => (
    <>
      {dataTable?.map((item) => (
        <View key={item.id} style={{ width: '100%', flexDirection: 'row' }}>
          <View style={{ width: '15%' }}>
            <View style={styles.tbody}>
              <Text>{item.productCode}</Text>
            </View>
          </View>
          <View style={{ width: '45%' }}>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text>{item.productName}</Text>
            </View>
          </View>
          <View style={styles.tbody}>
            <Text>{item.priceProduct} </Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.discount === MAX_DISCOUNT ? MAX_DISCOUNT : `${item.discount} %`}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.quantity}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.totalPrice}</Text>
          </View>
        </View>
      ))}
    </>
  );

  const TableTotal = () => (
    <View style={styles.priceTotal}>
      <Text>{t('orders_orderDetail_cart_table_total')}</Text>
      <Text>{formatPrice(data.total)}</Text>
    </View>
  );

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.container}>
          <InvoiceTitle />
          <View style={styles.contentOrder}>
            <Address />
            <View style={styles.productData}>
              <Text style={styles.titleCart}>{t('orders_details_cart_title')}</Text>
              <TableHead />
              <TableBody />
              <TableTotal />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
