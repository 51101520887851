import { URL_API_PROFILE } from '~/utils/constants';
import axiosClient from './axiosClient';
import { IChangePassword } from '~/pages/profile/Profile.interface';

export const changePasswordApi = {
  changePassword: (payload: IChangePassword) => {
    const url = URL_API_PROFILE.CHANGE_PASSWORD;
    return axiosClient.put(url, payload);
  },
};
