import { createSlice } from '@reduxjs/toolkit';
import { getListAgent } from './agentThunk';

interface IAgentState {
  loading: boolean;
  agents: {};
}

const initialState: IAgentState = {
  loading: false,
  agents: {},
};

const listAgentSlice = createSlice({
  name: 'agent/listAgent',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListAgent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getListAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action.payload.data
      })
      .addCase(getListAgent.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const listAgentActions = listAgentSlice.actions;

export const selectListAgent = (state: any) => state.listAgent.agents;
export const selectLoading = (state: any) => state.listAgent.loading;

const agentReducer = listAgentSlice.reducer;
export default agentReducer;
