// Libs
import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Ant design
import { Typography, Table, Button, Space, Upload, message, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload/interface';
import { ColumnType } from 'antd/es/table';
// Scss
import styles from './UpdateDatabase.module.scss';
// Interface
import { IUpdateDatabaseErrorTable, IUploadDatabase } from './UpdateDatabase.interface';
// Utils
import { MAX_COUNT_FILE, MESSAGE_DURATION, SCROLL_Y_TABLE_LINE_ERROR, TITLE_LEVEL, UPDATE_DATABASE_TYPE } from '~/utils/constants';
// Thunk
import { updateDatabase } from '~/thunks/updateDatabase/updateDatabaseThunk';
import { selectListErrors } from '~/thunks/updateDatabase/updateDatabaseSlice';
// Shares
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import EmptyTable from '~/components/specific/emptyTable/EmptyTable';
import { LoadingContext } from '~/contexts/LoadingContext';
import useBreakpoint from '~/hooks/useBreakPoint';

const cx = classNames.bind(styles);

const UpdateDatabase: React.FC = () => {
  const [idDatabase, setIdDatabase] = useState<number>();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  const listErrors = useAppSelector(selectListErrors);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const { xs } = useBreakpoint();

  const defaultDatabases: IUploadDatabase[] = [
    {
      id: 1,
      databaseName: t('update_database_name_category'),
    },
    {
      id: 2,
      databaseName: t('update_database_name_payment'),
    },
    {
      id: 3,
      databaseName: t('update_database_name_product'),
    },
    {
      id: 4,
      databaseName: t('update_database_name_listing'),
    },
    {
      id: 5,
      databaseName: t('update_database_name_client'),
    },
    {
      id: 6,
      databaseName: t('update_database_name_destination'),
    },
  ];

  const handleBeforeUpload = (file: File) => {
    const isValidType = file.type === 'text/plain';

    if (!isValidType) {
      message.error(t('code_message_file_only_accepts_text_file'));
    }

    return isValidType || Upload.LIST_IGNORE;
  };

  const handleCustomRequest = ({ file, onSuccess, onError }: any) => {
    let type = '';

    switch (idDatabase) {
      case UPDATE_DATABASE_TYPE.CATEGORY.key:
        type = UPDATE_DATABASE_TYPE.CATEGORY.type;
        break;
      case UPDATE_DATABASE_TYPE.PAYMENT.key:
        type = UPDATE_DATABASE_TYPE.PAYMENT.type;
        break;
      case UPDATE_DATABASE_TYPE.LISTING.key:
        type = UPDATE_DATABASE_TYPE.LISTING.type;
        break;
      case UPDATE_DATABASE_TYPE.DESTINATION.key:
        type = UPDATE_DATABASE_TYPE.DESTINATION.type;
        break;
      case UPDATE_DATABASE_TYPE.PRODUCT.key:
        type = UPDATE_DATABASE_TYPE.PRODUCT.type;
        break;
      case UPDATE_DATABASE_TYPE.CLIENT.key:
        type = UPDATE_DATABASE_TYPE.CLIENT.type;
        break;

      default:
        break;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    loadingContext?.show();
    dispatch(updateDatabase(formData))
      .unwrap()
      .then((res) => {
        onSuccess();
        loadingContext?.hide();

        message.success(`${file.name} ${t('success_upload_file')}`, MESSAGE_DURATION);

        if (res?.data?.lineTextErrors?.length > 0) {
          setIsErrorModalOpen(true);
        }
      })
      .catch((error) => {
        onError();
        loadingContext?.hide();
        message.error(`${file.name} ${t('error_upload_file')}`);
      });
  };

  const propsUpload: UploadProps = {
    beforeUpload: handleBeforeUpload,
    customRequest: handleCustomRequest,
    maxCount: MAX_COUNT_FILE,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const handleUploadClicked = (id: number) => {
    setIdDatabase(id);
  };

  const columns: ColumnType<IUploadDatabase>[] = [
    {
      title: t('update_database_table_name'),
      dataIndex: 'databaseName',
    },
    {
      dataIndex: 'actions',
      align: 'right',
      width: xs ? 64 : 180,
      render: (_, record) => {
        return (
          <Upload {...propsUpload}>
            <Button type='primary' onClick={() => handleUploadClicked(record?.id)} icon={<UploadOutlined />}>
              {!xs && t('update_database_button_upload')}
            </Button>
          </Upload>
        );
      },
    },
  ];

  const columnsErrorTable: ColumnType<IUpdateDatabaseErrorTable>[] = [
    {
      title: t('update_database_list_error_table_title_content'),
      dataIndex: 'content',
    },
    {
      title: t('update_database_list_error_table_title_message'),
      dataIndex: 'message',
      width: 200,
    },
  ];

  const handleCancelErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const handleOkErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <div id='updateDatabase' className={cx('updateDatabaseContainer')}>
      <Typography.Title level={TITLE_LEVEL}>{t('updateDatabase_list_title')}</Typography.Title>
      <Space direction='vertical' size='large' className={cx('contentUpdateDatabase')}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={defaultDatabases}
          pagination={false}
          locale={{
            emptyText: <EmptyTable />,
          }}
        />
      </Space>
      <Modal
        title={t('update_database_list_error_title')}
        open={isErrorModalOpen}
        className={cx('updateDatabaseModal')}
        onOk={handleOkErrorModal}
        onCancel={handleCancelErrorModal}
        closeIcon={false}
        width={800}
      >
        <Table
          dataSource={listErrors}
          columns={columnsErrorTable}
          pagination={false}
          scroll={{ y: SCROLL_Y_TABLE_LINE_ERROR }}
          locale={{
            emptyText: <EmptyTable />,
          }}
        />
      </Modal>
    </div>
  );
};

export default UpdateDatabase;
