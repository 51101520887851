import classNames from 'classnames/bind';
// Libs
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Layout, Result } from 'antd';
// Scss
import styles from './SendEmailSuccess.module.scss';

const cx = classNames.bind(styles);

const SendEmailSuccess: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout id='sendEmailSuccess' className={cx('mainLayout')}>
      <Result
        status='success'
        title={t('success_message_send_email')}
        subTitle={t('success_send_email_success_subtitle')}
        extra={[<Button type='primary'>{t('success_send_email_success_button')}</Button>]}
      />
    </Layout>
  );
};

export default SendEmailSuccess;
