import { createAsyncThunk } from '@reduxjs/toolkit';
import { clientDetailApi } from '~/apis/clients';
import { IPayloadClientDetailAdminNote, IPayloadClientDetailId } from '~/pages/clients/Clients.interface';
import { ADD_CLIENT_DETAIL_ADMIN_NOTE, GET_CLIENT_DETAIL } from '~/utils/actionType';

export const getClientDetail = createAsyncThunk(GET_CLIENT_DETAIL, async (payload: IPayloadClientDetailId, { rejectWithValue }) => {
  try {
    const res = await clientDetailApi.getClientDetail(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addClientDetailAdminNote = createAsyncThunk(ADD_CLIENT_DETAIL_ADMIN_NOTE, async (payload: IPayloadClientDetailAdminNote, { rejectWithValue }) => {
  try {
    const res = await clientDetailApi.addClientDetailAdminNote(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
